import React, { useEffect, useState } from "react";
import "./gallery.css";
import '../../Styles/customcheckbox.css';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AddFvrtArts, getAllArts, getAllSound, getFvrtArts } from "../../redux/features/auth/authSlice";
import Login from "../../Screens/Login";
import { toast } from "react-toastify";
import ModalHandler from "../../Screens/newloginflow/ModalHandler";
import headerImage from '../../assets/images/headerImage.png'
import filterIcon from '../../assets/images/filter_icon.png'
import search from '../../assets/search.png'
import SelectPage from "./SelectPage";
import MusicFilters from "./MusicFilters";
import GalleryMusic from "./GalleryMusic";
import TablePagination from "../Pagination/TablePagination";


const GalleryPage = () => {

  const USER = JSON.parse(localStorage.getItem("user"));
  const [planId, setplanId] = useState()
  const [styId, setstyId] = useState()
  const [data, setData] = useState([]);
  const [screenshift, setscreenshift] = useState(1)
  const [newLoginModal, setnewLoginModal] = useState(false)
  const [searchQuery, setSearchQuery] = useState("");
  const [other, setother] = useState('other')






  const [filter, setFilter] = useState({
    formatTitle: "",
    style: "",
    format: "",
  });

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [enablemusicpage, setenablemusicpage] = useState(false);
  const [musicFilterdata, setmusicFilterdata] = useState()
  const userList = useSelector((state) => state.auth.allArts);
  const [paginatedData, setpaginatedData] = useState()

  const { isLoading } = useSelector((state) => state.auth);
  const { allSound } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {

    const getData = async () => {
      await dispatch(getAllSound());
      console.log("runing continue");
    };

    getData();


  }, []);

  useEffect(() => {
    if (userList) {
      setData(userList?.data)
    }
  }, [])

  useEffect(() => {
    const getData = async () => {
      await dispatch(getAllArts());
    };

    getData();
  }, [dispatch, filter]);


  const searchData = () => {
    if (enablemusicpage) {
      const filteredData = allSound?.data?.filter((item) => {
        const titleMatch = item.title.toLowerCase().includes(searchQuery.toLowerCase());
        const descriptionMatch = item.description.toLowerCase().includes(searchQuery.toLowerCase());
        return titleMatch || descriptionMatch;
      });
      setmusicFilterdata(filteredData);
    }
    else {
      const filteredData = userList?.data?.filter((item) => {
        const titleMatch = item.title
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const descriptionMatch = item.description
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        return titleMatch || descriptionMatch;
      });
      setData(filteredData);
    }

  };

  const handleSearch = () => {
    searchData();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const moveToUserDetail = (id) => {
    navigate('/profile-for-main', { state: { id: id } })
  }


  useEffect(() => {



    const sendFilterBackend = async () => {

      if (filter?.formatTitle === 'Music') {
        setenablemusicpage(true)
        return
      }
      else {
        setenablemusicpage(false)
        await dispatch(getAllArts(filter))

          .then((response) => {
            console.log("response filter", response?.payload?.data)

            setData(response?.payload?.data)

          })
      }

    };
    sendFilterBackend()



  }, [filter, dispatch]);

  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  const detailsPage = (item) => {
    navigate("/art-details", { state: { id: item._id, image: item.image } });
  };

  const donatePage = (item) => {
    // if (USER) {
    const queryParams = new URLSearchParams({
      art_id: item?._id,
    });

    navigate(`/donate?${queryParams}`, {
      state: {
        title: item.title,
        description: item.description,
        image: item.image,
        art_id: item?._id,
        user_id: item?.owner_id,
      },
    });
    // }
    // if (!USER) {
    //  loginModalScreen()
    // }
  };


  const FavoriteArtWork = async (item) => {
    if (!USER) {
      setShowLoginModal(true);
    }
    if (USER) {
      const data = await dispatch(AddFvrtArts({
        art_id: item?._id,
        user_id: USER?.user[0]?._id
      }))

      await dispatch(getFvrtArts({
        user_id: USER?.user[0]?._id
      }))
      const res = data.payload

      if (res.success === false) {
        toast.info(res.message, {
          autoClose: 1000
        })
      }
      if (res.success === true) {
        toast.success(res.message, {
          autoClose: 1000
        })
      }
    }
  }


  const paginatedlist = (val) => {



    setpaginatedData(val)
  }





  function loginModalScreen() {
    setscreenshift(1)
    setnewLoginModal(!newLoginModal)
  }


  function shiftCountplus() {

    setscreenshift(screenshift + 1)

  }
  function shiftCountminus() {

    setscreenshift(screenshift - 1)

  }
  function restscreenplus() {
    setscreenshift(screenshift + 2)
  }
  function restscreenminus() {
    setscreenshift(screenshift - 2)
  }
  function interestscreenplus() {
    setscreenshift(screenshift + 2)
  }
  function interestscreenminus() {
    setscreenshift(screenshift - 2)
  }



  return (
    <>
      <div
        className="col text-center background-image"
        style={{
          borderTop: '15px solid white',
          borderLeft: '15px solid white',
          borderRight: '15px solid white',
          backgroundImage: `url('${headerImage}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "500px",

        }}
      >
        <div className="container" style={{ borderRadius: "0 20px 20px 0" }}>
          <div className="row d-flex justify-content-center">

            <div className="col-sm-6 d-flex col-md-9 col-sm-10 col-10 ps-0 ">

             
              <div className="w-100">
                <input

                  type="text"
                  className="mainsearchinput text-muted border-0 w-100 p-3"
                  sty
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />


              </div>



              <div
                className="input-group-append border-0 text-center text-light d-flex align-items-center justify-content-center"
                style={{ width: "60px", background: "#719DA8", borderRadius: '0 5px 5px 0', fontWeight: '800' }}
                onClick={handleSearch}
              >
                <img src={search} alt="" style={{ height: '26px', width: '26px' }} />

              </div>





            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid"
        style={{ backgroundColor: "white", color: "#709BA5", background: '#EEEDEB' }}
      >
        <div className="gallary-container pb-0">
          <div className="row pt-3">

            <div className="col-md-4  col-lg-4 col-xl-3  pe-xl-5 pe-lg-3 pe-md-0 "

            >
              <div className="bg-white h-100"
                style={{
                  // paddingTop: "30px",
                  position: "relative",
                  //  margin :"1.5rem",
                  border: "1px solid #EEECEB",

                  borderRadius: '15px 15px 0 0',
                  boxShadow: "3px 2px 6px #B4B3B1",
                  // borderRadius: "12px",
                }}
              >
                <aside className="col-sm-3   pe-0  w-100">
                  <div className="">
                    <article
                      className="card-group-item text-muted"
                      style={{ fontWeight: "700" }}
                    >
                      <header className="card-header d-flex py-3 ps-3">
                        <div className="">

                          <img src={filterIcon} style={{ width: '20px', height: '20px' }} alt="" />
                        </div>
                        <h6
                          className="title ps-1 pb-0 mb-0 pt-1 fw-bold"
                          style={{
                            fontSize: "18px",
                            color: "#709BA5",
                            fontWeight: "400",
                          }}
                        >
                          Filters
                        </h6>
                      </header>

                      <header
                        className="card-header border-bottom-0 mt-3 pb-0"
                        style={{ background: "transparent" }}
                      >
                        <h6
                          className="title mb-2 fw-bold ps-3"
                          style={{
                            fontSize: "18px",
                            color: "#709BA5",
                            fontWeight: "400",
                          }}
                        >
                          Format
                        </h6>
                      </header>
                      <div className="filter-content ps-3 mt-1">
                        <div className="card-body">
                          <form>
                            {userList?.formats?.map((format, i) => {
                              return (







                                <div className="checkbox-wrapper my-3" >
                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={planId === format._id}
                                      onChange={(e) => {

                                        if (planId === format._id) {
                                          // If the same row's checkbox is clicked again, uncheck it
                                          setplanId(null);
                                        } else {
                                          // If a different row's checkbox is clicked, update the planId state
                                          setplanId(format._id);
                                        }

                                        const { value, checked } = e.target;
                                        if (checked) {
                                          setFilter((prevState) => ({
                                            ...prevState,
                                            // title: value,
                                            format: format._id,
                                            formatTitle: format.title
                                          }));
                                        } else {
                                          setFilter((prevState) => ({
                                            ...prevState,
                                            formatTitle:
                                              prevState.format === format._id
                                                ? ""
                                                : prevState.title,
                                            format:
                                              prevState.format === format._id
                                                ? ""
                                                : prevState.format,
                                          }));
                                        }
                                      }}
                                      className={planId === format._id ? "checked" : ""}

                                    />
                                    <span style={{ color: '#709DA7' }}>{format.title}</span>
                                  </label>
                                </div>







                              );
                            })}


                            <div className="checkbox-wrapper my-3" >
                              <label>
                                <input
                                  type="checkbox"
                                  checked={planId === other}
                                  value={planId}
                                  onChange={(e) => {

                                    if (planId === other) {
                                      // If the same row's checkbox is clicked again, uncheck it
                                      setplanId(null);
                                    } else {
                                      // If a different row's checkbox is clicked, update the planId state
                                      setplanId(other);
                                    }

                                    const { value, checked } = e.target;
                                    if (checked) {
                                      setFilter((prevState) => ({
                                        ...prevState,
                                        formatTitle: 'other',
                                        format: other,
                                      }));
                                    } else {
                                      setFilter((prevState) => ({
                                        ...prevState,
                                        formatTitle:
                                          prevState.format === other
                                            ? ""
                                            : prevState.title,
                                        format:
                                          prevState.format === other
                                            ? ""
                                            : prevState.format,
                                      }));
                                    }
                                  }}
                                  className={planId === other ? "checked" : ""}

                                />
                                <span style={{ color: '#709DA7' }}>Others</span>
                              </label>
                            </div>

                          </form>
                        </div>
                      </div>
                    </article>

                    <hr style={{ opacity: "0.2" }} />
                    {
                      enablemusicpage ?
                        <MusicFilters />
                        :
                        <article
                          className="card-group-item text-muted mb-3"
                          style={{ fontWeight: "700" }}
                        >
                          <header
                            className="card-header border-bottom-0  pb-0"
                            style={{ background: "transparent" }}
                          >
                            <h6
                              className="title mb-2 pb-0 fw-bold ps-3"
                              style={{
                                fontSize: "18px",
                                color: "#709BA5",
                                fontWeight: "400",
                              }}
                            >
                              Style
                            </h6>
                          </header>
                          <div className="filter-content ps-3 mt-1">
                            <div className="card-body">
                              <form>
                                {userList?.styles?.map((style, i) => {
                                  return (
                                    <div className="checkbox-wrapper my-3" >
                                      <label>
                                        <input
                                          type="checkbox"
                                          checked={styId === style._id}
                                          value={styId}
                                          onChange={(e) => {

                                            if (styId === style._id) {
                                              // If the same row's checkbox is clicked again, uncheck it
                                              setstyId(null);
                                            } else {
                                              // If a different row's checkbox is clicked, update the planId state
                                              setstyId(style._id);
                                            }


                                            const { value, checked } = e.target;
                                            if (checked) {
                                              setFilter((prevState) => ({
                                                ...prevState,
                                                //  title: value,
                                                style: style._id,
                                              }));
                                            } else {
                                              setFilter((prevState) => ({
                                                ...prevState,
                                                // title: prevState.style === style._id ? "" : prevState.title,
                                                style: prevState.style === style._id ? "" : prevState.style,
                                              }));
                                            }
                                          }}
                                          className={styId === style._id ? "checked" : ""}

                                        />
                                        <span style={{ color: '#709DA7' }}>{style.title}</span>
                                      </label>
                                    </div>
                                  );
                                })}

                                <div className="checkbox-wrapper my-3" >
                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={styId === other}
                                      onChange={(e) => {

                                        if (styId === other) {
                                          // If the same row's checkbox is clicked again, uncheck it
                                          setstyId(null);
                                        } else {
                                          // If a different row's checkbox is clicked, update the planId state
                                          setstyId(other);
                                        }


                                        const { value, checked } = e.target;
                                        if (checked) {
                                          setFilter((prevState) => ({
                                            ...prevState,
                                            //  title: value,
                                            style: other,
                                          }));
                                        } else {
                                          setFilter((prevState) => ({
                                            ...prevState,
                                            // title: prevState.style === style._id ? "" : prevState.title,
                                            style: prevState.style === other ? "" : prevState.style,
                                          }));
                                        }
                                      }}
                                      className={styId === other ? "checked" : ""}

                                    />
                                    <span style={{ color: '#709DA7' }}>Others</span>
                                  </label>
                                </div>

                              </form>
                            </div>
                          </div>
                        </article>
                    }

                  </div>
                </aside>
              </div>
            </div>
            <div className="col-md-8 col-xl-9 col-lg-8  col-sm-12 col-12 pb-3  pe-md-0 pe-sm-3 ">
              <div className="row m-0 p-0 ms-md-4 ">
                <div className=" col-12 pb-3 p-0">

                  <div className="row mt-md-0 mt-sm-3 mt-xs-3 mt-3 ">

                    {isLoading ? (
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status" style={{ color: '#709AA4' }}>
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : enablemusicpage ?
                      <GalleryMusic allSound={allSound} musicFilterdata={musicFilterdata} /> :

                      paginatedData?.length > 0 ? (
                        paginatedData?.map((item, i) => {
                          // getting image from backend
                          const imagesData = item.image[0];
                          const descriptionWords = item.description.split(" ");
                          const Description = descriptionWords.slice(0, 2).join(" ");

                          return (

                            <div
                              className="col-md-4 col-sm-6 col-sx-12 col-12  mb-4  gallery-cardArea"
                              style={{
                                padding: '0 10px',
                                // borderRadius: "18px",

                              }}
                              key={i}
                            >
                              <div
                                className="card rounded-4 gallery-card"
                                style={{
                                  color: "#f8f8f8",
                                  boxShadow: "3px 2px 6px #bab8b8",

                                  overflow: 'hidden', // Ensure that content beyond the card size is hidden
                                }}
                              >
                                <div className=' gallery-cardImage' style={{
                                  // width: "19.5rem",


                                  overflow: 'hidden', // Ensure that content beyond the card size is hidden
                                }}>
                                  <img
                                    className='img-fluid'
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      objectFit: 'cover',
                                      cursor: 'pointer',
                                    }}
                                    src={item?.image ? imagesData : "No User Exist"}
                                    alt="Not data"
                                    onClick={() => detailsPage(item)}
                                  />
                                </div>

                                <div className="p-2 d-flex align-items-center">
                                  <div className="">
                                    <h6 className='p-0' style={{ fontWeight: '700', color: '#000000', fontSize: "14px", lineHeight: "16.94px" }}>
                                      {item.title ? item.title : 'No User Exist'}
                                    </h6>

                                    <p
                                      onClick={() => moveToUserDetail(item.owner_id)}
                                      className='p-0 m-0'
                                      style={{ fontWeight: '400', textDecoration: 'none', color: '#709BA5', fontSize: "14px", lineHeight: "16.94px" }}
                                    >
                                      by <span className="fw-bold fs-6" style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item?.ownerName}</span>
                                    </p>
                                  </div>

                                </div>
                              </div>

                            </div>



                          );
                        })
                      ) : (
                        <div className="text-muted fs-3">No Data Found</div>
                      )}

                  </div>
                  {/* {Art Cards Fetching Dynamically} */}


                </div>

              </div>
              {!enablemusicpage &&
                data && <TablePagination list={data} paginatedList={paginatedlist} />
              }

            </div>


            {showLoginModal && <Login handleClose={closeLoginModal} />}

            <ModalHandler
              closeModal={loginModalScreen}
              ModalIsOpen={newLoginModal}
              screenshift={screenshift}
              shiftCountplus={shiftCountplus}
              shiftCountminus={shiftCountminus}
              restscreenplus={restscreenplus}
              restscreenminus={restscreenminus}
              interestscreenplus={interestscreenplus}
              interestscreenminus={interestscreenminus}

            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryPage;
