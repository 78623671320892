import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSound, getUserSound } from '../../../redux/features/auth/authSlice'
import uploadBlog from '../../../assets/icons/uploadblog.png'
import Select from 'react-select';


const UploadMusicDetail = ({ userimageuplift, selectedFile,
  passToParent, nextPage, duration,
  chooseImage, SelectedImage, availableImage
  , preserveDataInParent, usePreservedData }) => {

  const user = JSON.parse(localStorage.getItem("user"));
  const _id = user?.user[0]?._id;


  const User = JSON.parse(localStorage.getItem('user'))
  const user_id = User?.user[0]._id
  const [uploadedArtCount, setUploadedArtCount] = useState(0);
  const { allSound } = useSelector((state) => state.auth);
  const dispatch = useDispatch()

  const [userImage, setuserImage] = useState()
  const Vocals = ["Yes", "No"]





  const [addMusic, setAddMusic] = useState({
    title: '',
    description: '',
    owner_id: user_id,
    mood: [],
    genre: [],
    instrument: [],
    vocals: Vocals[0],
    tags: '',
    image: ''
  })



  const [errors, setErrors] = useState({
    title: '',
    description: '',
    tags: '',
    mood: null,
    instrument: null,
    genre: null,
  });

  //this useEffect get values from preseved data and updata our state
  useEffect(() => {
    if (usePreservedData) {
      setAddMusic((prev) => ({
        ...prev,
        title: usePreservedData?.title,
        description: usePreservedData?.description,
        mood: usePreservedData?.mood,
        genre: usePreservedData?.genre,
        instrument: usePreservedData?.instrument,
        vocals: usePreservedData?.vocals,
        tags: usePreservedData?.tags,

        owner_id: usePreservedData?.owner_id,

      }))

    }
  }, [usePreservedData])

  //get formats and styles
  useEffect(() => {
    const getData = async () => {
      await dispatch(getAllSound());
    };

    getData();
  }, []);


  const fileInputRef = useRef(null);

  const onchange = (e) => {
    const { name, value } = e.target;
    setAddMusic({ ...addMusic, [name]: value });
  };


  const handlechange = (selectedOptions, { name }) => {
    const updatedValues = selectedOptions.map(option => option.value);

    setAddMusic(prevAddMusic => ({
      ...prevAddMusic,
      [name]: updatedValues,
    }));

  };






  // Function to handle avatar click and trigger file input
  const handleAvatarClick = () => {
    // fileInputRef.current.click();
    chooseImage()
    preserveDataInParent(addMusic)
  };

  //add file in state
  const handleFileChange = (event) => {
    chooseImage()
    const file = event.target.files[0];
    setuserImage(file)
    setAddMusic(prevAddMusic => ({ ...prevAddMusic, image: file }));
    userimageuplift(file)

  };





  const handleChangeTags = (e) => {
    const { name, value } = e.target;
    setAddMusic({ ...addMusic, [name]: value });
  };


  useEffect(() => {
    const getData = async () => {
      const data = await dispatch(getUserSound({ user_id: _id }));
      setUploadedArtCount(data?.payload?.data?.length)
    };
    getData();
  }, [_id, dispatch]);



  const validateForm = () => {
    let isValid = true;
    const updatedErrors = {
      title: '',
      description: '',
      tags: '',
      mood: null,
      instrument: null,
      genre: null


    };

    if (addMusic.title.trim() === '') {
      updatedErrors.title = 'Title is required';
      isValid = false;
    }

    if (addMusic.description.trim() === '') {
      updatedErrors.description = 'Description is required';
      isValid = false;
    }

    if (addMusic.tags.trim() === '') {
      updatedErrors.tags = 'Tags are required';
      isValid = false;
    }
    if (addMusic.mood?.length === 0) {
      updatedErrors.mood = 'mood are required';
      isValid = false;
    }
    if (addMusic.instrument?.length === 0) {
      updatedErrors.instrument = 'instrument are required';
      isValid = false;
    }
    if (addMusic.genre?.length === 0) {
      updatedErrors.genre = 'genre are required';
      isValid = false;
    }

    setErrors(updatedErrors);
    return isValid;
  };




  const addUserMusic = async () => {
    if (!selectedFile) {
      return;
    }

    if (!validateForm()) {
      return;
    }

    if (validateForm()) {


      preserveDataInParent(addMusic)
      passToParent(addMusic)
      nextPage();

     
    }

  };



  return (
    <>
      <div className="detail-title mt-4">
        <p className='fw-semibold h4' style={{
          color: '#74A8B0'
        }}>Details</p>
      </div>
      <div className="row">
        <div className="col">
          <div className='form-group my-2'>
            <input type="text" onChange={onchange}
              value={addMusic.title}
              className={`form-control project-input ${errors.title && 'is-invalid'}`}
              name="title" 
              placeholder='Title' />
            {errors.title && <div className="invalid-feedback">{errors.title}</div>}
          </div>
          <div className='form-group my-2'>
            <textarea

              className={`form-control project-input  ${errors.description && 'is-invalid'}`}
              onChange={onchange}
              value={addMusic.description}
              name="description"
              placeholder='Description'
              rows="3"></textarea>
             {errors.description && <div className="invalid-feedback">{errors.description}</div>}
          </div>


          <div className="row ">
            <div className="col my-2">
              <div class="form-group mood">
                <label for="exampleFormControlSelect1" style={{ color: "#74A8B0" }}>Mood</label>
                <Select
                  name="mood"
                  className={` form-control project-input border-0 p-0 ${errors.mood && 'is-invalid'}`}
                  isMulti
                  defaultValue={usePreservedData?.mood?.map(mood => ({ label: mood, value: mood }))}
                  options={allSound?.distinctMoods?.map(mood => ({ label: mood.title, value: mood.title }))}
                  onChange={(selectedOptions) => handlechange(selectedOptions, { name: 'mood' })}
                />
                {errors.mood && <div className="invalid-feedback">{errors.mood}</div>}




              </div>
              <div class="form-group instrument">
                <label for="exampleFormControlSelect1" style={{ color: "#74A8B0" }}>Instrument</label>
             
                <Select
                  name="instrument"
                  className={` form-control project-input border-0 p-0 ${errors.instrument && 'is-invalid'}`}
                  isMulti
                  defaultValue={usePreservedData?.instrument?.map(value => ({ label: value, value: value }))}
                  options={allSound?.distinctInstruments?.map(instrument => ({ label: instrument.title, value: instrument.title }))}
                  onChange={(selectedOptions) => handlechange(selectedOptions, { name: 'instrument' })}
                />
                {errors.instrument && <div className="invalid-feedback">{errors.instrument}</div>}
              </div>

            </div>
            <div className="col my-2">

              <div class="form-group Genre">
                <label for="exampleFormControlSelect1" style={{ color: "#74A8B0" }}>Genre</label>
                <Select
                  name="genre"
                  className={` form-control project-input border-0 p-0 ${errors.genre && 'is-invalid'}`}
                  isMulti
                  defaultValue={usePreservedData?.genre?.map(value => ({ label: value, value: value }))}
                  options={allSound?.distinctGenres?.map(genre => ({ label: genre.title, value: genre.title }))}
                  onChange={(selectedOptions) => handlechange(selectedOptions, { name: 'genre' })}
                />
                {errors.genre && <div className="invalid-feedback">{errors.genre}</div>}
              </div>
              <div class="form-group Vocals">
                <label for="exampleFormControlSelect1" style={{ color: "#74A8B0" }}>Vocals</label>
                <select class="form-control project-input"

                  id="exampleFormControlSelect1"
                  value={addMusic.vocals}

                  onChange={onchange}
                  name="vocals"
                >
                  {
                    Vocals.map((vocal, i) => {
                      return (
                        <>

                          <option value={vocal} key={i}>{vocal}</option>
                        </>

                      )
                    })
                  }

                </select>
              </div>
            </div>
          </div>

          <div className='form-group my-2'>
            <input
              type="text"
              className={`form-control project-input ${errors.tags && 'is-invalid'}`}
              placeholder="#hashTags"
              value={addMusic.tags}
              onChange={handleChangeTags}
              // onKeyDown={onKeyDown}
              name="tags"
            />
            {errors.tags && <div className="invalid-feedback">{errors.tags}</div>}
          </div>

          {/* <UploadImageForMusic/> */}
        </div>

        <div className="col">
          <div className="image d-flex justify-content-center align-items-center">

            <div className="d-flex justify-content-center mb-md-5 mb-sm-2 ms-4">
              <div className="profile-image-container">
                <input
                  className=''
                  type="file"
                  name="image"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  accept="image/*"
                />

                <div className="avatar d-flex align-items-end" onClick={handleAvatarClick}>



                <div className=""
                style={{
   
  
    height: '350px',
    overflow: 'hidden', // Ensure that content beyond the card size is hidden
  }}
>
  <div className=''style={{
    // width: "19.5rem",

    height: '350px',
    overflow: 'hidden', // Ensure that content beyond the card size is hidden
  }}>
    <img
     
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        cursor: 'pointer', 
        position:'relative'
      }}
      src={
        !(SelectedImage || availableImage) ? uploadBlog :
          SelectedImage ? URL.createObjectURL(SelectedImage) :
            `${availableImage}`
      }
      className=" img-fluid  "
      alt="Not data"
    
    />
  </div>

 
               </div>
                 


                </div>
              </div>

            </div>


          </div>
          <div className="d-flex  my-1" style={{ justifyContent: 'space-between' }}>
            <div className='mx-2'>
              <p className='fw-semibold m-0' style={{
                color
                  : "#74A8B0"
              }}>Track Title</p>
              <p className='text-muted'>
                {selectedFile?.name?.split(' ').slice(0, 4).join(' ')}
                {selectedFile?.name?.split(' ').length > 4 ? '...' : ''}
              </p>
            </div>
            <div className='mx-2 text-end'>
              <p className='fw-semibold m-0' style={{ color: '#74A8B0' }}>track length</p>
              <p className='text-muted'>{duration ? duration : '00'} </p>
            </div>
          </div>

        </div>
      </div>
      <div className="d-flex justify-content-end  text-end mt-3 border-top">
        <div className=' pt-2'>
          <button className='btn text-white'

            onClick={addUserMusic}

            style={{ backgroundColor: '#74A8B0' }} >
            NEXT</button>
        </div>

      </div>
    </>
  )
}

export default UploadMusicDetail