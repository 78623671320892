import React, { useState } from 'react';
import cardImage1 from '../../assets/images/cardImage3.png';
import cardImg1 from "../../assets/images/cardImage1.png";
import cardImg3 from "../../assets/images/cardImage3.png";
import cardImg4 from "../../assets/images/cardImage4.png";
import cardImg7 from "../../assets/images/cardImage7.png";
import cardImg8 from "../../assets/images/cardImage8.png";
export const MusicStyleCard = ({sendmusicToParent}) => {
  const [disabledCards, setDisabledCards] = useState([]);
  const [musicInteset, setmusicInteset] = useState([])

  const cardsdata= [
    {
  img:cardImg7,
  title:'Rock'
  },
  {
    img:cardImg8,
    title:'Pop musci'
    },
    {
      img:cardImg3,
      title:'Hip hop'
      },
      {
        img:cardImg1,
        title:' Blues'
        },
        {
          img:cardImg4,
          title:'Disco'
          },
          {
            img:cardImg1,
            title:'Funk'
            },
            {
              img:cardImg4,
              title:'Jazz'
              },
              {
                img:cardImg3,
                title:'Folk music'
                },
                {
                  img:cardImg7,
                  title:'Drum and bass'
                  },
                  {
                    img:cardImg8,
                    title:'Classic'
                    }
  ]

  const handleCardClick = (index,item) => {
    if (!disabledCards.includes(index)) {
      setDisabledCards([...disabledCards, index]);
      setmusicInteset([...musicInteset,item?.title])
      sendmusicToParent([...musicInteset,item?.title])
    }
  };

  const cards = cardsdata.map((item, index) => (
    <div
      key={index}
      className="col-md  col-sm-4  col-12 mt-2 p-0 d-flex justify-content-center"
      style={{ paddingLeft: '10px', paddingRight: '10px' }}
    >
      <div
        className="card  text-white"
        style={{
          width: '150px',
          backgroundColor: disabledCards.includes(index) ? 'transparent' : '#709BA5',
          transition: 'background-color 0.3s',
          pointer:"cursor",
        
          zIndex:"1"
        }}
        onClick={() => handleCardClick(index,item)}
      >
        <img src={item.img} className="card-img w-100" alt="..." />
        <div className="card-img-overlay" style={{ backgroundColor: disabledCards.includes(index) ? 'rgba(0,0,0,0.5)' : '#709BA5',}}>
          {/* <h5 className="card-title">Card title</h5> */}
          <p className="card-text" style={{color:"white", fontSize:"14px", textAlign:"left", fontWeight:"400" , position:"absolute", bottom:"2px", width:"90px"}}>{item.title}</p>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <div className="row">{cards}</div>
    </>
  );
};