
import React,{useState} from 'react'

import { useNavigate } from 'react-router-dom';




import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import AvailableImages from '../Modal/AvailableImages';
import ClientMusicImage from '../Modal/ClientMusicImage';







export default function UploadImageForMusic({backToDetailPage,clientimageSendToDetailPage,AvailableImagetoParent}) {

  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState('first');
  const [searchQuery, setSearchQuery] = useState('');
  const handleSearch = (query,tab) => {

    setActiveTab(tab);

    setSearchQuery(query);
  };
   
   
const sendImageToDetailPage=(img) => {

clientimageSendToDetailPage(img)
}

const sendAvailableImageToDetailPage=(img)=>{
  AvailableImagetoParent(img)
}
  return (
    <>


      <div className='rana'>
       
        <Tab.Container id="left-tabs-example" defaultActiveKey="first" >

   
          <Row className={`m-0 mb-3`} > 
       
          <Col sm={12} className={`sidebar-area shadow-end  pe-0 pt-1`}
          >
                <button type="button"  className='text-start sideBar-icon bg-transparent border-0'>
                     
                </button>
                
              <Nav  className=" d-flex"
              
              >
                {/* side menu titles */} 





                <Nav.Item className='pt-2'>
                  <Nav.Link className='fs-6 d-flex  p-0 px-2' style={{ margin: '0.2rem' }} eventKey="first">


                 <p className='text-middle my-2 ps-2 p-0 g' style={{fontWeight:'400',color:"black"}}>Available Images</p> 
                    
                     </Nav.Link>
                </Nav.Item>
              
               

                <Nav.Item className='pt-2'>
                  <Nav.Link className='fs-6 d-flex  p-0 px-2' style={{ margin: '0.2rem' }} eventKey="second">


                 <p className='text-middle my-2 ps-2 p-0 g' style={{fontWeight:'400',color:'black'}}>Upload Image</p> 
                    
                     </Nav.Link>
                </Nav.Item>

              

                   


              </Nav>
              <hr className='w-100 mb-3' style={{borderBottom :'1px solid grey' , opacity:'0.1'}} />
            </Col>
          <Col sm={12} className='sidebar-screens card pt-4 ps-0 h-100'>
          
              
          <Tab.Content>


             

            <Tab.Pane style={{ color: "black" }} eventKey="first">
             
<AvailableImages
SendAvailabelImagetoParent={sendAvailableImageToDetailPage}
backToDetailPage={()=>backToDetailPage()}


/> 
           </Tab.Pane>
           
      
                
            <Tab.Pane style={{ color: "black" }} eventKey="second">
             
<ClientMusicImage
 backToDetailPage={()=>backToDetailPage()}
 clientSelectedImage={sendImageToDetailPage}
 />             
              </Tab.Pane>
             





          </Tab.Content>
        </Col>
          
           
          </Row>
        </Tab.Container>
      </div>





    </>
  )
}

























