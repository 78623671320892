import React, { useEffect ,useState} from 'react'
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { Box,IconButton,} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { userBankDetail ,userAccountDetail, updateUserBankDetail} from '../../../redux/features/auth/authSlice';


import { toast } from 'react-toastify';
const AcountDetailsTable = ({ModalIsOpen,closeModal}) => {


    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          minWidth: "35%",
          
          transform: "translate(-50%, -50%)",
        },
      };



    
 const user = localStorage.getItem('user');
  const USER = user ? JSON.parse(user) : null;
  const [data, setData] = useState({
    user_id: USER?.user[0]?._id,
    bankName: '',
    branchNumber: '',
    routingNumber: '',
    bankAccountNumber: '',
  });
  console.log("🚀 ~ file: AcountDetailsTable.jsx:38 ~ AcountDetailsTable ~ data:", data)

  const [errors, setErrors] = useState({}); // State to hold validation errors
  const {bankDetails,isLoading} = useSelector((state)=>state.auth)


  const dispatch = useDispatch();
  useEffect(()=>{
    if(bankDetails){
     setData((prev)=>({
      ...prev,
  bankName: bankDetails?.data?.bankName||'',
  branchNumber: bankDetails?.data?.branchNumber||'',
  routingNumber:`${bankDetails?.data?.routingNumber}` ||'',
  bankAccountNumber: `${bankDetails?.data?.bankAccountNumber}`|| '',
  user_id: USER?.user[0]?._id,
})) 
    }


  },[bankDetails])

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, ''); 

  // Set the input field with the cleaned numeric value
      setData({ ...data, [name]: numericValue });
  };
   const handleText = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
   }

  const validateForm = () => {
    const errors = {};

    // Add your validation rules here
    if (!data.bankName) {
      errors.bankName = 'Bank Name is required';
    }

    if (!data.branchNumber) {
      errors.branchNumber = 'Branch Number is required';
    } else if (data.branchNumber.length !== 3) {
      errors.branchNumber = 'Branch Number must be exactly 3 digits';
    }

    if (!data.routingNumber) {
      errors.routingNumber = 'Routing Number is required';
    } else if (data.routingNumber.length !== 5) {
      errors.routingNumber = 'Routing Number must be exactly 5 digits';
    }

    if (!data.bankAccountNumber) {
      errors.bankAccountNumber = 'Account Number is required';
    } else if (data.bankAccountNumber.length !== 7) {
      errors.bankAccountNumber = 'Account Number must be exactly 7 digits';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; // Form is valid if there are no errors
  };

  const submit = async () => {
    if (validateForm()) {
      try {
        await dispatch(updateUserBankDetail(data))
        .then((res) => {
          toast.success(res.payload.message);
          dispatch(userBankDetail({user_id:USER?.user[0]?._id}))
          closeModal()
          setData({
            bankName: '',
            branchNumber: '',
            routingNumber: '',
            bankAccountNumber: '',
          });
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
 

    <Modal 
    isOpen={ModalIsOpen}
    onRequestClose={closeModal}
    style={customStyles}
    contentLabel="Example Modal"
  >
      <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p className='m-0 fs-4 openSans-600' style={{color:'#709CA6'}}>
                Edit Bank Detail
              </p>
              <IconButton onClick={closeModal} aria-label="delete">
                <CloseIcon sx={{ color: "#0000009e" }} />
              </IconButton>
            </Box>
     

         <div className="container-fluid">
     
        <div className="container pt-0 mt-0">
          <div className="py-2">
            <div className="form-group py-2">
              <label htmlFor="account_email" className="openSans-400" style={{ color: '#709CA6' }}>
                Bank Name
              </label>
           
                   <input
                id="account_email"
                type="text"
                className={`form-control my-1 ${errors.bankName ? 'is-invalid' : ''}`}
                placeholder="Bank Name"
                name="bankName"
                value={data?.bankName}
                onChange={handleText}
                autoComplete="none"
              /> 
              
              {errors.bankName && <div className="invalid-feedback">{errors.bankName}</div>}
            </div>

            <div className="form-group py-2">
              <label htmlFor="account_confirm_password" className="openSans-400" style={{ color: '#709CA6' }}>
                Branch Number
              </label>

            
              <input
                id="account_confirm_password"
                type="text"
                className={`form-control my-1 ${errors.branchNumber ? 'is-invalid' : ''}`}
                placeholder="Branch Number"
                name="branchNumber"
                value={data?.branchNumber}
                onChange={handleChange}
                autoComplete="none"
                minLength={3}
                maxLength={3}
              
              />
             
              {errors.branchNumber && <div className="invalid-feedback">{errors.branchNumber}</div>}
            </div>
            <div className="form-group">
              <label htmlFor="account_new-password" className="openSans-400" style={{ color: '#709CA6' }}>
                Routing Number
              </label>
            
              <input
                id="account_new-password"
                type="number"
                className={`form-control my-1 ${errors.routingNumber ? 'is-invalid' : ''}`}
                placeholder="Routing Number"
                name="routingNumber"
                value={data?.routingNumber}
                onChange={handleChange}
                autoComplete="none"
                minLength={5}
                maxLength={5}
              />
              
              {errors.routingNumber && <div className="invalid-feedback">{errors.routingNumber}</div>}
            </div>

            <div className="py-2">
              <div className="form-group">
                <label htmlFor="account_new-password" className="openSans-400" style={{ color: '#709CA6' }}>
                  Account Number
                </label>
            
                <input
                  id="account_new-password"
                  type="number"
                  className={`form-control my-1 ${errors.bankAccountNumber ? 'is-invalid' : ''}`}
                  placeholder="Account Number"
                  name="bankAccountNumber"
                  value={data?.bankAccountNumber}
                  onChange={handleChange}
                  autoComplete="none"
                  minLength={7}
                maxLength={7}
                />
               
                {errors.bankAccountNumber && <div className="invalid-feedback">{errors.bankAccountNumber}</div>}
              </div>
            </div>
            <div className="mt-3">
           
              <button className="btn " style={{ color: 'white', backgroundColor: '#709CA6' }} onClick={submit}>
               Update
              </button>
               
            </div>
          </div>
        </div>
      </div>


 </Modal>
  )
}

export default AcountDetailsTable



