import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { AddArtToFvrtCollections, addNewCollection, deleteCollectionApi, getAllFvrtCollection } from '../../redux/features/auth/authSlice';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
    zIndex: 9999, // Ensure the overlay is above other elements
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    minWidth: '37%',
    background: '#FFFFFF',
    border: '2px solid white',
    borderRadius: '10px',
    padding: '8px',
    transform: 'translate(-50%, -50%)',
    zIndex: 1, // Ensure the modal is above the overlay
  },
};


const AddFvrtMusicModal = ({ ModalIsOpen, closeModal,selectedCheckboxes,artId }) => {

    const user = JSON.parse(localStorage.getItem("user"));
    const user_id = user?.user[0]?._id;
  
     const { userList } = useSelector((state) => state.auth);
     
  
     const [selectedStyleId, setSelectedStyleId] = useState(null);
  
     const collections = userList?.FvrtCollections?.data || [];
  
    const [searchTerm, setSearchTerm] = useState("");
    // const [collections, setCollections] = useState([]);
  
    const [showNewCollectionInput, setShowNewCollectionInput] = useState(false);
  
    const [newCollectionTitle,setNewCollectionTitle] = useState({
      title:'',
      user_id:user_id,
      sound_id:selectedCheckboxes? selectedCheckboxes[0]:'',
    })
  
    const [addFvrtCollections,setAddFvrtCollections] = useState({
      folder_id:'',
      user_id:user_id,
     
    })
  
   
  
    const dispatch = useDispatch()
       
  
    const getData = async () =>{
     
      await dispatch(getAllFvrtCollection({user_id:user_id}))
     }
         useEffect(()=>{
          if(ModalIsOpen){
            getData()
          }
           
         },[ModalIsOpen])
  
  const deletecollection=async(id)=>{
    console.log("working");
    dispatch(deleteCollectionApi({id:id}))
    .then((res) => {
      if(res.payload.success===true){
          toast.success("collection delete successfully!",{autoClose:2000})
          dispatch(getAllFvrtCollection({user_id:user_id}))
      }
  
        
    })
  }
  
 
         
        const filteredCollections = collections?.length > 0
        ? collections.filter((item) =>
            item?.title.toLowerCase().includes(searchTerm?.toLowerCase())
          )
        : [];
  
    
  
  
    const handleNewCollectionChange = (e) => {
      const { name, value } = e.target;
      setNewCollectionTitle((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
    const handleNewCollectionClick = (e) => {
      e.stopPropagation(); // Prevent dropdown from closing
      setShowNewCollectionInput(true);
    };
  
   
    const handleCheckboxChange = (id) => {
      setAddFvrtCollections((prev)=>({
        ...prev,
  folder_id:id
      }));
      setSelectedStyleId(id)
    };
    
  
  
    const handleDropdownClick = (e) => {
      e.stopPropagation(); // Prevent dropdown from closing
    };
  
   const [showSign,setShowSign] = useState(false)

    const sign = ( ) => {
      setShowSign(!showSign)
    
    }


    const handleNewCollectionSave = (e) => {
      
    e.stopPropagation(); // Prevent dropdown from closing
    if(newCollectionTitle.title ===''){
      toast.error('please Add collection Name',{autoClose:2000})
    }
   else {
    dispatch(addNewCollection({title:newCollectionTitle.title,user_id:user_id}))
      .then(() => {
        setNewCollectionTitle({
          title: '',
        });
        dispatch(getAllFvrtCollection({user_id:user_id}));
      });
   } 
  };
  
  
   
  
    const saveMusic = () =>{
      if(addFvrtCollections.folder_id===''){
        toast.error('please select collection',{autoClose:2000})
      } 
      else{
         dispatch(AddArtToFvrtCollections({...addFvrtCollections,art_id:artId}))
      .then((res)=>{
  
      if(res?.payload?.success === false){
        toast.error(res?.payload?.message)
         dispatch(getAllFvrtCollection({user_id:user_id}))
         closeModal()
        return
      }
      toast.success(res?.payload?.message)
      dispatch(getAllFvrtCollection({user_id:user_id}))
       closeModal()
      })
      }
     
    }
  return (
    <div className="div">

<div className={`backdrop ${ModalIsOpen ? 'active' : ''}`}  />
       
                   <Modal
        isOpen={ModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >

<div className="d-flex justify-content-between align-items-center">
          <div className="w-100 text-center">
            <p className="h3 fs-4 mt-md-3" style={{ fontWeight: '700', color: 'rgb(112, 155, 165)' ,opacity:'0.8'}}>
              Add To Favourite Collection
            </p>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
          <FontAwesomeIcon className='text-muted fs-3 mb-4 me-2' style={{cursor:'pointer'}} icon={faXmark} onClick={closeModal} />
          </div>
        </div>

        <div className="border-bottom my-3"  style={{opacity:'0.5'}}  ></div>

       <div className="container">
        <ul className="" style={{listStyle:'none'}}>
          <li className="p-2 w-100 ps-0">
           
            <div className="d-flex    justify-content-between align-items-center">
              <div className=' w-75 border rounded-3'>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="compaignSearch form-control w-100 border-0 rounded-2 bg-transparent text-dark"
                  placeholder="Search Your Collection"
                  style={{outline:'none'}}
                  onClick={handleDropdownClick}
                />
              </div>
            
           
              <AddCircleOutlineIcon className='fs-1 me-3' style={{color:"lightgrey",cursor:"pointer"}}   onClick={sign} />
             
           
               
              </div> 
           
          </li>
          {filteredCollections?.map((item, i) => {
            return (
              <li className="px-2 my-2" key={i}>
                <div className="d-flex">
                  <div className="w-100">
                     <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={item._id === selectedStyleId}
                    onChange={() => handleCheckboxChange(item._id)}
                    id={`checkbox${i}`}
                  />
                  <label class="form-check-label" for={`checkbox${i}`}>
                    {item.title}
                  </label>
                </div>
                  </div>
                  <div className="w-100 text-end">
                    <DeleteForeverIcon style={{color:"lightgrey",cursor:"pointer"}}  onClick={()=>deletecollection(item._id)}/>
                  </div>
                </div>
               
              </li>
            );
          })}
          <li className="p-1" onClick={handleNewCollectionClick}>
            <div className="row border-top mt-2">
             

<div className="row  mt-1 pt-2">

  {
    showSign && (
      <> 
      <div className="col-9 p-0  ps-3 ">
                
                  <input
                    type="text"
                    name="title"
                    className="form-control dropdown-toggle"
                    value={newCollectionTitle.title}
                    
                    onChange={handleNewCollectionChange}
                    placeholder="Add New..."
                    onClick={handleDropdownClick}
                  />
               
              </div>

               <div className="col-3  d-flex align-items-center justify-content-end  text-end">
            <div>
               <AddCircleOutlineIcon className='fs-3' style={{ color: "#74a8b0" ,cursor:'pointer'}} onClick={handleNewCollectionSave}/>
            </div>
               
              </div>

              
      </>
     
    )
  }
            
             
            </div>,

            </div>
          </li>
<div className=" pt-2 text-center pe-md-5">
<button className="btn  btn-sm  text-center  rounded-pill px-3" style={{backgroundColor:'#74a8b0',color:'white'}} onClick={saveMusic}>Add To Favourite</button>

</div>
        </ul> 
       </div>
       
   
        
      </Modal> 
           
 
 

  

     
    </div>
  );
};

export default AddFvrtMusicModal;
