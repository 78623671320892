import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllArts, getUserArts } from "../../../redux/features/auth/authSlice";
import CloseIcon from '@mui/icons-material/Close';
import UserUloadArtVisibility from "../../../Components/UploadArt/UserUploadArtVisibility";
import FormateList from "./FormateList";
import StyleList from "./StyleList";
import '../../../Styles/uploadmodalresponsivness.css'
const UserUploadArtDetail = ({ selectedFile, close }) => {

  const [processing, setProcessing] = useState(1);

  const { userList } = useSelector((state) => state.auth);
  const filterLists = useSelector((state) => state.auth.allArts);

  const user = JSON.parse(localStorage.getItem("user"));
  const _id = user?.user[0]?._id;

  const User = JSON.parse(localStorage.getItem("user"));
  const user_id = User?.user[0]._id;

  const recieveStyleFromChild = (id) => {
    setAddArt((prevstate) => ({
      ...prevstate,
      style: id
    }))
  }

  const recieveFormatFromChild = (id) => {
    setAddArt((prevstate) => ({
      ...prevstate,
      format: id
    }))
  }

  const Dimensions = ["CM","MM","INCH"]
  const Materials = ["Paper","Oil Pastel","Charcoal Pencil","Acrylic Paint","Coloured Pencil","Watercolor Paper","Tempera","Textile"]

  const [addArt, setAddArt] = useState({
    title: "",
    description: "",
    format: '',
    style: '',
    material: Materials[0],
    dimension1: '0',
    dimension2: '0',
    dimension3: '0',
    dimension4: Dimensions[0],
    tags: '',
    user_id: user_id,
    image: selectedFile
  });
  console.log("🚀 ~ file: UserUploadArtDetail.jsx:55 ~ UserUploadArtDetail ~ addArtttt:", addArt)




  const [errors, setErrors] = useState({
    title: "",
    description: "",
    format: '',
    style: "",
    dimension1: '',
    dimension2: '',
    dimension3: '',

  });

  const dispatch = useDispatch();

  const onchange = (e) => {
    const { name, value } = e.target;
    setAddArt({ ...addArt, [name]: value });
  };



  useEffect(() => {
    const getData = async () => {
      await dispatch(getAllArts())
    }
    getData()
  }, [dispatch])





  useEffect(() => {
    const getData = async () => {
      await dispatch(getUserArts({ user_id: _id }));
    };
    getData();
  }, [_id, dispatch]);






  const validateForm = () => {
    let isValid = true;
    const updatedErrors = {
      title: "",
      description: "",
      tags: '',
      format: '',
      style: '',
      dimension1: '',
      dimension2: '',
      dimension3: '',


    };

    if (addArt.title.trim() === "") {
      updatedErrors.title = "Title is required";
      isValid = false;
    }

    if (addArt.description.trim() === "") {
      updatedErrors.description = "Description is required";
      isValid = false;
    }
    if (addArt.tags.trim() === "") {
      updatedErrors.tags = "tags is required";
      isValid = false;
    }
    if (addArt.format?.trim() === '') {
      updatedErrors.format = 'format are required';
      isValid = false;
    }
    if (addArt.style?.trim() === '') {
      updatedErrors.style = 'style are required';
      isValid = false;
    }
    if (addArt.dimension1 === '0') {
      updatedErrors.dimension1 = 'dimension are required';
      isValid = false;
    }
    if (addArt.dimension2 === '0') {
      updatedErrors.dimension2 = 'dimension are required';
      isValid = false;
    }
    if (addArt.dimension3 === '0') {
      updatedErrors.dimension3 = 'dimension are required';
      isValid = false;
    }

    setErrors(updatedErrors);
    return isValid;
  };


  const next = async () => {
    if (!selectedFile) {
      return;
    }

    if (!validateForm()) {
      return;
    }

    if (validateForm()) {
      setProcessing(processing + 1);
    }

  };


  const backPage = () => {
    setProcessing(processing - 1);
  }





  return (
    <>

      {
        processing === 1 ? (
          <>
            <div className=" uploadArtDetail-row" >
              <div className="uploadArtDetail-textDetail ">
                <div className="form-group my-2">
                  <p
                    className="fw-semibold h4"
                    style={{
                      color: "#74A8B0",
                    }}
                  >
                    Details
                  </p>
                  <input
                    type="text"
                    onChange={onchange}
                    value={addArt.title}
                    className={`form-control project-input ${errors.title && "is-invalid"}`}
                    name="title"
                   
                    placeholder="Title"
                  />
                  {errors.title && (
                    <div className="invalid-feedback">{errors.title}</div>
                  )}
                </div>
                <div className="form-group my-4">

                  <textarea
                    className={`form-control project-input ${errors.description && "is-invalid"}`}
                    onChange={onchange}
                    value={addArt.description}
                    name="description"
                    placeholder=" Description "
                    rows="4"
                  ></textarea>
                  {errors.description && (
                    <div className="invalid-feedback">{errors.description}</div>
                  )}
                </div>
                <div className="row">
                  <div className="col py-0">
                    <div className="form-group ">
                      <label htmlFor="" className="fs-5" style={{ color: "rgb(116, 168, 176)" }}>Format</label>
                      <div className={`form-control border-0 p-0 ${errors.format && "is-invalid"}`}>
                        <FormateList sendToParentFormatId={recieveFormatFromChild} callType={"upload"} selectedFormat={addArt?.format} />
                      </div>
                      {errors.format && <div className="invalid-feedback">{errors.format}</div>}

                    </div>



                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="" className="fs-5" style={{ color: "rgb(116, 168, 176)" }}>Style</label>
                      <div className={`form-control border-0 p-0 ${errors.style && "is-invalid"}`}>
                        <StyleList sendToParentStyleId={recieveStyleFromChild} callType={"upload"} selectedStyle={addArt?.style}/>

                      </div>
                      {errors.style && <div className="invalid-feedback">{errors.style}</div>}
                    </div>


                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col">
                    <div className="form-group ">
                        <label htmlFor="" className="fs-5" style={{ color: "#74A8B0" }}>Dimensions</label>
                    <div className={`form-control border-0 p-0 ${errors.dimension1 && "is-invalid"} d-flex align-items-center mt-1`}>
                      <input
                        type="text"
                        onChange={onchange}
                        value={addArt.dimension1}
                        className={`form-control project-input ${errors.dimension1 && "is-invalid"}`}
                        name="dimension1"
                       
                        placeholder="100"
                      />

                      <span className="text-muted"> <CloseIcon style={{ fontSize: '13px' }} /></span>
                      <input
                        type="text"
                        onChange={onchange}
                        value={addArt.dimension2}
                        className={`form-control project-input ${errors.dimension2 && "is-invalid"}`}
                        name="dimension2"
                       
                        placeholder="100"
                      />

                      <span className="text-muted"> <CloseIcon style={{ fontSize: '13px' }} /></span>
                      <input
                        type="text"
                        onChange={onchange}
                        value={addArt.dimension3}
                        className={`form-control project-input ${errors.dimension3 && "is-invalid"}`}
                        name="dimension3"
                      
                        placeholder="10"
                      />
                      
                    </div>
                    { errors.dimension3 && <div className="invalid-feedback ">{errors.dimension1}</div>}
                    </div>
                  
                  </div>
                  <div className="col">
                    <label htmlFor="" className="fs-5" style={{ color: "rgb(116, 168, 176)" }}>Material</label>

                    <select className="form-control mt-1" id="exampleFormControlSelect1"
                      value={addArt.material}
                      onChange={onchange}
                      name="material"
                      style={{ fontWeight: '600', color: 'rgb(116, 168, 176)' }}
                    >
                      {
                        Materials?.map((mat, i) => {

                          return (
                            <option key={i} value={mat}>{mat}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6">
                    <select className="form-control" id="exampleFormControlSelect1"
                      value={addArt.measurments}
                      onChange={onchange}
                      name="measurments"
                      style={{ fontWeight: '600', color: 'rgb(116, 168, 176)' }}
                    >
                      {
                        Dimensions?.map((dim, i) => {

                          return (
                            <option key={i} value={dim}>{dim}</option>
                          )
                        })
                      }
                    </select>

                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <input
                      type="text"
                      onChange={onchange}
                      value={addArt.tags}
                      className={`form-control project-input ${errors.tags && "is-invalid"}`}
                      name="tags"
                      
                      placeholder="#hashtage"
                    />
                    {errors.tags && (
                      <div className="invalid-feedback">{errors.tags}</div>
                    )}
                  </div>
                </div>

              </div>

              <div className="uploadArtDetail-image ">
                <div className="image d-flex justify-content-center align-items-center w-100">

                  <div className="d-flex justify-content-center mb-md-5 mb-sm-2 ms-4">



                    <div className=""
                      style={{


                        height: '350px',
                        overflow: 'hidden', // Ensure that content beyond the card size is hidden
                      }}
                    >
                      <div className='' style={{
                        // width: "19.5rem",

                        height: '350px',
                        overflow: 'hidden', // Ensure that content beyond the card size is hidden
                      }}>
                        <img

                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            cursor: 'pointer',
                            position: 'relative'
                          }}
                          src={selectedFile ?

                            URL.createObjectURL(selectedFile) :
                            ''}
                          className=" img-fluid  "
                          alt="Not data"

                        />
                      </div>


                    </div>


                  </div>


                </div>

              </div>









            </div>
            <div className="d-flex justify-content-end text-end mt-3 border-top">
              <div className=" pt-2">
                <button
                  className="btn text-white px-4"
                  // onClick={addArtUser}
                  onClick={next}
                  style={{ backgroundColor: "#74A8B0", textTransform: 'uppercase', letterSpacing: '0.5px' }}
                >
                  Next
                </button>
              </div>
            </div>
          </>



        ) : processing === 2 ?
          (
            <UserUloadArtVisibility
              addArt={addArt}
              close={close}
              backPage={backPage}
            />
          ) : ''
      }


    </>
  );
};

export default UserUploadArtDetail;
