import React, { useState, useRef, useEffect } from 'react';
import './player.css';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import DownloadIcon from '@mui/icons-material/Download';
import StarIcon from '@mui/icons-material/Star';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { baseURL } from '../../redux/axios/axios';
import CircularProgress from '@mui/material/CircularProgress';
import uploadimg from '../../assets/icons/uploadblog.png'

import axios from 'axios';
import { toast } from 'react-toastify';
import AddFvrtMusicModal from '../Music/AddFvrtMusicModal';
import { useNavigate } from 'react-router-dom';

const CustomPlayer = ({audiodata,detailsPage,detailpageStyle}) => {

  let login = JSON.parse(localStorage.getItem('user'));
    const [playToggle, setPlayToggle] = useState(false);
  const [currentAudio, setcurrentAudio] = useState()
  const [previousId, setPreviousId] = useState()
  const [audioStates, setAudioStates] = useState([]);
  const [downloadOpen, setdownloadOpen] = useState();
  const [musicData,setMusicData] = useState([])
  const [audioPlayStatus, setaudioPlayStatus] = useState({
    id:null,
    status:false,
  })
  const [openModalFvrt, setopenModalFvrt] = useState(false)
// console.log('current',currentAudio);
// console.log('prev',previousId);




  const audioRefs = useRef( audiodata?.map(() => React.createRef()));
  const navigate = useNavigate()
  
 
  const togglePlay = (index) => {
  
    const audioRef = audioRefs.current[index];
   

    if (audioRef) {

    
      if(currentAudio===index && previousId!==index && !playToggle)
      { 
        audioRefs.current[currentAudio]?.current.pause();
        setPlayToggle(!playToggle)
          console.log("2");

      }
      else if(currentAudio===index && previousId!==index && playToggle)
      { 
        audioRef.current.play();
        setPlayToggle(!playToggle)
        console.log("3");

      }
      
      else if(currentAudio!==index)
      { 
        audioRefs.current[currentAudio]?.current.pause();
        audioRef?.current.play();
        setPlayToggle(false)
        setPreviousId(currentAudio)
        setcurrentAudio(index)
        console.log("4");

      }
     
      
      const isPlaying = !audioRef.current.paused;

      // Now 'isPlaying' will be true if the audio is playing, and false if it's paused
      console.log('Is playing:', isPlaying);
      setaudioPlayStatus((prev)=>({
        ...prev,
        id:index,
        status:isPlaying

      }))
     
    }
  };

  const  moveToUserDetail=(id)=>{
    navigate('/profile-for-main',{state:{id:id}})
   }
  

  // Function to update the progress for a specific audio file
  const updateProgressBar = (index) => {
    const audioRef = audioRefs?.current[index]?.current;

    if (audioRef) {
      const currentTime = audioRef?.currentTime;
      const newProgress = (currentTime / audioRef.duration) * 100;

      setAudioStates((prevAudioStates) => {
        const updatedStates = [...prevAudioStates];
        updatedStates[index] = { ...updatedStates[index], progress: newProgress };
        return updatedStates;
      });
    }
  };

  // Function to handle metadata load for a specific audio file
  const handleMetadataLoad = (index) => {
    audiodata?.forEach((item, i) => {
      const audioRef = audioRefs?.current[i]?.current;
  
      if (audioRef) {
        setAudioStates((prevAudioStates) => {
          const updatedStates = [...prevAudioStates];
          updatedStates[i] = { ...updatedStates[i], duration: audioRef.duration };
          return updatedStates;
        });
      } else {
        console.log(`Audio element at index ${i} is not loaded yet.`);
      }
    });
  
    // Rest of your code...
  };
  

  // Function to handle progress bar click for a specific audio file
  const handleProgressBarClick = (index, event) => {
    const audioRef = audioRefs?.current[index]?.current;

    if (audioRef) {
      const progressBar = event.currentTarget;
      const clickPosition = event.clientX - progressBar.getBoundingClientRect().left;
      const percentage = (clickPosition / progressBar.offsetWidth) * 100;
      const newTime = (percentage / 100) * audioRef.duration;
      audioRef.currentTime = newTime;

      setAudioStates((prevAudioStates) => {
        const updatedStates = [...prevAudioStates];
        updatedStates[index] = { ...updatedStates[index], progress: percentage };
        return updatedStates;
      });
    }
  };

const formatTime = (time) => {
  if (!isNaN(time)) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  } else {
    return '0:00';
  }
};



useEffect(() => {
  if (audiodata?.length > 0) {

     const handleMetadataLoadHandler = () => handleMetadataLoad(currentAudio);
  const updateProgressBarHandler = () => updateProgressBar(currentAudio);

  audiodata?.forEach((_, i) => {
    const audioRef = audioRefs?.current[i]?.current;
    if (audioRef) {
      audioRef.addEventListener('loadedmetadata', handleMetadataLoadHandler);
      audioRef.addEventListener('timeupdate', updateProgressBarHandler);
    }
  });

  return () => {
    audiodata?.forEach((_, i) => {
      const audioRef = audioRefs.current[i]?.current;
      if (audioRef) {
        audioRef.removeEventListener('loadedmetadata', handleMetadataLoadHandler);
        audioRef.removeEventListener('timeupdate', updateProgressBarHandler);
      }
    });
  };
  }
 
}, [currentAudio, playToggle,audiodata]);

  



const [windowWidth, setWindowWidth] = useState(window.innerWidth);

useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

const download = async (soundId,soundTitle) => {
  try {
    setdownloadOpen(soundId);


    const response =  await axios.post(`${baseURL}/sound/sounds/download`, {
      soundIds: [soundId],
      }, {
        responseType: 'blob',
       
      });
 
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${soundTitle}.zip`; 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    
  } catch (error) {
    console.error('Failed to download sounds:', error);
  } finally {
    setdownloadOpen();
  }
};

const openFvrt = (item) =>{
  if (login) {
    setMusicData(item);
    setopenModalFvrt(!openModalFvrt);
  } else {
    toast.error('Please login to store music in your collection.',{autoClose:2000}); 
  }
 } 

  return (
    <>


<AddFvrtMusicModal
        closeModal={openFvrt}
        ModalIsOpen={openModalFvrt}
        selectedCheckboxes={musicData}
      />
    {audiodata && audiodata?.map((item,i)=>{



   
    const title6 = item?.title?.slice(0,15)
     

      return(
       
        <div className={`player-container shadow my-1 p-0 ${detailpageStyle===true? "detail-style":''}`} key={i} >
        <div className="inner-content" 
        >
          <div className="player-image"  style={{cursor:"pointer"}} onClick={detailpageStyle===true? '': ()=>detailsPage(item)}>
            <img src={item?.image?.length>0 ? item.image[0]:uploadimg} alt="" style={{ width: '50px',height:'50px' }} />
          </div>

          <div className="play-button">
          
            {
              audioPlayStatus?.id===i && audioPlayStatus?.status ?
              <PauseIcon
              style={{ color: 'white', fontSize: '30px' }}
              onClick={() => togglePlay(i)}
            />:
            <PlayArrowIcon
            style={{ color: 'white', fontSize: '30px' }}
            onClick={() => togglePlay(i)}
          />
          
            }
          </div>

          <div className={`artist-title ${windowWidth <= 600 ? 'ellipsis' : ''}`}>
        <div>
          <p className="m-0 text-white" style={{ fontSize: '14px' }}>
            <span className="title-text">
              {
          item?.title?.length>15 ?`${title6}...`:`${item.title}`}</span>
            <span style={{ color: 'yellow', display:`${  windowWidth <= 600 ? 'none':''}` }}>{' '}NEW</span>
          </p>
          <small className=""  onClick={() => moveToUserDetail(item.owner_id)} style={{ color: '#E6E2DF', cursor:"pointer" }}>{item?.ownerName}</small>
        </div>
      </div>

          <div className="music-type">
            <div>
              <p className="m-0" style={{ fontSize: '14px', color: 'white' }}>
                Music Type
              </p>
            </div>
          </div>

        
              <div className="player-timer">
                <p className="m-0" style={{ fontSize: '14px', color: 'white' }}>
                  {formatTime(audioRefs.current[i]?.current?.currentTime)} / {formatTime(audioStates[i]?.duration)}
                </p>
              </div>
           
            <div className='big-bar'>
              <div className="progress-bar" onClick={(event) => handleProgressBarClick(i, event)}>
                
                <div
                  className="progress-indicator"
                  style={{ width: `${audioStates[i]?.progress}%` }}
                />
              </div>
            </div>

          <div className="player-options">
            <div className="player-icons">
            {downloadOpen===item._id? <CircularProgress style={{height:"12px", width:"12px", color:"white"}}  />:
              <DownloadIcon 
              onClick={()=>download(item._id,item.title)}
               style={{ color: 'white', fontSize: '20px', cursor:'pointer' }} /> }
            </div>
            <div className="player-icons">
              <StarIcon style={{ color: 'white', fontSize: '20px',cursor:'pointer' }}    onClick={()=>openFvrt(item?._id)} />
            </div>
            <div className="player-icons">
            
              <div class="dropdown">
   <MoreVertIcon className='dropdown-toggl' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: 'white', fontSize: '20px',cursor:'pointer' }} />
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><a class="dropdown-item"  onClick={detailpageStyle===true? '': ()=>detailsPage(item)} >Detail</a></li>
   
  </ul>
</div>
            </div>
          </div>

          {/* Hidden audio element */}
          <audio ref={audioRefs.current[i]} src={item?.file?.length>0? item.file[0]:''} />
         
        </div>



        {/* player for below 800px */}


        
        <div className=' d-flex justify-content-center align-items-center small-bar'>
              <div className="small-progress-bar" onClick={(event) => handleProgressBarClick(i, event)}>
                
                <div
                  className="small-progress-indicator"
                  style={{ width: `${audioStates[i]?.progress}%` }}
                />
              </div>
            </div>
      </div>
        
      )
    })}
      





     
    </>
  );
};

export default CustomPlayer;
