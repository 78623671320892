import React, { useState,useEffect } from "react";

import cover from "../assets/images/cover.png";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
// import { AccountDetail } from "../Screens/UserDashboard/AccountDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import '../Dashboard/Sidebar/sidebar.css'
// import PaymentSetting from "../Screens/UserDashboard/PaymentSetting";
// import AddPayment from "../Screens/UserDashboard/AddPayment";
import UploadArtWork from "./UploadArt/UploadArtWork";
// import Profile from "../Screens/UserDashboard/Profile/Profile";
// import UserProfile from '../Screens/AdminDashboard/Profile/Profile'
import Message from "../Screens/UserDashboard/Messaging/Message";
import { useLocation, useNavigate } from "react-router-dom";
import DonationScreen from "../Screens/UserDashboard/Donations/DonationScreen";
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
// import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import UpdatePassword from "../Screens/UserDashboard/UpdatePassword";
import PublicProfile from "../Screens/UserDashboard/PublicProfile/PublicProfile";
import { io } from "socket.io-client";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { baseURL } from "../redux/axios/axios";

export default function LandingSideBar() {
  // const [buttonClicked, setButtonClicked] = useState(false);


  const [sidebarVisible, setSidebarVisible] = useState(false);
  const socket = io(baseURL);
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  function handleSidebarClick() {
    setSidebarVisible(!sidebarVisible);
  }
  const USER = JSON.parse(localStorage.getItem("user"))
  

  // const screenShift = () => {
  //   setButtonClicked(!buttonClicked);
  // };
  const eventKey = location.state?.eventKey;
  const MessageToUser = location.state?.user_id

  useEffect(() => {
    // Listen for the 'userBlocked' event
    socket.on('userBlocked', (data) => {

      if(data?.userId === USER?.user[0]?._id){
        localStorage.clear()
        navigate('/')
      }
      toast.success(data?.message,{
        autoClose:4000
      })
      // Perform any additional actions when a user is blocked via socket
      // For example, you can update the UI or fetch the updated user list

    });
  
    // Cleanup socket connection on component unmount
    return () => {
      socket.disconnect();
      console.log('Disconnected from the server');
    };
  }, [dispatch]);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${cover})`,
          backgroundSize: "cover",

          width: "100%",
          height: "170px",
        }}
      >
        {/* Content */}
      </div>

      <div>
        {/* <h2>User Dashboard</h2> */}
        <Tab.Container id="left-tabs-example" defaultActiveKey={`${ eventKey ? eventKey:'public'}`}>
          <Row className="m-0 mt-3 mb-3 handleWidth" >
            <Col
              sm={2}
              className={`sidebar-area shadow-end rounded-4 card  pt-md-5 pt-sm-3 pt-xs-0 pt-0 ${
                sidebarVisible ? "active" : ""
              }`}
              style={{
               
                position: "relative",
                boxShadow: "5px 5px 3px #dbc6c6",
                zIndex:1
              }}
            >
              <button
                type="button"
                onClick={handleSidebarClick}
                className="text-start sideBar-icon bg-transparent border-0 "
              >
                <FontAwesomeIcon icon={faList} />
              </button>

              <Nav variant="pills" className="flex-column">
                {/* side menu titles */}
                <div>
                  <Nav.Item>
                      <p
                    className="text-middle openSans-300 mt-2  ms-3"
                    style={{ margin: "0.2rem", color: "#709CA6" }}
                  >
                    SETTINGS
                  </p>
                  </Nav.Item>
                
                </div>
                  

                <Nav.Item className="ps-3">
                  <Nav.Link
                    className="fs-6 d-flex p-0 py-2 mt-2"
                    style={{ margin: "0.2rem" , background:'none'}}
                    eventKey="public"
                  >
                    <div className="d-flex align-items-center">
                      <div className="usersidebar-icon d-flex align-items-center mx-2">
                        <AccountBoxOutlinedIcon/>
                      </div>
                      <div className="d-flex align-items-center">    <p className="sidebarTitle title text-middle m-0 fw-bold openSans-400 p-0  " style={{textTransform:'capitalize'}}>Public Profile</p>{" "}</div>
                    </div>
                
                  </Nav.Link>
                </Nav.Item> 

               
             

                <Nav.Item className="ps-3">
                  <Nav.Link
                    className="fs-6 d-flex p-0 py-2"
                    style={{ margin: "0.2rem" , background:'none' }}
                    eventKey="fourth"
                  >
                      <div className="d-flex align-items-center">
                      <div className="icon  d-flex align-items-center mx-2">
                        <AccountBalanceOutlinedIcon/>
                      </div>
                      <div className="d-flex align-items-center"><p className="text-middle title m-0 sidebarTitle fw-bold openSans-400 p-0 " style={{textTransform:'capitalize'}}>
                     Account detail
                    </p></div>
                    </div>
                    
                  </Nav.Link>
                </Nav.Item>

             
                <Nav.Item className="ps-3">
                  <Nav.Link
                    className="fs-6 d-flex p-0 py-2"
                    style={{ margin: "0.2rem" , background:'none' }}
                    eventKey="seventh"
                  >
                      <div className="d-flex align-items-center">
                      <div className="icon  d-flex align-items-center mx-2">
                        <VolunteerActivismOutlinedIcon/>
                      </div>
                      <div className="d-flex align-items-center"> <p className="text-middle title m-0 sidebarTitle openSans-400 fw-bold p-0 " style={{textTransform:'capitalize'}}>
                     Payment Settings
                    </p></div>
                    </div>
                   
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className="ps-3">
                  <Nav.Link
                    className="fs-6 d-flex p-0 py-2"
                    style={{ margin: "0.2rem" , background:'none'}}
                    eventKey="fifth"
                  >
                      <div className="d-flex align-items-center">
                      <div className="icon  d-flex align-items-center mx-2">
                        <FileUploadOutlinedIcon/>
                      </div>
                      <div className="d-flex align-items-center"> <p className="text-middle title m-0 sidebarTitle openSans-400 p-0  fw-bold" style={{textTransform:'capitalize'}}>
                      Your artwork
                    </p></div>
                    </div>
                   
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className="ps-3">
                  <Nav.Link
                    className="fs-6 d-flex p-0 py-2"
                    style={{ margin: "0.2rem" , background:'none'}}
                    eventKey="sixth"
                  >
                      <div className="d-flex align-items-center">
                      <div className="icon  d-flex align-items-center mx-2">
                        <MessageOutlinedIcon/>
                      </div>
                      <div className="d-flex align-items-center"> <p className="text-middle title m-0 sidebarTitle openSans-400 p-0 fw-bold " style={{textTransform:'capitalize'}}>
                     Messages
                    </p></div>
                    </div>
                   
                  </Nav.Link>
                </Nav.Item>
            {/* <Nav.Item className="">
                  <Nav.Link
                    className="fs-6 d-flex p-0 py-2 mt-2"
                    style={{ margin: "0.2rem" , background:'none'}}
                    eventKey="first"
                  >
                    <div className="d-flex align-items-center">
                      <div className="icon">
                        <AccountBoxOutlinedIcon/>
                      </div>
                      <div className="d-flex align-items-center">    <p className="sidebarTitle text-middle m-0 fw-bold openSans-400 p-0 ps-3 bg-none" style={{textTransform:'capitalize'}}>Profile</p>{" "}</div>
                    </div>
                
                  </Nav.Link>
                </Nav.Item>  */}

      
              </Nav>
            </Col>
            <Col
              sm={10}
              className="sidebar-screens card rounded-2"
              style={{
                backgroundColor: "#F8F8F8",
                paddingTop: "30px",
                boxShadow: "5px 5px 3px #dbc6c6",
             
              }}
            >
              <Tab.Content>
              <Tab.Pane
                  style={{ color: "black", border: "none" }}
                  eventKey="public"
                > <PublicProfile /> </Tab.Pane>

                {/* <Tab.Pane
                  style={{ color: "black" }}
                  eventKey="first"
                >
               

                  {buttonClicked ? (
                    <UserProfile onButtonClick={screenShift} />
                  ) : (
                    <Profile onButtonClick={screenShift} />
                  )}
                </Tab.Pane> */}
                {/* <Tab.Pane
                  style={{ color: "black", border: "none" }}
                  eventKey="second"
                ></Tab.Pane> */}

            
                <Tab.Pane
                  style={{ color: "black", border: "none" }}
                  eventKey="fourth"
                >
                <UpdatePassword />
                  {/* {buttonClicked ? (
                    <AddPayment onButtonClick={screenShift} />
                  ) : (
                    <PaymentSetting onButtonClick={screenShift} />
                  )} */}
                </Tab.Pane>

                <Tab.Pane
                  style={{ color: "black", border: "none" }}
                  eventKey="fifth"
                >
                  <UploadArtWork />
                </Tab.Pane>

                <Tab.Pane
                  style={{ color: "black", border: "none" }}
                  eventKey="sixth"
                >
                 <Message userId={MessageToUser}/>
                </Tab.Pane>

                
                <Tab.Pane
                  style={{ color: "black", border: "none" }}
                  eventKey="seventh"
                >
                 <DonationScreen/>
                </Tab.Pane>

            

              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
}
