import React,{useEffect, useRef, useState} from 'react'
import uploadimg from "../../../assets/icons/uploadblog.png"

import { useDispatch, useSelector } from 'react-redux';
import { EditUserArtApi, getAllArts, getArtDetails, getSoundDetail, getUserArts } from '../../../redux/features/auth/authSlice';
import CloseIcon from '@mui/icons-material/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import '../../../Styles/visibililtyscreen.css'
import '../../../Styles/uploadmodalresponsivness.css'
import StyleList from './StyleList';
import FormateList from './FormateList';


const EditUserUploadArt = ({closeWindow,selectedCheckboxes}) => {

  const User = JSON.parse(localStorage.getItem('user'))
  const user_id = User?.user[0]._id
  const id =selectedCheckboxes[0]
  const Materials = ["Paper","Oil Pastel","Charcoal Pencil","Acrylic Paint","Coloured Pencil","Watercolor Paper","Tempera","Textile"]
  const Dimensions = ["CM","MM","INCH"]



  const filterLists = useSelector((state) => state.auth.allArts);

const [imageUrl, setimageUrl] = useState(null)
  const [userImage, setuserImage] = useState(null)

       const [EditArt,setEditArt] = useState({
        title: "",
        description: "",
        format:filterLists?.formats?._id, 
        style:filterLists?.styles?._id,
        material :'',
        dimension1:'',
        dimension2:'',
        dimension3:'',
        dimension4:'',
        tags:'',
         user_id,
       
       })
       console.log("🚀 ~ EditUserUploadArt ~ EditArt:", EditArt)
       
       const [visibility, setVisibility] = useState('');
       const [donation, setDonation] = useState('');

       const handleDonationChange = (event) => {
        setDonation(event.target.value);
      };
      const handleVisibilityChange = (event) => {
        const selectedVisibility = event.target.value;
        setVisibility(selectedVisibility);
  
        if (selectedVisibility === 'private') {
          setDonation('none');
        }
        if (selectedVisibility === 'everyone') {
          setDonation('everyone');
        }
      };
   
   const dispatch = useDispatch()

   const fileInputRef = useRef(null);

   // Function to handle avatar click and trigger file input
   const handleAvatarClick = () => {
     fileInputRef.current.click();
   };




useEffect(()=>{
  const getValues = async()=>{
    dispatch(getAllArts())
    const data = await dispatch(getArtDetails(id))
    const values = data.payload?.data
    console.log("🚀 ~ getValues ~ values:", values)
    
    if(values){
      setEditArt((prev)=>({
        ...prev,
        title:values?.title,
        description:values?.description,
        dimension1:values?.dimension1,
        dimension2:values?.dimension2,
        dimension3:values?.dimension3,
        dimension4:values?.dimension4,
        format:values?.format,
        style:values?.style,
        tags:values?.tags,
        material:values?.material,
       user_id,
       id:id
      }))
      setimageUrl(values?.image)
    }
      setVisibility(values?.visibility)
      setDonation(values?.donation)
  }
  getValues()
},[])

   
   const onchange = (e) => {
     const { name, value } = e.target;
     setEditArt({ ...EditArt, [name]: value })
   };

   const recieveStyleFromChild = (id)=>{
    setEditArt((prevstate)=>({
      ...prevstate,
      style:id
    }))
  }
  const recieveFormatFromChild = (id)=>{
    setEditArt((prevstate)=>({
        ...prevstate,
        format:id
      }))
    }

   const handleFileChange = (e) => {
    const  file  = e.target.files[0];
 setuserImage(file)

  };
 

  


  const EditArtUser = async() => {

    const formData = new FormData()

    formData.append('visibility',visibility)
    formData.append('donation',donation)

    for (const key in EditArt) {
      if (EditArt.hasOwnProperty(key)) {
          formData.append(key, EditArt[key]);
      }
    }

if(userImage){
  formData.append('image',userImage)
}
    
   await dispatch(EditUserArtApi(formData))
      .then(() => {
        closeWindow();
  
        toast.success('Art Updated Successfully', {
          position: "top-center",
          autoClose: 1000,
        });
  
        dispatch(getUserArts({
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          user_id,
        }));
      });
  };
  

     



  return (
    <>
     <div className="container-fluid " >
     <div className="row border-bottom fs-4  p-1">
            <div className="col">
                <p className='mb-3' style={{color:'#74A8B0',fontWeight:'700'}} >Edit details</p>
            </div>
            <div className="col d-flex justify-content-end">
            <FontAwesomeIcon className='text-muted fs-3' style={{cursor:'pointer'}} icon={faXmark} onClick={closeWindow} />
            </div>
        </div>
       <div className='uploadArtDetail-row'>
   <div className="uploadArtDetail-textDetail ">
          <div className="form-group my-2">
          <p
          className="fw-semibold h4"
          style={{
            color: "#74A8B0",
            fontWeight:'700'
          }}
        >
          Details
        </p>
            <input
              type="text"
              onChange={onchange}
              value={EditArt.title}
              className={`form-control project-input fw-bold `}
              name="title"
             
              placeholder="Pop CAT"
            />
           
          </div>
          <div className="form-group my-4">
          {/* <p
          className="fw-semibold h4"
          style={{
            color: "#74A8B0",
          }}
        >
          Description
        </p> */}
            <textarea
              className='form-control project-input'
              onChange={onchange}
              value={EditArt.description}
              name="description"
              placeholder="Any Description here"
              rows="6"
            ></textarea>
           
          </div> 
           <div className="row">
        <div className="col py-0">
          <label htmlFor="" className="fs-5 fw-bold" style={{color:"rgb(116, 168, 176)"}}>Format</label>
      
        <FormateList sendToParentFormatId={recieveFormatFromChild} initalFormat={EditArt?.format} callType={"edit"}/>
        </div>
        <div className="col">
        <label htmlFor="" className="fs-5 fw-bold" style={{color:"rgb(116, 168, 176)"}}>Style</label>
        
        <StyleList sendToParentStyleId={recieveStyleFromChild} initalStyle={EditArt?.style} callType={"edit"}/>
        </div>
             </div>
      
            <div className="row mt-1">
        <div className="col"> 
        <label htmlFor="" className="fs-5 fw-bold" style={{color: "#74A8B0"}}>Dimensions</label>
        <div className="d-flex align-items-center mt-1">
          <input
              type="text"
              onChange={onchange}
              value={EditArt.dimension1}
              className='form-control project-input px-1 '
              name="dimension1"
             
              placeholder="100"
            />
            <span className="text-muted"> <CloseIcon style={{fontSize:'13px'}} /></span>
             <input
              type="text"
              onChange={onchange}
              value={EditArt.dimension2}
              className='form-control project-input px-1 '
              name="dimension2"
             
              placeholder="100"
            />
              <span className="text-muted"> <CloseIcon style={{fontSize:'13px'}} /></span>
             <input
              type="text"
              onChange={onchange}
              value={EditArt.dimension3}
              className='form-control project-input px-1 '
              name="dimension3"
             
              placeholder="10"
            />
             </div> 
        </div>
        <div className="col">
                <label htmlFor="" className="fs-5 fw-bold" style={{color:"rgb(116, 168, 176)"}}>Material</label>
  
        <select class="form-control project-input mt-1 " id="exampleFormControlSelect1"
    value={EditArt.material}
    onChange={onchange}
    name="material"
    
    >
  {
                        Materials?.map((mat, i) => {

                          return (
                            <option key={i} value={mat} >{mat}</option>
                          )
                        })
                      }
               
            
      </select>
        </div>
            </div>

            <div className="row mt-3">
           <div className="col-md-6">
        <select class="form-control project-input" id="exampleFormControlSelect1"
    value={EditArt.dimension4}
    onChange={onchange}
    name="dimension4"
    >
    {
        Dimensions?.map((dim,i)=>{
          
            return (
                <option key={i} value={dim._id}>{dim}</option>
            )
        })
      }
      </select>
        </div>
            </div>
            <div className="row mt-3">
           <div className="col-md-12">
           <input
              type="text"
              onChange={onchange}
              value={EditArt.tags}
              className='form-control'
              name="tags"
              class="form-control project-input"
              placeholder="#Cat #Popat #art #printing"
            />
        </div>
            </div>

        </div>

        <div className="uploadArtDetail-image px-3">
         
         <div className="profile-image-container">
                       <input
                         className=''
                         type="file"
                         ref={fileInputRef}
                         style={{ display: 'none' }}
                         onChange={handleFileChange}
                         accept="image/*"
                       />

                       <div className="avatar d-flex align-items-end" onClick={handleAvatarClick}>
                        



                           
                <div className=""
                style={{
   
  
    height: '350px',
    overflow: 'hidden', // Ensure that content beyond the card size is hidden
  }}
>
  <div className=''style={{
    // width: "19.5rem",

    height: '350px',
    overflow: 'hidden', // Ensure that content beyond the card size is hidden
  }}>
    <img
     
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        cursor: 'pointer', 
        position:'relative'
      }}
      src={
        imageUrl && !userImage ? imageUrl[0] :
          userImage ? URL.createObjectURL(userImage) :
            !userImage && !imageUrl ? uploadimg : ''
      }
      className=" img-fluid  "
      alt="Not data"
    
    />
  </div>

 
               </div>
                       </div>
                     </div>
        </div> 
       </div>
       
       <div className="row mt-4        ">
          <div className="col-md-5 col-sm-12 visibility rounded-2">
                 <p className='fw-semibold h4' style={{ color:'#74A8B0' }}>Visibility</p>
 
            <div className="form-check my-2 mx-2">
              <input
                className="form-check-input"
                type="radio"
                name="visibility"
                value="private"
                checked={visibility === 'private'}
                onChange={handleVisibilityChange}
              />
              <label className="form-check-label" style={{ color:'#74A8B0' }}>
                Private - Only you
              </label>
            </div>

            <div className="form-check my-2 mx-2">
              <input
                className="form-check-input"
                type="radio"
                name="visibility"
                value="everyone"
                checked={visibility === 'everyone'}
                onChange={handleVisibilityChange}
              />
              <label className="form-check-label" style={{ color:'#74A8B0' }}>
                Public - Everyone
              </label>
            </div>
          </div>

         
          <div className="col-md-6 col-sm-12  visibility  rounded-2">
                 <p className='fw-semibold h4' style={{ color:'#74A8B0' }}>Donation Setting</p>
     
            <div className="form-check my-2 mx-2">
              <input
                className="form-check-input"
                type="radio"
                name="donation"
                value="everyone"
                disabled={visibility === 'private'}
                checked={donation === 'everyone'}
                onChange={handleDonationChange}
              />
              <label className="form-check-label" style={{ color:'#74A8B0' }}>
                Everyone
              </label>
            </div>

            <div className="form-check my-2 mx-2">
              <input
                className="form-check-input"
                type="radio"
                name="donation"
                value="none"
                checked={donation === 'none'}
                onChange={handleDonationChange}
              />
              <label className="form-check-label" style={{ color:'#74A8B0' }}>
                None
              </label>
            </div>
          </div>
        

       
      </div>
    
       
         <div className="d-flex justify-content-end text-end mt-3 border-top">
        <div className=" pt-2">
          <button
            className="btn text-white px-4 "
            onClick={EditArtUser}
           
            style={{ backgroundColor: "#74A8B0",textTransform:'capitalize' ,letterSpacing:'0.5px' }}
          >
            Update
          </button>
        </div>
      </div>
      </div>

    </>
  )
}

export default EditUserUploadArt