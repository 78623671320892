import React, { useEffect, useState } from 'react';
import profile from "../../../assets/images/cardImage3.png";
import { useDispatch, useSelector } from 'react-redux';
import { getUserSound, addUserSound } from '../../../redux/features/auth/authSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import uploadBlog from '../../../assets/icons/uploadblog.png'


const UploadMusicVisibility = (props) => {

  const User = JSON.parse(localStorage.getItem('user'))
  const user_id = User?.user[0]._id
  const navigate = useNavigate()
  const { isLoading } = useSelector((state) => state.auth)

  const [uploadedArtCount, setUploadedArtCount] = useState(0);



  const [visibility, setVisibility] = useState('private');
  const [donation, setDonation] = useState('none');
  const [userImage, setuserImage] = useState()


  const dispatch = useDispatch()


  const handleVisibilityChange = (event) => {
    const selectedVisibility = event.target.value;
    setVisibility(selectedVisibility);

    if (selectedVisibility === 'private') {
      setDonation('none');
    }
    if (selectedVisibility === 'everyone') {
      setDonation('everyone');
    }
  };

  const handleDonationChange = (event) => {
    setDonation(event.target.value);
  };

  useEffect(() => {
    const getData = async () => {
      const data = await dispatch(getUserSound({ user_id: user_id }));
      setUploadedArtCount(data?.payload?.data?.length)
    };
    getData();
  }, [user_id, dispatch]);


  const getData = async () => {
    await dispatch(getUserSound({
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      user_id,
    }))
  }

  useEffect(() => {
    if (props?.SelectedImage) {
      setuserImage(props?.SelectedImage)
    } else if (props?.availableImage) {
      convertImageToFileAndSend(props?.availableImage)
    }
  }, [props?.SelectedImage, props?.availableImage])


  const convertImageToFileAndSend = async (imageURL) => {
    try {
      const response = await fetch(imageURL);
      const blob = await response.blob();

      // Create a new File object from the Blob
      const file = new File([blob], 'selectedImage.jpg', { type: 'image/jpeg' });

      // Now you can use the 'file' in your FormData and send it to the API
      setuserImage(file);
    } catch (error) {
      console.error('Error converting image to file:', error);
    }
  };
  const Public = async () => {

    const val = uploadedArtCount + 1;
    setUploadedArtCount(val);

    let formData = new FormData();
    formData.append('title', props.allDetail?.title);
    formData.append('description', props.allDetail?.description);
    formData.append('owner_id', user_id);
    formData.append('mood', props.allDetail?.mood);
    formData.append('genre', props.allDetail?.genre);
    formData.append('instrument', props.allDetail?.instrument);
    formData.append('vocals', props.allDetail?.vocals);
    formData.append('tags', props.allDetail?.tags);
    formData.append('file', props.selectedFile);
    formData.append('image', userImage)
    formData.append('visibility', visibility)

    formData.append('donation', donation)



   
      await dispatch(addUserSound(formData))

        .then(() => {
          getData()
          toast.success('Sound Added Successfully', {
            autoClose: 1000
          })

          props.close()
        })

    



  }



  return (
    <>
      <div className="detail-title mt-1">
        <p className='fw-semibold h4 ' style={{ color: '#74A8B0' }}>Visibility</p>
        <p className='' style={{ color: '#74A8B0' }}>Who can see your artwork</p>
      </div>
      <div className="row">
        <div className="col">
          <div className="visibility border rounded-2">
            <div className="form-check my-2 mx-2">
              <input
                className="form-check-input"
                type="radio"
                name="visibility"
                value="private"
                checked={visibility === 'private'}
                onChange={handleVisibilityChange}
              />
              <label className="form-check-label" style={{ color: '#74A8B0' }}>
                Private - Only you
              </label>
            </div>

            <div className="form-check my-2 mx-2">
              <input
                className="form-check-input"
                type="radio"
                name="visibility"
                value="public"
                checked={visibility === 'public'}
                onChange={handleVisibilityChange}
              />
              <label className="form-check-label" style={{ color: '#74A8B0' }}>
                Public - Everyone
              </label>
            </div>
          </div>

          <div className="detail-title mt-5">
            <p className='fw-semibold h4' style={{ color: '#74A8B0' }}>Donation Setting</p>
            <p style={{ color: '#74A8B0' }}>We can donate your artwork</p>
          </div>

          <div className="visibility border rounded-2">
            <div className="form-check my-2 mx-2">
              <input
                className="form-check-input"
                type="radio"
                name="donation"
                value="everyone"
                disabled={visibility === 'private'}
                checked={donation === 'everyone'}
                onChange={handleDonationChange}
              />
              <label className="form-check-label" style={{ color: '#74A8B0' }}>
                Everyone
              </label>
            </div>

            <div className="form-check my-2 mx-2">
              <input
                className="form-check-input"
                type="radio"
                name="donation"
                value="none"
                checked={donation === 'none'}
                onChange={handleDonationChange}
              />
              <label className="form-check-label" style={{ color: '#74A8B0' }}>
                None
              </label>
            </div>
          </div>
        </div>






        {/* <div className="col">
          <div className="avatar d-flex align-items-end">

            <img
              src={


                !(props?.SelectedImage || props?.availableImage) ? uploadBlog :
                  props?.SelectedImage ? URL.createObjectURL(props?.SelectedImage) :
                    `${props?.availableImage}`
              }
              className=" img-fluid  h-100 w-100 object-cover"
              style={{ position: 'relative', width: '264px', height: '285px' }}
              alt="Avatar"
            />
          </div>
          <div className="d-flex  my-5" style={{ justifyContent: 'space-between' }}>
            <div className='mx-2'>
              <p className='fw-semibold m-0' style={{
                color
                  : "#74A8B0"
              }}>Track Title</p>
              <p className='' >
                {props?.selectedFile?.name?.split(' ').slice(0, 4).join(' ')}
                {props?.selectedFile?.name?.split(' ').length > 4 ? '...' : ''}
              </p>
            </div>
            <div className='mx-2 text-end'>
              <p className='fw-semibold m-0' style={{ color: '#74A8B0' }}>track length</p>
              <p className='text-muted'>{props?.duration ? props?.duration : '00'} </p>
            </div>
          </div>
        </div> */}









        <div className="col">
          <div className="image d-flex justify-content-center align-items-center">

            <div className="d-flex justify-content-center mb-md-5 mb-sm-2 ms-4">
              <div className="profile-image-container">
             

                <div className="avatar d-flex align-items-end" >

                <div className=""
                style={{
   
  
    height: '350px',
    overflow: 'hidden', // Ensure that content beyond the card size is hidden
  }}
>
  <div className=''style={{
    // width: "19.5rem",

    height: '350px',
    overflow: 'hidden', // Ensure that content beyond the card size is hidden
  }}>
    <img
     
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        cursor: 'pointer', 
        position:'relative'
      }}
      src={


        !(props?.SelectedImage || props?.availableImage) ? uploadBlog :
          props?.SelectedImage ? URL.createObjectURL(props?.SelectedImage) :
            `${props?.availableImage}`
      }
      className=" img-fluid  "
      alt="Not data"
    
    />
  </div>

 
               </div>



                


                </div>
              </div>

            </div>


          </div>
          <div className="d-flex  my-1" style={{ justifyContent: 'space-between' }}>
            <div className='mx-2'>
              <p className='fw-semibold m-0' style={{
                color
                  : "#74A8B0"
              }}>Track Title</p>
              <p className='text-muted'>
                {props?.selectedFile?.name?.split(' ').slice(0, 4).join(' ')}
                {props?.selectedFile?.name?.split(' ').length > 4 ? '...' : ''}
              </p>
            </div>
            <div className='mx-2 text-end'>
              <p className='fw-semibold m-0' style={{ color: '#74A8B0' }}>track length</p>
              <p className='text-muted'>{props?.duration ? props?.duration : '00'} </p>
            </div>
          </div>

        </div>













      </div>
      <div className="d-flex justify-content-end  text-end mt-3 border-top">
        <div className="mx-2 pt-2">
          <button className='btn btn-secondary'
            disabled={isLoading}
            onClick={props.backPage}>
            BACK
          </button>
        </div>
        <div className=" pt-2">
          <button className='btn text-white'
            disabled={isLoading}
            style={{ backgroundColor: '#74A8B0' }} onClick={Public}>
            {isLoading ? "Loading..." : visibility === 'public' ? "PUBLIC" : "SAVE"}
          </button>
        </div>
      </div>
    </>
  );
}

export default UploadMusicVisibility;
