import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UpdateUser, getUserDetails } from '../../../redux/features/auth/authSlice'
import {toast} from 'react-toastify'
import Avatar from 'react-avatar';
import './profile.css'

const PublicProfile = () => {

    const USER = JSON.parse(localStorage.getItem('user'))
    const userID =USER?.user[0]?._id

    const user = useSelector(state => state.auth.userDetail )
    console.log("🚀 ~ PublicProfile ~ user:", user)


      const {isLoading}=useSelector((state)=>state.auth)
      const [userAvatar, setuserAvatar] = useState('')
    const [data, setData] = useState({
     userName:'',
     name:"",
      bio :'',
      website:'',
      instagram :'',
      facebook:'',
     
      imageURL:null,
      id:userID
    })
    useEffect(()=>{
      if(user){
       setData ({
          userName:user?.user?.userName ||'',
          name:user?.user?.firstName+' '+user?.user?.lastName,
           bio :user?.user?.bio||'',
           website:user?.user?.website ||'',
           instagram :user?.user?.instagram||'',
           facebook:user?.user?.facebook||'',
          
           imageURL:null,
           id:userID
         })
      }

      if (user?.user?.userName) {
        // If there is a username, use the first letter of the username
        setuserAvatar(user?.user?.userName.charAt(0).toUpperCase()) ;
      } else if (user?.user?.firstName && user?.user?.lastName) {
        // If there is a first name and last name, use the first letters of both
        setuserAvatar( `${user?.user?.firstName.charAt(0).toUpperCase()}${user?.user?.lastName.charAt(0).toUpperCase()}`)
       
      }
  
    
    },[user])

    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };

    const dispatch = useDispatch()

    useEffect(()=>{
      if(userID){
          const data = async()=>{  
        await dispatch(getUserDetails({id:userID}))
        }
        data()
      }
      
      },[userID,dispatch])


      const selectPic = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*'; 
        fileInput.addEventListener('change', (e) => {
          const file = e.target.files[0]; 
          if (file) {
            const imageURL = URL.createObjectURL(file);
            setData((prevState) => ({
              ...prevState,
              image: file,
              imageURL,
            }));
          }
        });
        fileInput.click();
      };

     

      const update = async () => {
        // Create a copy of the data object without the imageURL property
        const { imageURL, ...dataWithoutImageURL } = data;
    
        const formData = new FormData();
        
        for (const key in dataWithoutImageURL) {
          formData.append(key, dataWithoutImageURL[key]);
        }
      
        await dispatch(UpdateUser(formData))
          .then((res) => {
            toast.success(res.payload.message, { autoClose: 1000 });
            dispatch(getUserDetails({id:userID}))
          });
      };
      

  return (
    <div className="container-fluid" >
    <div className="container">
      <div className="row mb-5">
        <div className="col-md-6 col-sm-12">
          <h4 className="fs-3 openSans-800" style={{ fontWeight: '600', color: '#8f9bb9', fontSize: '24px' }}>
            Public Profile
          </h4>
          <h6 className='openSans-300' style={{ color: '#8f9bb9' }}>
            People visiting your profile will see the following info
          </h6>
         
          <p className='openSans-300 mt-5' style={{ color: '#8f9bb9' }}>
            Photo
          </p>
  
      
           
      {
        user?.user ?
        (
        <>
              <div
          className=" profile-image"
         
        >
          {data.imageURL ? (
            <img
              className="rounded-circle h-100 w-100"
              src={data.imageURL}
              alt="Selected"
            />
          ) : user?.user?.image[0] ? (
            <img
              className="rounded-circle h-100 w-100"
              src={user?.user?.image[0]}
              alt="User's Profile"
            />
          ) : (
           
            
          

            <p className="letter-show m-0">
            {userAvatar}
            </p>
         
          )}
        </div>
          <button className='btn text-white rounded-pill mt-4 openSans-600 px-4 d-flex justify-content-center' onClick={selectPic} style={{background:'#8f9bb9'}}>Change</button>

     <div className="row mt-md-5">
        <div className="col">
        <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label openSans-400 mb-md-1"  style={{ color: '#8f9bb9' }}>
                    Name
                  </label>
                  <input
                    type="text"
                    value={ data? data?.userName: ''}
                    className="form-control openSans-500"
                    placeholder='Your Name'
                    style={{ border: '1px solid #CACED8',width:'fit-content' }}
                    name="userName"
                    onChange={handleChange}
                    id="exampleFormControlInput1"

                  />
                </div>
                <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label mb-md-1 openSans-400"  style={{ color: '#8f9bb9' }}>
                    About
                  </label>
                  <textarea
                    type="text"
                    value={data? data?.bio:''}
                    className="form-control openSans-600"
                    placeholder='Tell your story'
                    style={{ border: '1px solid #CACED8' }}
                    name="bio"
                    onChange={handleChange}
                    id="exampleFormControlInput1"
                    rows={6}
                  />
                </div>
        </div>
        <div className="col mx-xl-2 mx-lg-0 mx-0">
        <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label openSans-400 mb-md-1"  style={{ color: '#8f9bb9' }}>
                    Email
                  </label>
                  <input
                    type="text"
                    disabled
                    value={USER?.user[0]?.email}
                    className="form-control openSans-500"
                    placeholder='email'
                    style={{ border: '1px solid #CACED8',width:'fit-content' }}
                    name="userName"
                    id="exampleFormControlInput1"

                  />
                </div>
        <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label openSans-400 mb-md-1"  style={{ color: '#8f9bb9' }}>
                    Website
                  </label>
                  <input
                    type="text"
                    value={data? data?.website:''}
                    className="form-control openSans-600"
                    placeholder='Website'
                    style={{ border: '1px solid #CACED8',width:'fit-content' }}
                    name="website"
                    onChange={handleChange}
                    id="exampleFormControlInput1"
                  />
                </div>
                <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label openSans-400 mb-md-1"  style={{ color: '#8f9bb9' }}>
                    Instagram
                  </label>
                  <input
                    type="text"
                    value={data? data?.instagram :''}
                    className="form-control openSans-600"
                    placeholder='instagram'
                    style={{ border: '1px solid #CACED8',width:'fit-content' }}
                    name="instagram"
                    onChange={handleChange}
                    id="exampleFormControlInput1"
                  />
                </div>
                <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label openSans-400 mb-md-1"  style={{ color: '#8f9bb9' }}>
                    Facebook
                  </label>
                  <input
                    type="text"
                    value={data? data?.facebook:''}
                    className="form-control openSans-600"
                    placeholder='Facebook'
                    style={{ border: '1px solid #CACED8',width:'fit-content' }}
                    name="facebook"
                    onChange={handleChange}
                    id="exampleFormControlInput1"
                  />
                </div>
        </div>
     </div>
        </>
      
        )


        :(
        <div className=" fw-semibold  openSans-600" style={{ color: '#8f9bb9' }}>
          <p>Please Wait...</p>
        </div>
        )
      }


         
          

        

     <button className='btn text-white rounded-pill mt-md-4 openSans-600 px-4' 
    onClick={update}
    disabled={isLoading}
    style={{background:'#8f9bb9'}}>{isLoading? "please wait...":'Save'} </button>


        </div> 
      </div>
    </div>
  </div>
  
  )
}

export default PublicProfile