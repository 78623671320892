import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserFormat,
  addFormat,
} from "../../../redux/features/auth/authSlice";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


const FormateList = ({sendToParentFormatId,initalFormat,callType,selectedFormat}) => {


  const user = JSON.parse(localStorage.getItem("user"));
  const user_id = user?.user[0]._id;

  const { userFormat } = useSelector((state) => state.auth);

  const collections = userFormat?.data || [];
  const [formatTitle, setformatTitle] = useState()
  const [showNewCollectionInput, setShowNewCollectionInput] = useState(false);
  const [initalFormatTitle, setinitalFormatTitle] = useState({
    title:'',
    id:null,
  })

  const [newCollectionTitle, setNewCollectionTitle] = useState({
    title: "",
    user_id: user_id,
  });

  const [selectedFormatId, setSelectedFormatId] = useState(null);

  const dispatch = useDispatch();

  const getData = async () => {
    await dispatch(getUserFormat({ user_id }));
  };
  useEffect(() => {
    getData();
  }, []);

  //this useEffect will work when edit modal open and selection need values
  //this useEffect will work when we upload art and user come back visibility to detail page
  //in this we compare comming id's with list and get required object

  useEffect(()=>{
    if(initalFormat && callType==='edit'){
      collections.forEach(element => {
        
        if(initalFormat===element._id){
          setinitalFormatTitle((prev)=>({
            ...prev,
            title:element.title,
            id:element._id
          }))

          sendToParentFormatId(element._id)
        }
      });
    }
    else if(selectedFormat && callType==='upload'){
      collections.forEach(element => {
        
        if(selectedFormat===element._id){
          setinitalFormatTitle((prev)=>({
            ...prev,
            title:element.title,
            id:element._id
          }))

          sendToParentFormatId(element._id)
        }
      });
    }
  },[initalFormat,callType,selectedFormat])

  const handleNewCollectionChange = (e) => {
    const { name, value } = e.target;
    setNewCollectionTitle((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };



  const handleCheckboxChange = (item) => {
 
    sendToParentFormatId(item._id)
    setformatTitle(item?.title)
  };

  const handleDropdownClick = (e) => {
    e.stopPropagation(); // Prevent dropdown from closing
  };

  const handleNewCollectionSave = (e) => {
    e.stopPropagation(); // Prevent dropdown from closing
    dispatch(
      addFormat({
        title: newCollectionTitle?.title,
        user_id,
      })
    ).then(async () => {
      setNewCollectionTitle({
        title: "",
      });
      await dispatch(getUserFormat({ user_id }));
    });
  };

  return (
    <>
      <div className="dropdown"   style={{ fontWeight: '600', color: 'rgb(116, 168, 176)' }}>
        <button
          className="btn btn-transparent  dropdown-toggle d-flex w-100 text-start"
          type="button"
          style={{border:"1px solid #74a8b0",
          justifyContent:"space-between", 
          alignItems:'center',fontWeight: '600' ,
          color: `${initalFormatTitle.title && !formatTitle ? '#709AA4': formatTitle? '#709AA4':'#BED3D6'}`
        }
        }
          data-bs-toggle="dropdown"
          aria-expanded="false"
         
        >
          {initalFormatTitle.title && !formatTitle ? initalFormatTitle?.title: formatTitle? formatTitle:'Select Format...'}
        </button>
        <ul className="dropdown-menu" style={{ minWidth: "180px" }} >
          {collections?.map((item, i) => {
            return (
              <li className="px-2" key={i} >
              <a href="#format" className="dropdown-item"   style={{ fontWeight: '600', color: 'rgb(116, 168, 176)' }} onClick={() => handleCheckboxChange(item)}>

              
                  {item.title}
               
              
              </a>
             
            </li>
            );
          })}
          <li className="p-1" >
            <div className="row border-top mt-1 pt-2">
              <div className="col-9 ps-3  text-center d-flex align-items-center justify-content-start">
              
                  <input
                    type="text"
                    name="title"
                    className="form-control  project-input"
                    value={newCollectionTitle.title}
                    onChange={handleNewCollectionChange}
                    placeholder="Add New..."
                    onClick={handleDropdownClick}
                  />
               
              </div>
              <div className="col-3   d-flex align-items-center justify-content-center  text-center">
               
                <AddCircleOutlineIcon style={{ color: "#74a8b0" }} onClick={handleNewCollectionSave}/>
              </div>
            </div>
          </li>
          <div className="pt-2 text-center"></div>
        </ul>
      </div>
    </>
  );
};

export default FormateList;
