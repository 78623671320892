import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useDispatch,useSelector } from "react-redux";
import { getAllFvrtCollection,addNewCollection, AddArtToFvrtCollections, deleteCollectionApi } from "../../redux/features/auth/authSlice";
import {toast} from 'react-toastify'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


const MyFavouriteDropDown = ({ selectedCheckboxes }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_id = user?.user[0]._id;

   const { userList } = useSelector((state) => state.auth);
   

   const [selectedStyleId, setSelectedStyleId] = useState(null);

   const collections = userList?.FvrtCollections?.data || [];

  const [searchTerm, setSearchTerm] = useState("");
  // const [collections, setCollections] = useState([]);

  const [showNewCollectionInput, setShowNewCollectionInput] = useState(false);

  const [newCollectionTitle,setNewCollectionTitle] = useState({
    title:'',
    user_id:user_id,
    sound_id:selectedCheckboxes? selectedCheckboxes[0]:'',
  })

  const [addFvrtCollections,setAddFvrtCollections] = useState({
    folder_id:'',
    user_id:user_id,
    art_id:selectedCheckboxes? selectedCheckboxes[0]:'',
  })

 

  const dispatch = useDispatch()
     

  const getData = async () =>{
    await dispatch(getAllFvrtCollection({user_id:user_id}))
   }
       useEffect(()=>{
        if(user_id){
          getData()
        }
         
       },[])

const deletecollection=async(id)=>{
  console.log("working");
  dispatch(deleteCollectionApi({id:id}))
  .then((res) => {
    console.log("🚀 ~ file: MyFavouriteDropDown.jsx:54 ~ .then ~ res:", res)
    if(res.payload.success===true){
        toast.success("collection delete successfully!")
    	dispatch(getAllFvrtCollection({user_id:user_id}))
    }

  	
  })
}

      //  useEffect(()=>{
      //   setCollections(userList?.FvrtCollections?.data)

      //    if(!collections){
      //     return <p><b>Collection</b> is loading...</p>
      //    }
      //  },[])


       

       
      const filteredCollections = collections?.length > 0
      ? collections.filter((item) =>
          item?.title.toLowerCase().includes(searchTerm?.toLowerCase())
        )
      : [];

  


  const handleNewCollectionChange = (e) => {
    const { name, value } = e.target;
    setNewCollectionTitle((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleNewCollectionClick = (e) => {
    e.stopPropagation(); // Prevent dropdown from closing
    setShowNewCollectionInput(true);
  };

 
  const handleCheckboxChange = (id) => {
    setAddFvrtCollections((prev)=>({
      ...prev,
folder_id:id
    }));
    setSelectedStyleId(id)
  };
  


  const handleDropdownClick = (e) => {
    console.log('first')
    e.stopPropagation(); // Prevent dropdown from closing
  };


  const handleNewCollectionSave = (e) => {
  e.stopPropagation(); // Prevent dropdown from closing
  if(addFvrtCollections.folder_id===''){
    toast.error('please Add collection Name',{autoClose:2000})
  }
  dispatch(addNewCollection({title:newCollectionTitle.title,user_id:user_id}))
    .then(() => {
      setNewCollectionTitle({
        title: '',
      });
      dispatch(getAllFvrtCollection({user_id:user_id}));
    });
};




const saveMusic = () =>{
  if(addFvrtCollections.folder_id===''){
    toast.error('please select collection',{autoClose:2000})
  } 
  else{
     dispatch(AddArtToFvrtCollections({...addFvrtCollections,art_id:addFvrtCollections.art_id}))
  .then((res)=>{

  if(res?.payload?.success === false){
    toast.error(res?.payload?.message)
     dispatch(getAllFvrtCollection({user_id:user_id}))
     
    return
  }
  toast.success(res?.payload?.message)
  dispatch(getAllFvrtCollection({user_id:user_id}))
   
  })
  }
 
}

  const [showSign,setShowSign] = useState(false)

    const sign = ( ) => {
      setShowSign(!showSign)
    
    }

  return (
    <>
      <div className="dropdown" onClick={handleDropdownClick}>
        <button
          className="btn btn-transparent text-white dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Add To My Favorite
        </button>
        <ul className="dropdown-menu" style={{ minWidth: "350px" }}>
        <li className="p-2 w-100 ps-0">
           
           <div className="d-flex justify-content-between align-items-center mx-3">
             <div className=' w-75 border rounded-3'>
               <input
                 type="text"
                 value={searchTerm}
                 onChange={(e) => setSearchTerm(e.target.value)}
                 className="compaignSearch form-control w-100 border-0 rounded-2 bg-transparent text-dark"
                 placeholder="Search Your Collection"
                 style={{outline:'none'}}
                 onClick={handleDropdownClick}
               />
             </div>
           
          
             <AddCircleOutlineIcon className='fs-3' style={{color:"lightgrey",cursor:"pointer"}}   onClick={sign} />
            
          
              
             </div> 
          
         </li>
          {filteredCollections?.map((item, i) => {
            return (
              <li className="px-2" key={i}>
                <div className="d-flex">
                  <div className="w-100">
                     <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={item._id === selectedStyleId}
                    onChange={() => handleCheckboxChange(item._id)}
                    id={`checkbox${i}`}
                  />
                  <label class="form-check-label" for={`checkbox${i}`}>
                    {item.title}
                  </label>
                </div>
                  </div>
                  <div className="w-100 text-end">
                    <DeleteForeverIcon style={{color:"lightgrey",cursor:"pointer"}}  onClick={()=>deletecollection(item._id)}/>
                  </div>
                </div>
               
              </li>
            );
          })}
          <li className="p-1" onClick={handleNewCollectionClick}>
            <div className="border-top">
            
            </div>

            {
              showSign && (
                <div className="d-flex justify-content-between align-items-center mx-3 mt-md-3">
             <div className=' w-75 border rounded-3'>
             <input
                    type="text"
                    name="title"
                    className="form-control dropdown-toggle"
                    value={newCollectionTitle.title}
                    
                    onChange={handleNewCollectionChange}
                    placeholder="Add New Collection"
                    onClick={handleDropdownClick}
                  />
             </div>
           
          <div className="div">
               <AddCircleOutlineIcon className='fs-3' style={{color:"lightgrey",cursor:"pointer"}}   onClick={handleNewCollectionSave} />
    
          </div>
            
          
              
             </div>  
              )
            }
           
        
          </li>
          <div className=" pt-2 text-center pe-md-5">
<button className="btn  btn-sm  text-center  rounded-pill px-3" style={{backgroundColor:'#74a8b0',color:'white'}} onClick={saveMusic}>Add To Favourite</button>

</div>
        </ul>
      </div>
    </>
  );
};

export default MyFavouriteDropDown;
