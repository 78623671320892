
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
// import "../Styles/forgetpasswordmodal.css";
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { sendEmail } from "../../redux/features/auth/authSlice";
import { toast } from "react-toastify";
import doorImage from "../../assets/images/doorImage.png";

const NewResetPassword = ({ screenMinu, closeModal }) => {
  const [email, setemail] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setemail(e.target.value);
    setError('');
  }


  const dispatch = useDispatch()

  const sendmail = async () => {
    if (!email) {
      setError('Please enter your email');
      return;
    }
    if (email) {
      await dispatch(sendEmail({ email: email }))
        .then((res) => {
          toast.success(res?.payload?.message, { autoClose: 2000 })
          closeModal()
        })
    }


  }


  return (
    <>


      <div className="h-100 login-row justify-content-center">


        
          <div className="login-text p-0" style={{ minWidth: '50%' }}>


            <div
              className="d-flex align-items-start mt-2 ms-2"
              style={{
                height: "33px",
                width: "33px",

                padding: "4px",
                paddingBottom: "2px",
                borderRadius: "5px",
                textAlign: "center",
                marginLeft: "3px",
              }}
            >
              <FontAwesomeIcon
                icon={faXmark}
                className="fs-4 ps-1 "
                onClick={() => {
                  closeModal();
                }}
                style={{ color: "#709AA4", cursor: "pointer" }}
              />
            </div>

            <div className="row align-items-center " style={{ height: '90%' }}>
              <div className=" py-2 rounded-start" style={{ paddingLeft: '3.5rem', paddingRight: '3.5rem' }}>

                <h3 className="text-center fw-bold" style={{ color: '#709DA7' }}>Reset Your Password</h3>
                <p className="text-center" style={{ color: '#709DA7', opacity: 0.7 }}>What's your registered email?</p>

                <div>
                  <div className="py-2">
                    <div className="form-group py-2">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        name="email"
                        onChange={handleChange}
                      />
                    </div>

                    {error && <p className="text-danger text-start">{error}</p>}
                  </div>
                </div>

                <div className="py-2 d-grid text-center">
                  <button
                    type="button"
                    className="btn py-1"
                    onClick={sendmail}
                    style={{ backgroundColor: '#709DA7', color: 'white', fontWeight: '600' }}
                  >
                    Send a password reset email
                  </button>
                  <div className="my-2 text-center">
                    <span className=" " style={{ color: "#709DA7" }}>
                      {" "}
                      back to
                    </span>
                    <a
                      href="#"
                      onClick={() => {
                        //   handleClose();
                        screenMinu()
                      }}
                      className="text-decoration-none text-cadet fw-bold text-dark font-18 px-2"
                    >
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div className="login-image p-0 loginImage" style={{ minWidth: '50%' }}>
            {/* <div style={{ textAlign: "center" }}>
                <img src={doorImage} alt="" className="login-img" />
              </div> */}


            <div className=' ' style={{
              // width: "19.5rem",
              width: '100%',
              height: "100%",

              overflow: 'hidden', // Ensure that content beyond the card size is hidden
            }}>
              <img
                className='img-fluid'
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  cursor: 'pointer',
                }}
                src={doorImage}
                alt="Not data"

              />
            </div>
          </div>
       


      </div>


    </>
  );
}
export default NewResetPassword;