import React, { useState,useEffect } from "react";
import "./userflow.css";
import { toast } from "react-toastify";
import { useNavigate,Link } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { getUserDetails } from "../../redux/features/auth/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGauge, faRightFromBracket, faStar, faSubscript } from "@fortawesome/free-solid-svg-icons";
import Avatar from 'react-avatar';

const UserFlow = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [UserDetailData, setUserDetailData] = useState()
  const [userAvatar, setuserAvatar] = useState('')
  const updatedUserDetail = useSelector((state)=>state.auth.userDetail);


  const userDetialaApi = useSelector(state => state.auth.userDetail )


  const user = JSON.parse(localStorage.getItem("user"))
  
  const ID = JSON.parse(localStorage.getItem('user'))
  const user_id = ID?.user[0]?._id



  const navigate = useNavigate();
  const dispatch = useDispatch();


useEffect(()=>{
  if(userDetialaApi){
    if (userDetialaApi?.user?.userName) {
      // If there is a username, use the first letter of the username
      setuserAvatar(userDetialaApi?.user?.userName.charAt(0).toUpperCase()) ;
    } else if (userDetialaApi?.user?.firstName ) {
      // If there is a first name and last name, use the first letters of both
      setuserAvatar( userDetialaApi?.user?.firstName.charAt(0).toUpperCase())
     
    }
  }
},[userDetialaApi])


  useEffect(()=>{
    const  getData =async ()=>{
    await dispatch(getUserDetails({
       id:user.user[0]?._id
     }))
    } 
    getData()
   },[] )

   useEffect(()=>{
    if(updatedUserDetail){
      setUserDetailData(updatedUserDetail?.user)
    }
  },[updatedUserDetail])
  

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const subPlan=()=>{
    navigate(`/payment-plans/${user_id}`)
  }


  const logout = () => {
    localStorage.removeItem("user");
    toast.success("Successfully logged out!", {
      position: "top-center",
      autoClose:1000
    });
    props.isLoggedIn()
    navigate("/");
  };

  return (
    <>
      <div className="px-2">
        <div className="d-flex align-items-center">
       
        {/* <div className="text-center text-white rounded-circle fs-4 bg-info justify-content-center align-items-center text-align-center" 
     style={{ height: '35px', width: '35px', border:'3px solid', textTransform: 'capitalize', fontWeight: '800', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px' }}>
 
  <Avatar 
 
            color={Avatar.getRandomColor('sitebase', ['red', 'blue', '#539b9d'])} 
            // name={updatedUserDetail?.user?.firstName ? updatedUserDetail?.user?.firstName : user?.user?.firstName}  
           name = { !userDetialaApi?.user?.userName ? user?.user[0]?.firstName : userDetialaApi?.user?.userName}

            size={50}  
            round={true}/>
</div> */}
<div className="top-avatar-image">
  <p className="top-avatar-letter m-0">{userAvatar? userAvatar: user?.user[0]?.firstName?.charAt(0).toUpperCase()}</p>
</div>


          <div className="pe-0">
            <div className={`dropdown${isDropdownOpen ? " no-border" : ""}`}>
              <button
                className="btn dropdown-toggle rounded-0 bg-transparent text-white pb-2"
                style={{ fontWeight: "600", letterSpacing: "0.9px" ,textTransform:'capitalize' }}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={toggleDropdown}
              >
                {/* {UserDetailData ? `${UserDetailData?.firstName} ` : user?.user?.firstName ? user?.user?.firstName : 'No Name Added'} */}
                {!userDetialaApi?.user?.userName ? user?.user[0]?.firstName : userDetialaApi?.user?.userName}
              </button>
              <ul className="dropdown-menu px-2" style={{ minWidth: "200px" }}>
                <div>
                  <small
                    className="text-start text-muted ps-3"
                    style={{ fontSize: "11px", fontWeight: "600" }}
                  >
                    YOUR ACCOUNT
                  </small>
                </div>

                <div className="d-flex mt-2">
                  <div className="px-2">
                  <div className="top-avatar-image">
  <p className="top-avatar-letter m-0">{userAvatar? userAvatar: user?.user[0]?.firstName?.charAt(0).toUpperCase()}</p>
</div>
                 
               
                  </div>
                 
                  <div className=" align-items-center">
                    <p className="fw-bold fs-5 h5 m-0">{ !userDetialaApi?.user?.userName ? user?.user[0]?.firstName+' '+user?.user[0]?.lastName  : userDetialaApi?.user?.userName}</p> 
                    <p className="text-muted" style={{ fontSize: "14px" }}>
                    {user?.user[0]?.email}
                    </p>
                  </div>
                </div>

                <div>
                  <small
                    className="text-start text-muted ps-3"
                    style={{ fontSize: "11px", fontWeight: "600" }}
                  >
                    OPTIONS
                  </small>
                </div>

                <div>
                 
                  <li>
                    <Link
                      className="dropdown-item text-muted"
                      to={user?.Role?.title==='user' ?"/dashboard/user-dashboard":"/dashboard/admin-dashboard"}
                      style={{ fontWeight: "700" }}
                    >
                      {/* <FontAwesomeIcon icon={faGauge} /> */}
                      <span className="ps-2" style={{color:'#709ba5'}}>My Dashboard</span>
                    </Link>
                  </li> 

                   <li>
                    <Link
                      className="dropdown-item text-muted"
                      to="/my-favorite-art-gallery"
                      style={{ fontWeight: "700" }}
                    >
                      {/* <FontAwesomeIcon icon={faStar} /> */}
                      <span className="ps-2" style={{color:'#709ba5'}}>My Favourite Art Actually</span>
                    </Link>
                  </li> 

                  <li>
                    <p
                      className="dropdown-item text-muted pb-0 mb-0"
                      
                      onClick={()=>subPlan()}
                      style={{ fontWeight: "700",cursor:'pointer' }}
                    >
                  {/* <FontAwesomeIcon icon={faSubscript} /> */}
                      <span className="ps-2" style={{color:'#709ba5'}} >Subscription Plan</span>
                    </p>
                  </li> 

                  <li>
                    <a
                      className="dropdown-item text-muted d-flex align-items-center"
                      href="#"
                      style={{ fontWeight: "700" }}
                    >
                      {/* <FontAwesomeIcon icon={faRightFromBracket} /> */}
                      <div className="ps-2"onClick={logout} style={{color:'#709ba5'}} type='button'>LogOut</div>
                    </a>
                  </li>  
                 
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserFlow;
