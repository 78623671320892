import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SubscribedList } from '../../../redux/features/auth/authSlice';
import TablePagination from '../../../Components/Pagination/TablePagination';
import SubscriberDetail from './SubscriberDetail';





const SubscribedMember = () => {


   const UserSubscribed = useSelector((state)=>state.auth.SubscribeList)
   console.log("🚀 ~ file: SubscribedMembers.jsx:15 ~ SubscribedMember ~ UserSubscribed:", UserSubscribed)
  const dispatch = useDispatch()
  const [paginatedData, setpaginatedData] = useState()
  const [editModal, seteditModal] = useState()
  const [docId, setdocId] = useState({
    id:'',
    email:''
  })



  useEffect(()=>{
    const getData =async () =>{
     await dispatch(SubscribedList())
    
    }
    getData()
  },[dispatch])

  const paginatedlist = (list)=>{
    setpaginatedData(list)
  
  }

  function editModalScreen(item) {
    setdocId(prev=>({
      ...prev,
      id:item?.user_id,
      email:item?.user_email
    }))
    seteditModal(!editModal)
    }

  return (
    <>
  <p style={{fontSize:"16px", fontWeight:"500", color:"rgb(120, 116, 134)"}}>The Subscribed Members of art actually</p>
  <table class="table  " style={{backgroundColor:"white", border:"1px solid #B0A7A7"}}>
        <thead className=''>
          <tr className="border-bottom" style={{color:'rgb(120, 116, 134)'}}>
            <th scope="col border-end" style={{fontSize:"14px", fontWeight:"600", }}> Name</th>
            <th scope="col border-end" style={{fontSize:"14px", fontWeight:"600", }}>Email</th>
            <th scope="col" style={{fontSize:"14px", fontWeight:"600", }}>status</th>
            
            <th scope="col"style={{fontSize:"14px", fontWeight:"600", }}>Start Date</th>
         
            <th scope="col" style={{fontSize:"14px", fontWeight:"600", }}>Detail</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData?.map((item,i) => {
          
          const timestamp = Number(item.start_date); // Your timestamp here
          const milliseconds = timestamp * 1000;

          // Create a new Date object using the milliseconds
          const date = new Date(milliseconds);
        
          // Get the components of the date
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
          const day = String(date.getDate()).padStart(2, '0');
 
        
          // Create a readable date string
          // const readableDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
const formattedDate = `${day}-${month}-${year}`;


            return (
              <tr className='text-muted' key={i}>
                <td  className='border-end'>{item.user_name}</td>
                <td className='border-end'>{item.user_email}</td>
                <td className={`border-end ${item.status==='active'? 'text-success':'text-danger'}`}>{item.status}</td>
                <td className='border-end'>$ {Math.floor(item.amount / 100)}</td>
                <td className='border-end'>{formattedDate}</td>
                <td className='border-end'><a href="#" 
                style={{textDecoration:'none',color:`${item.status==='active'? '#539B9D':"#bdb7b7"}`}} 
                onClick={item.status==='active'?()=>editModalScreen(item):()=>{}}
                 >More Details</a></td>
               
              
                <td>
                
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <TablePagination list={UserSubscribed?.data} paginatedList={paginatedlist} />
      <SubscriberDetail
         val={docId}
         closeModal={editModalScreen}
         ModalIsOpen={editModal}
      />


  </>
  )
}

export default SubscribedMember