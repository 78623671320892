import React, { useState } from 'react';
import Modal from 'react-modal';
import './donatemodal.css'
import {useNavigate} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
    zIndex: 9999, // Ensure the overlay is above other elements
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    minWidth: '37%',
    background: '#FFFFFF',
    border: '2px solid white',
    borderRadius: '10px',
    padding: '8px',
    transform: 'translate(-50%, -50%)',
    zIndex: 10000, // Ensure the modal is above the overlay
  },
};


const DonateModal = ({ ModalIsOpen, closeModal,musicData }) => {


  const [inputData,setInputData] = useState('')

    const [count,setCount] = useState(1)

    const [isCardSelected, setIsCardSelected] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const [name,setName] = useState('')

    const handleCardClick = () => {
      setIsCardSelected(!isCardSelected);
      setIsSubmitDisabled(false);
    };

    const counter = () =>{
        setCount(count + 1)
    }
    
  const reverse = () =>{
        setCount(1)
        closeModal()
    }

    const isNextDisabled = count === 1 && (inputData.trim() === '' || parseInt(inputData) === 0);


const navigate = useNavigate()

const submit = () =>{
  navigate('/checkout',{state:{amount:inputData,art_id:musicData?._id,name:name,artist_name:musicData}})
}

  return (
    <div className="div">


<div className={`backdrop ${ModalIsOpen ? 'active' : ''}`}  />
                   <Modal
        isOpen={ModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {count===1?
        <div>
            <div className="d-flex justify-content-between align-items-center">
          <div className="w-100 text-center">
            <p className="h3 fs-4 mt-md-3" style={{ fontWeight: '700', color: 'rgb(112, 155, 165)' ,opacity:'0.8'}}>
              Donation
            </p>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }} className='mb-4 me-2'>
          <FontAwesomeIcon className='text-muted fs-3' style={{cursor:'pointer'}} icon={faXmark} onClick={closeModal} />
          </div>
        </div>

        <div className="border-bottom my-3"  style={{opacity:'0.5'}}  ></div>

        <div>
            <div className="col-md-12">
              <p className="text-center  mb-0" style={{ fontWeight: '500', color: 'rgb(112, 155, 165)',opacity:'0.7',letterSpacing:'0.8px' }}>
            I would like to make a donation for:
          </p>   
            </div>
         
          <div className="col-md-12 text-center mt-2">
            <p className="h3 fs-4  mt-md-1 pt-0" style={{ fontWeight: '700', color: 'rgb(112, 155, 165)' ,opacity:'0.8',textTransform:'capitalize'}}>
              "{musicData?.title} - {musicData?.owner_name}"
            </p>
          </div>
          <div className="col-md-12 text-center mt-3">
  <input
    type="number"
    className='rounded-3 border fw-bolder custom-input'
    style={{
      padding: '13px',
      outline: 'none',
      color:'rgb(112, 155, 165)'
    }}
    placeholder='US$ Custom Amount'
    value={inputData}
    onChange={(e)=>setInputData(e.target.value)}
  />
     </div>

     <div className="col-md-12">
              <p className="text-center  mb-0" style={{ fontWeight: '500', color: 'rgb(112, 155, 165)',opacity:'0.7',letterSpacing:'0.8px' }}>
            1 US$ = 100 Art-Act point
          </p>   
            </div>
            <div className="border-bottom my-4" style={{opacity:'0.5'}} ></div>

<div className="d-flex col-md-12 justify-content-center">

    <div className="text-center mb-4">
            <button className="btn text-white fw-semibold px-4 py-1" style={{ background: 'rgb(112, 155, 165)' }} disabled>
              Cancel
            </button>
          </div>
          <div className="text-center mx-3">
            <button className="btn text-white fw-semibold px-4 py-1" 
            onClick={counter}
            style={{ background: 'rgb(112, 155, 165)' }} disabled={isNextDisabled}>
              NEXT
            </button>
          </div>
</div>
          
        </div>  
        </div>
        :
        <div>

<div className="d-flex justify-content-between align-items-center">
          <div className="w-100 text-center">
            <p className="h3 fs-4 mt-md-3" style={{ fontWeight: '700', color: 'rgb(112, 155, 165)' ,opacity:'0.8',textTransform:'capitalize'}}>
              Donation to " {musicData?.title} - {musicData?.owner_name}"
            </p>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }} className='mb-4 me-2'>
          <FontAwesomeIcon className='text-muted fs-3' style={{cursor:'pointer'}} icon={faXmark} onClick={reverse} />
          </div>
        </div>

        <div className="border-bottom my-3"  style={{opacity:'0.5'}}  ></div>
        <div className="row container">
            <div className="col-md-6 col-sm-12">
                <div className="div">
     <p className='mb-0'>
        <label htmlFor="" style={{color: 'rgb(112, 155, 165)'}}>FULL NAME</label>
        </p> 
        <input
    type="text"
    className='rounded-3 border fw-bolder custom-input'
    style={{
      padding: '11px',
      outline: 'none',
      color:'rgb(112, 155, 165)'
    }}
   value={name}
   onChange={(e)=>setName(e.target.value)}
  />
  <p className='mb-0'>
        <label htmlFor="" style={{color: 'rgb(112, 155, 165)',opacity:'0.7',fontSize:'12px',fontWeight:'600'}}>[WILL BE PRINTED ON DONATION RECEIPT]</label>
        </p> 
</div>

<div className="div mt-3">
     <p className='mb-0'>
        <label htmlFor="" style={{color: 'rgb(112, 155, 165)'}}>E-MAIL ADDRESS</label>
        </p> 
        <input
    type="text"
    className='rounded-3 border fw-bolder custom-input'
    style={{
      padding: '11px',
      outline: 'none',
      color:'rgb(112, 155, 165)'
    }}
   
  />

</div>
            </div>
            <div className="col-md-6 col-sm-12">
            <div className="div">
     <p className='mb-0'>
        <label className='ps-1' htmlFor="" style={{color: 'rgb(112, 155, 165)'}}>DONATION AMOUNT</label>
        </p> 
        <button className="btn text-white fw-semibold px-4 py-1 col-md-8" style={{ background: 'rgb(112, 155, 165)' }}>
        US$ {inputData ? inputData : '300'}    
            </button>
  
</div>
<div className="div mt-2">
     <p className='mb-0'>
        <label className='ps-1' htmlFor="" style={{color: 'rgb(112, 155, 165)'}}>DONATION METHOD</label>
        </p> 
        <button   className={`btn border fw-semibold py-1 col-md-7 ${ isCardSelected ? 'card-btn' : ''  }`}
            style={{ background: isCardSelected ? 'rgb(112, 155, 165)' : 'white', color: isCardSelected ? 'white' : '#B9C6C5', }} 
            onClick={handleCardClick}> Credit Card </button>
  
</div>

{/* <div className="div mt-2">
    
        <button className="btn border fw-semibold px-1 py-1 col-md-4" style={{ background: 'white',color:'#B9C6C5' }}>
              Paypal
            </button>
  
</div> */}
            </div>
        </div>

      

        <div>
          
      
            <div className="border-bottom my-4" style={{opacity:'0.5'}} ></div>

<div className="d-flex col-md-12 justify-content-center">

    <div className="text-center mb-4">
            <button className="btn text-white fw-semibold px-4 py-1" style={{ background: 'rgb(112, 155, 165)' }} disabled>
              Cancel
            </button>
          </div>
          <div className="text-center mx-3">
            <button className="btn text-white fw-semibold px-4 py-1" 
            onClick={submit}
            style={{ background: 'rgb(112, 155, 165)' }}  disabled={isSubmitDisabled} >
              SUBMIT
            </button>
          </div>
</div>
          
        </div>
        </div>
      
    }
        
      </Modal> 
           
 
 

  

     
    </div>
  );
};

export default DonateModal;
