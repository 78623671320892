import React, { useEffect, useState } from 'react';
// import check from '../../assets/images/shield.png';
import checked from '../../assets/images/checked.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CreateSessionForSubscription, CreateUserForSubscription, getAllPlans } from '../../redux/features/auth/PaymentSlice';
import { UpdateUser, getUserDetailForProfile, getUserDetails } from '../../redux/features/auth/authSlice';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';



const Plans = () => {

  const param = useParams();
  var {id} = param;
   console.log("🚀 ~ file: Plans.jsx:15 ~ Plans ~ id:", id)
   
  const [userInfo,setUserInfo] = useState(null)

  const  [data,setData] = useState()

  

  const dispatch = useDispatch()


  useEffect(()=>{
    const getVal = async () =>{
         const res =  await dispatch(getUserDetailForProfile(id))
         const val = res.payload 
      
         setUserInfo(val);    
    }
    getVal()
  },[id,dispatch])

 
   useEffect(()=>{
    const getData = async () => {
     const data =  await dispatch(getAllPlans())
     const res = data.payload?.plans[0]
     setData(res)
    
   }
   getData()
  },[dispatch])

   const navigate = useNavigate()


 



    const move = () => {
      navigate('/subscription-page',{state:{userInfo:userInfo,planId:data?.id}})
    }

    const regularMove = () =>{
      navigate('/dashboard/user-dashboard')
    }

  return (
    <> 
    <div className='my-4 container'>
      <p className='fw-bold fs-2' style={{color:"#709AA4"}}>Payment Plans</p>
    </div>
    
     <div className="container my-5 bg-white card" style={{ fontFamily: "inter", borderRadius: '10px', boxShadow: '5px 5px 5px 0px gray' }}>
      <div className='card border-0 text-center ps-5 pe-5'>
        <div className="row">
        <KeyboardBackspaceIcon className=' ms-3' style={{fontSize:'4rem',cursor:'pointer', color:'#709AA4'}} onClick={regularMove} />
        
        </div>
        <div className="row d-flex align-items-center justify-content-end card-body flex-column flex-sm-row py-5">
          {/* <div className="col-sm-auto col-md-4 text-start mb-3">
           
          </div>  */}
         
          <div className="col-sm-6 col-md-6 col-lg-6 rounded text-center mb-3 py-5 ms-md-5" style={{ background: '#709AA4', color: 'white' ,width:'300px'}}>
            <p className=''>Regular Member</p>
            <h2 className='' style={{ fontWeight: '600' }}>$ 0</h2>
            <p className=''>Per Month</p>
            <button className='btn bg-white rounded-pill px-4 py-2 px-5 mt-5'  style={{ color: 'black' }} 
            onClick={regularMove}><small>Get Started</small></button>
          </div> 
          <div className="col-sm-6 col-md-6 col-lg-6 rounded text-center mb-3 py-5 ms-md-5" style={{ background: '#709AA4', color: 'white' ,width:'300px'}}>
            <p className=''>VIP Member</p>
            <h2 className='' style={{ fontWeight: '600' }}>$ {data?.unit_amount/100}</h2>
            <p className=''>Per Month</p>
            <button className='btn bg-white rounded-pill px-4 py-2 px-5 mt-5' onClick={move} style={{ color: 'black' }}><small>Choose Plan</small></button>
          </div>
        </div>
        <div className="row d-flex align-items-center p-2 " style={{ background: '#F9F2FF', borderRadius: '20px', marginLeft: '10px', marginRight: '10px' }}>
          <div className="col-6 pt-3 text-start"><p className='fs-5' style={{ fontWeight: 'bold' }}>Unlimited Art Works Uploadable </p></div>
          <div className="col">
            {/* <img src={checked} alt="" /> */}
          </div>
          <div className="col">
            <img src={checked} alt="" />
          </div>
        </div>
        <div className="row d-flex align-items-center p-2 " style={{ background: 'transparent', borderRadius: '20px', marginLeft: '10px', marginRight: '10px' }}>
          <div className="col-6 pt-3 text-start"><p className='fs-5' style={{ fontWeight: 'bold' }}>Donations</p></div>
          <div className="col  ">
            <img src={checked} alt="" className='pe-5'/>
          </div>
          <div className="col">
            <img src={checked} alt="" />
          </div>
        </div>
        {/* <div className="row d-flex align-items-center p-2 " style={{ background: '#F9F2FF', borderRadius: '20px', marginLeft: '10px', marginRight: '10px' }}>
          <div className="col pt-3 text-start"><p className='fs-5' style={{ fontWeight: 'bold' }}>Feature Title</p></div>
          <div className="col">
            <img src={checked} alt="" />
          </div>
          <div className="col">
            <img src={checked} alt="" />
          </div>
        </div> */}
       
       
      </div>
    </div>

    </>
 

  )
}

export default Plans;
