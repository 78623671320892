import React, { useState } from 'react';
import cardImg1 from "../../assets/images/cardImage1.png";
import cardImg3 from "../../assets/images/cardImage3.png";
import cardImg4 from "../../assets/images/cardImage4.png";
import cardImg7 from "../../assets/images/cardImage7.png";
import cardImg8 from "../../assets/images/cardImage8.png";
export const ArtStyleCard = ({sendstyleToParent}) => {
  const [disabledCards, setDisabledCards] = useState([]);
  const [styleInteset, setstyleInteset] = useState([])
  console.log("🚀 ~ ArtStyleCard ~ styleInteset:", styleInteset)

  
const cardsdata= [
  {
img:cardImg1,
title:'Modernism'
},
{
  img:cardImg3,
  title:'Dadaism'
  },
  {
    img:cardImg4,
    title:'surrealism'
    },
    {
      img:cardImg7,
      title:'Abstract Expression'
      },
      {
        img:cardImg8,
        title:'cubism'
        },
        {
          img:cardImg1,
          title:'Baroque'
          },
          {
            img:cardImg4,
            title:'Futurism'
            },
            {
              img:cardImg3,
              title:'Art Deco'
              },
              {
                img:cardImg7,
                title:'Graffiti'
                },
                {
                  img:cardImg8,
                  title:'Classicism'
                  }
]
  const handleCardClick = (index,item) => {
    if (!disabledCards.includes(index)) {
      setDisabledCards([...disabledCards, index]);
      setstyleInteset([...styleInteset,item?.title])
      sendstyleToParent([...styleInteset,item?.title])
      
    }
  };

  const cards = cardsdata.map((item, index) => (
    <div
      key={index}
      className="col-md col-sm-4  col-12 mt-2 p-0 d-flex justify-content-center"
      style={{ paddingLeft: '10px', paddingRight: '10px' }}
    >
      <div
        className="card  text-white"
        style={{
          width: '150px',
          backgroundColor: disabledCards.includes(index) ? 'transparent' : '#709BA5',
          transition: 'background-color 0.3s',
          pointer:"cursor",
        
          zIndex:"1"
        }}
        onClick={() => handleCardClick(index,item)}
      >
        <img src={item.img} className="card-img w-100" alt="..." />
        <div className="card-img-overlay" style={{ backgroundColor: disabledCards.includes(index) ? 'rgba(0,0,0,0.5)' : '#709BA5',}}>
          {/* <h5 className="card-title">Card title</h5> */}
          <p className="card-text" style={{color:"white", fontSize:"14px", textAlign:"left", fontWeight:"400" , position:"absolute", bottom:"2px", width:"90px"}}>{item.title}</p>
        </div>
      </div>
    </div>
  ));

  return (
    <>
    
      <div className="row">{cards}</div>
     
    </>
  );
};