import React, { useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import './NavBar.css';

import logo from "../../assets/images/logo.png";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import UserFlow from './UserFlow';
import SearchModule from './SearchModule';
import ModalHandler from '../../Screens/newloginflow/ModalHandler';
import UPloadArtAndMusic from '../Modal/UPloadArtAndMusic';


import { useDispatch } from "react-redux";
import { CheckUserSubStatus } from "../../redux/features/auth/PaymentSlice";
import { toast } from "react-toastify";
import abc from '../../assets/svg/upload3.svg'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
const Header = () => {
  const location =useLocation()
  //localstatus value is come from form component, which tell us local storage is now empty
  const localStoragestatus = location?.state?.localStatus

  const [showSidebar, setShowSidebar] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [uploadArtMusicModalOpen, setUploadArtMusicModalOpen] = useState(false);
  const [mobilemenuClose, setmobilemenuClose] = useState(false)
  const [newLoginModal, setnewLoginModal] = useState(false)
  const [showSearch, setshowSearch] = useState(false)


  const [screenshift, setscreenshift] = useState(1)
  const User = JSON.parse(localStorage.getItem('user'))
  const subscriptio_id = User?.user[0]?.sub_id
  const subscription = User?.user[0]?.subscribe
  const vip = User?.user[0]?.vip
  const payment = User?.user[0]?.payment
  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });


  };
  const navigate = useNavigate()


  const dispatch = useDispatch()

  useEffect(() => {
    const userLoggedIn = JSON.parse(localStorage.getItem("user"))

    setIsLoggedIn(!!userLoggedIn);
  }, [setIsLoggedIn]);

  const loggedin = () => {
    setIsLoggedIn(!isLoggedIn);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
//this useEffect will run when we got the signal localstorage is clear now
  useEffect(()=>{
if(localStoragestatus==='false'){
  loggedin()
}
  },[localStoragestatus])
  

  const toggleMobileMenu = () => {
    setmobilemenuClose(!mobilemenuClose);
  };
  const handleShow = () => {
    setIsOpen(true);
  };
  function loginModalScreen() {
    setscreenshift(1)
    setnewLoginModal(!newLoginModal)
    handleItemClick(4)
  }

  function showSearchBar() {
    setshowSearch(!showSearch)

  }
  const uploadModalScreen = async () => {
    setUploadArtMusicModalOpen(!uploadArtMusicModalOpen);
    // if (subscriptio_id) {
    //   try {

    //     const res = await dispatch(CheckUserSubStatus(subscriptio_id));
    //     if (res.payload.status === 'canceled') {
    //       toast.error("Your subscription has been canceled");
    //       localStorage.clear();
    //       navigate('/');
    //     } else if (res.payload.status === 'No active subscription') {
    //       toast.warn("Please complete your subscription", { autoClose: 1000 });
    //       setUploadArtMusicModalOpen(!uploadArtMusicModalOpen);
    //     }
    //   } catch (error) {
    //     console.error("Error checking user subscription status:", error);
    //   }
    // } else {
      
    // }
  };


  useEffect(() => {
    const handleScroll = () => {
      const topNav = document.querySelector(".topnavbar");
      if (topNav) {
        if (window.scrollY > 0) {
          topNav.classList.add("scrolled");
        } else {
          topNav.classList.remove("scrolled");
        }
      }
    };


    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleItemClick = (index) => {
    setActiveItem(index);
  };




  function shiftCountplus() {

    setscreenshift(screenshift + 1)

  }
  function shiftCountminus() {

    setscreenshift(screenshift - 1)

  }
  function restscreenplus() {
    setscreenshift(screenshift + 2)
  }
  function restscreenminus() {
    setscreenshift(screenshift - 2)
  }
  function interestscreenplus() {
    setscreenshift(screenshift + 2)
  }
  function interestscreenminus() {
    setscreenshift(screenshift - 2)
  }



  const [showSearchModule, setShowSearchModule] = useState(false);

  const handleClick = () => {
    setShowSearchModule(true);
  };
  return (
    <>



      <nav className="topnavbar">
        <div className="container-fluid d-flex align-items-center">
          <Link
            className="nav-link m-0 active"
            aria-current="page"
            to="/"
            style={{ color: "white" }}
            onClick={handleLogoClick}
          // onClick={closeMobileMenu} // Close menu when clicked
          >
            <img
              className="img-fluid logo"
              src={logo}
              alt=""
              style={{ paddingBottom: "3px", width: '200px' }}
            />
          </Link>
          {/* <img className="logo img-fluid" style={{  }} src={logo} alt="" /> */}
          <div className="topList">
            <ul className='m-0 innerlist'>
              <li
                className='header-title'

              >

                <Link className={` openSans-500 fw-semibold ${activeItem === 0 ? 'active' : ''
                  }`} to="/" onClick={() => handleItemClick(0)}
                  style={{ textDecoration: 'none', color: `white`, padding: "10px 20px" }}>Home</Link>
              </li>


              <li
                className='header-title'

              >

                <Link className={`  openSans-500 fw-semibold ${activeItem === 1 ? 'active' : ''
                  }`} to="/gallery" onClick={() => handleItemClick(1)}
                  style={{ textDecoration: 'none', color: `white`, padding: "10px 20px" }}>Gallery</Link>
              </li>

             
              <li
                className='header-title'
              >

                <Link
                  className={` openSans-500 fw-semibold ${activeItem === 2 ? 'active' : ''
                    }`}
                  onClick={() => handleItemClick(2)}
                  to="/blog"
                  style={{ textDecoration: 'none', color: ` white`, padding: "10px 20px" }}>Blog</Link>
              </li>
              <li
                className='header-title'
              >

                <Link
                  className={` openSans-500 fw-semibold ${activeItem === 3 ? 'active' : ''
                    }`}
                  onClick={() => handleItemClick(3)}
                  to="/contact-us"
                  style={{ textDecoration: 'none', color: `white`, padding: "10px 20px" }}>Contact Us</Link>
              </li>




              {isLoggedIn ? (
                <>


                  <li
                    className="header-title d-flex align-items-center justify-content-end me-3"
                    style={{
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={uploadModalScreen}
                    title="Upload Art"

                  >
                    <img src={abc} alt="" className="img-fluid bg-transparent" style={{ color: 'white', width: '25px', height: '25px' }} />


                  </li>


                  <li className="header-title">
                    <UserFlow isLoggedIn={loggedin} />
                  </li>




                </>
              ) : (

                <li className="header-title">
                  <Link
                    className={` m-0  border-0 ${activeItem === 4 ? 'active' : ''}`}
                    to="#"
                    onClick={loginModalScreen}
                    style={{
                      color: "white",
                      fontWeight: "500",
                      textDecoration: 'none'
                    }}
                  >
                    Login / Register
                  </Link>

                </li>

              )}








              <li
                className="header-title d-flex align-items-center"
              >

                {showSearchModule ? (
                  <div >
                    <SearchModule customeWidth={240} />
                  </div>

                ) : (
                  <SearchOutlinedIcon onClick={handleClick} style={{ cursor: 'pointer' }} className="text-white fs-3 fw-bold" />
                )}
              </li>

            </ul>
          </div>
        </div>

        <MenuIcon className="menu-icon" onClick={toggleSidebar} />

        {showSidebar && (
          <div className="sidebar-main">
            <ul className='ps-2'>
              <li className='side-title'><Link  onClick={toggleSidebar}to='/'>Home</Link> </li>
              <li className='side-title' > <Link onClick={toggleSidebar} to='/gallery'>Gallery</Link> </li>

              <li className='side-title' > <Link  onClick={toggleSidebar} to='/blog'>Blog</Link> </li>

              <li className='side-title' > <Link onClick={toggleSidebar} to='/contact-us'>Contact Us</Link> </li>
              {isLoggedIn ? (
                <>


                  <li
                    className=" d-flex align-items-center side-title "
                    style={{
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={uploadModalScreen}
                    title="Upload Art"

                  >
                    <img src={abc} alt="" className="img-fluid bg-transparent" style={{ color: 'white', width: '25px', height: '25px' }} />


                  </li >

                  <div className=" text-center d-flex align-items-center">
                    <li className="side-title">
                      <UserFlow isLoggedIn={loggedin} />
                    </li>
                  </div>



                </>
              ) : (

                <li className="side-title">
                  <Link
                    className={` m-0  border-0 `}
                    to="#"
                    onClick={loginModalScreen}
                    style={{
                      color: "white",
                      fontWeight: "500",
                      textDecoration: 'none'
                    }}
                  >
                    Login / Register
                  </Link>

                </li>

              )}


              {showSearchModule ? (
                <li className="side-title">
                  <SearchModule customeWidth={240} />
                </li>

              ) : (
                <li className='side-title'>
                  <SearchOutlinedIcon onClick={handleClick} style={{ cursor: 'pointer' }} className="text-white fs-3 fw-bold" />
                </li>

              )}

            </ul>
          </div>
        )}
      </nav>







      <UPloadArtAndMusic
        closeModal={uploadModalScreen}
        ModalIsOpen={uploadArtMusicModalOpen}
      />

      {/* <UserUploadArtFile 
        closeModal={uploadModalScreen}
        ModalIsOpen={uploadArtMusicModalOpen}
      /> */}


      <ModalHandler
        closeModal={loginModalScreen}
        ModalIsOpen={newLoginModal}
        screenshift={screenshift}
        shiftCountplus={shiftCountplus}
        shiftCountminus={shiftCountminus}
        restscreenplus={restscreenplus}
        restscreenminus={restscreenminus}
        interestscreenplus={interestscreenplus}
        interestscreenminus={interestscreenminus}

      />
    </>

  );
};

export default Header;
