import axios from 'axios'


 const baseURL = 'https://api.test.artactually.net:3000';

// const baseURL = 'https://api.test.artactually.net:3000';


const instance = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
     'Access-Control-Allow-Origin':"*"
  }
});





instance.interceptors.request.use(
  (config) => {
    const userData = JSON.parse(localStorage.getItem('user'));
    const token = userData?.token;

    if (token && !config.headers['Authorization']) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

       /////For Form Data Request to Backend
const FormData = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/form-data'
  }
});

FormData.interceptors.request.use(
  (config) => {
    const userData = JSON.parse(localStorage.getItem('user'));
    const token = userData?.token;

    if (token && !config.headers['Authorization']) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { instance, baseURL ,FormData };