import React, { useEffect } from 'react'
import cardImg1 from "../../../assets/images/cardImage1.png"
import cardImg3 from "../../../assets/images/cardImage3.png";
import cardImg4 from "../../../assets/images/cardImage4.png";
import cardImg7 from "../../../assets/images/cardImage7.png";
import cardImg8 from "../../../assets/images/cardImage8.png";
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import blogbar from "../../../assets/images/topbarimage.png"
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDispatch, useSelector } from 'react-redux';
import { collectionHavingArtsApi, deleteItemFromCollection, getFavroiteFolderDataApi } from '../../../redux/features/auth/authSlice';
import { toast } from 'react-toastify';

const AllListOfFolderArt = () => {

    const location = useLocation()
    const { folderId, folderName } = location?.state
    const user = JSON.parse(localStorage.getItem("user"));
    const user_id = user?.user[0]._id;
    const { favroiteFolder, isLoading } = useSelector((state) => state.auth)
    console.log("🚀 ~ file: AllListOfFolderArt.jsx:23 ~ AllListOfFolderArt ~ favroiteFolder:", favroiteFolder)

    const dispatch = useDispatch()
  const navigate= useNavigate()
    const deleteItem = async (id) => {

        await dispatch(deleteItemFromCollection({ id: id }))
            .then(async (res) => {
                toast.success(res.payload.message)
                dispatch(collectionHavingArtsApi({ user_id: user_id }))
                dispatch(getFavroiteFolderDataApi({ folder_id: folderId }))
            })

    }

    const moveToUserDetail = (id) => {
      navigate('/profile-for-main', { state: { id: id } })
    }

    useEffect(() => {
        if (folderId) {
            dispatch(getFavroiteFolderDataApi({ folder_id: folderId }))
        }
    }, [folderId])
    return (
        <>
            <div style={{
                backgroundImage: `url(${blogbar})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '110px'
            }}></div>
            <div className="container-fluid  " style={{ fontFamily: "initial", color: "cadetblue", backgroundColor: "#EDEBEA" }}>
                <div className="ms-4 py-4">
                    <h2 className='ms-5 openSans-700' style={{  color: '#6896a1' }}>My Favourite Art Actually /<span><small style={{ fontSize: '18px' }}>{`${folderName} (${favroiteFolder?.data?.length} items )`}</small> </span></h2>
                </div>
                <div className="row justify-content-center  mx-4 pb-5" style={{
                    alignItems: "center", backgroundColor: '#fefefe',
                    marginLeft: "2px", marginRight: "2px", borderRadius: '15px', boxShadow: "  0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                }}>
                    <div style={{ textAlign: "end", paddingTop: "8px" }}>
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                    </div>
                    <div className="col-md-12">
                        <div className="row justify-content-center">
                            <div className="col ">
                                <div className="row test-row">
                                    {favroiteFolder?.data?.map((item, i) => {
                                        const descriptionWords = item.description.split(" ");
                                        const Description = descriptionWords.slice(0, 2).join(" ");
                                        return (
                                            <>
                                                



                                             






                                                <div
                    className="test-card mx-2"
                    style={{
                    

                    }}
                    key={i}
                  >
                    <div
                      className="card folderDetail-card"
                      style={{
                        boxShadow: "3px 2px 6px #bab8b8",
                        backgroundColor:'#F8F8F8',
                          borderRadius: "15px",
                        overflow: 'hidden',
                      
                      }}
                    >
                      <div className='folderDetail-imagelength' style={{  overflow: 'hidden', }} >
                        <img
                          className='img-fluid'
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            cursor: 'pointer',
                          }}

                          src={item.image ? item?.image[0] : "No User Exist"}
                          alt="Not data"
                         
                        />
                      </div>

                      <div className="p-2 d-flex align-items-center">
                        <div className="">
                          <h6 className='p-0 ' style={{ fontWeight: '700', color: '#000000', fontSize: "14px", lineHeight: "16.94px", textTransform: 'capitalize' }}>
                            {item.title ? item.title : 'No User Exist'}
                          </h6>

                          <p
                                    onClick={() => moveToUserDetail(item.owner_id)}
                                    className='p-0 m-0'
                                    style={{ fontWeight: '400', textDecoration: 'none', color: '#709BA5', fontSize: "14px", lineHeight: "16.94px" }}
                                  >
                                    by <span className="fw-bold fs-6" style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item?.ownerName}</span>
                                  </p>
                        </div>
                        <div className='col text-end'>

                        </div>
                      </div>
                      <hr className='m-0'/>
            <div className="w-100 d-flex justify-content-end align-items-center">
                <div>
                         <DeleteForeverIcon className='mx-2 my-1 ' style={{cursor:'pointer', color:'lightgray'}} onClick={()=>deleteItem(item.id_for_delete)}/>
            
                </div>
       
            </div>
                    </div>

                  </div>

                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>


                                  

                    </div>



                </div>
            </div>
        </>
    )
}

export default AllListOfFolderArt