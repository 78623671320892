import React, { useEffect, useRef, useState } from "react";
import "../Gallery/gallery.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AddFvrtArts, getAllArts, getAllSound, getFvrtArts } from "../../redux/features/auth/authSlice";
import Login from "../../Screens/Login";
import { baseURL } from "../../redux/axios/axios";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { toast } from "react-toastify";
import Tooltip from '@mui/material/Tooltip';
import ModalHandler from "../../Screens/newloginflow/ModalHandler";
import { AudioPlayer } from "../audioPlayer/AudioPlayer";
import CustomPlayer from "../audioPlayer/CustomrPlayer";
import filterIcon from '../../assets/images/filter_icon.png'
import search from '../../assets/search.png'
import headerImage from '../../assets/images/headerImage.png'
import SelectPage from "../Gallery/SelectPage";



const MusicFilters = () => {

  const USER = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState([]);
  console.log("🚀 ~ file: MusicPage.jsx:19 ~ MusicPage ~ data:", data)
  const [screenshift, setscreenshift] = useState(1)
  const [newLoginModal, setnewLoginModal] = useState(false)
  const [searchQuery, setSearchQuery] = useState("");

  const [mood, setSelectedMood] = useState("");
  const [genre, setSelectedGenre] = useState("");
  const [instrument, setSelectedInstrument] = useState("");



  const [showLoginModal, setShowLoginModal] = useState(false);

  const {allSound} = useSelector((state) => state.auth);
  
  const {isLoading} = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showAllMoods, setShowAllMoods] = useState(false);
  const [showAllGenres, setShowAllGenres] = useState(false);
  const [showAllInstruments, setShowAllInstruments] = useState(false);

  useEffect(() => {
    const getData = async () => {
      await dispatch(getAllSound());
    };

    getData();
  }, [dispatch]);

  // const searchData = () => {
  //   const filteredData = allSound?.data?.filter((item) => {
  //     const titleMatch = item.title.toLowerCase().includes(searchQuery.toLowerCase());
  //     const descriptionMatch = item.description.toLowerCase().includes(searchQuery.toLowerCase());
  //     return titleMatch || descriptionMatch;
  //   });
  //   setData(filteredData);
  // };

  // const handleSearch = () => {
  //   searchData();
  // };



 useEffect(() => {
  const sendFilterBackend = async () => {
    try {
      

      const queryParams = {
        mood: mood,
        genre: genre,
        instrument: instrument,
      };

      const filteredQueryParams = Object.fromEntries(
        Object.entries(queryParams).filter(([_, value]) => value !== "")
      );

      const queryString = new URLSearchParams(filteredQueryParams).toString();


            console.log("Final Query String:", queryString);

      await dispatch(getAllSound(queryString));
    } catch (error) {
      console.error("Error sending filter:", error);
    }
  };

  sendFilterBackend();
}, [mood, genre, instrument, dispatch]);





  







  const audioRefs = useRef({});
  const [activeId, setActiveId] = useState(null);


  
  return (
    <>
   
     
       
        
            <div
        

              
            >
          
                <div className=""
               
                
                >
                  <article
                    className="card-group-item text-muted"
                    style={{ fontWeight: "700" }}
                  >
                    

                    <header
                      className="card-header border-bottom-0 mt-3 pb-0"
                      style={{ background: "transparent" }}
                    >
                      <h6
                        className="title mb-2 fw-bold ps-3"
                        style={{
                          fontSize: "15px",
                          color: "#709BA5",
                          fontWeight: "400",
                        }}
                      >
                        Mood
                      </h6>
                    </header>
                    <div className="filter-content ps-3 mt-1">
                      <div className="card-body">
                        <form>
                          {allSound?.distinctMoods?.slice(0, showAllMoods ? undefined : 3)?.map((item, i) => {
                            return (
                             
                            <div className="checkbox-wrapper my-3" key={i}>
<label>
  <input
    type="checkbox"
    checked={mood === item.title}
    value={item.title}
    onChange={(e) => {
      const { value, checked } = e.target;
      setSelectedMood(checked ? value : "");
    }}
    className={mood ===item.title ? "checked" : ""}
   
  />
  <span style={{color:'#709DA7'}}>{item.title}</span>
</label>
</div>
                                 
                             
                            );
                          })}
                        </form>
                      
                      </div> 
                      {allSound?.distinctMoods?.length >= 3 && (
          <span
            className="form-check-label fw-bold ps-4"
            style={{ fontSize: "12px", color: "grey", fontWeight: "500", opacity: '0.7' ,cursor:'pointer'}}
            onClick={() => setShowAllMoods(!showAllMoods)}
          >
            {showAllMoods ? "Show less" : "Show more"}
          </span>
        )}
                    </div>
                    
                  </article>

                  <hr style={{ opacity: "0.2" }} />

                  <article
                    className="card-group-item text-muted mb-3"
                    style={{ fontWeight: "700" }}
                  >
                    <header
                      className="card-header border-bottom-0  pb-0"
                      style={{ background: "transparent" }}
                    >
                      <h6
                        className="title mb-2 pb-0 fw-bold ps-3"
                        style={{
                          fontSize: "15px",
                          color: "#709BA5",
                          fontWeight: "400",
                        }}
                      >
                        Genre
                      </h6>
                    </header>
                    <div className="filter-content ps-3 mt-1">
                      <div className="card-body">
                        <form>
                        {allSound?.distinctGenres?.slice(0, showAllGenres ? undefined : 3)?.map((item, i) => {
                            return (
                              <div className="checkbox-wrapper my-3" >
                              <label>
                                <input
                                  type="checkbox"
                                  checked={genre === item.title}
                                  value={item.title}
                                  onChange={(e) => {
                                    const { value, checked } = e.target;
                                    setSelectedGenre(checked ? value : "");
                                  }}
                                  className={genre ===item.title ? "checked" : ""}
                                 
                                />
                                <span style={{color:'#709DA7'}}>{item.title}</span>
                              </label>
                              </div>
                            );
                          })}
                        </form>
                      </div>
                      {allSound?.distinctGenres?.length >= 3 && (
          <span
            className="form-check-label fw-bold ps-4"
            style={{ fontSize: "12px", color: "grey", fontWeight: "500", opacity: '0.7',cursor:'pointer' }}
            onClick={() => setShowAllGenres(!showAllGenres)}
          >
            {showAllGenres ? "Show less" : "Show more"}
          </span>
        )}
                    </div>
                  </article> 


                  <hr style={{opacity:'0.2'}}/>

                 <article
                    className="card-group-item text-muted mb-3"
                    style={{ fontWeight: "700" }}
                  >
                    <header
                      className="card-header border-bottom-0  pb-0"
                      style={{ background: "transparent" }}
                    >
                      <h6
                        className="title mb-2 pb-0 fw-bold ps-3"
                        style={{
                          fontSize: "15px",
                          color: "#709BA5",
                          fontWeight: "400",
                        }}
                      >
                        Instrument
                      </h6>
                    </header>
                    <div className="filter-content ps-3 mt-1">
                      <div className="card-body">
                        <form>
                        {allSound?.distinctInstruments?.slice(0, showAllInstruments ? undefined : 3)?.map((item, i) => {
                            return (
                              <div className="checkbox-wrapper my-3" >
<label>
  <input
    type="checkbox"
    checked={instrument === item.title}
    value={item.title}
    onChange={(e) => {
      const { value, checked } = e.target;
       setSelectedInstrument(checked ? value : "");
                 }}

    className={instrument ===item.title ? "checked" : ""}
   
  />
  <span style={{color:'#709DA7'}}>{item.title}</span>
</label>
</div>
                            );
                          })}
                        </form>
                      </div>
                      {allSound?.distinctInstruments?.length >= 3 && (
          <span
            className="form-check-label fw-bold ps-4"
            style={{ fontSize: "12px", color: "grey", fontWeight: "500", opacity: '0.7',cursor:'pointer' }}
            onClick={() => setShowAllInstruments(!showAllInstruments)}
          >
            {showAllInstruments ? "Show less" : "Show more"}
          </span>
        )}
                    </div>
                  </article> 
                </div>
          
            </div>

            {/* <div className="col-md-8 col-xl-9 col-lg-8  col-sm-12 col-12 pb-3  pe-md-0 pe-sm-3">
    
              <div className="row mt-md-0 mt-sm-3 ms-md-5 mt-xs-3 mt-3">

                {isLoading ?
                 
                 <div class="d-flex justify-content-center">
                 <div class="spinner-border" role="status" style={{color:'#709AA4'}}>
                   <span class="sr-only">Loading...</span>
                 </div>
               </div>
                 : data.length >0 ?

              (  data &&   <CustomPlayer
                   detailPage={(item)=>details(item)}
                   audiodata={data}
                   />
                )
               
                : isLoading ? 
                  <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status" style={{color:'#709AA4'}}>
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                : allSound?.data?.length > 0 ? 
              
                  allSound?.data && <CustomPlayer
                     detailsPage={(item)=>details(item)}
                  audiodata={allSound?.data}
                  />
             
                  
                 
                : 
                  <div className="text-muted fs-3">No Data Found</div>
                }
              
              </div>

                
             
            </div> */}

      

          
    
    </>
  );
};

export default MusicFilters;
