import React from 'react'
import './welcome.css'
import img1 from '../../assets/images/mainLogo.png'
import img2 from '../../assets/logo/artactully-logo-white.svg'
import { Button } from "@mui/material";
import { BsFillCheckCircleFill } from "react-icons/bs"
import {  useNavigate } from 'react-router-dom';




const Welcome = () => {

    const navigate = useNavigate()
    const loginPage = () => {
        navigate('/')
    } 


    return (
        <>
          
                <div className="text-center  p-5 container">
                    <div className="text-center container rounded" style={{background:'#86C1CD'}} >
                        <img src={img2} alt="" className='img-fluid img-responsive ' />
                         


                        <p className='mt-5  email-varified openSans-400'>  <span>
                            <BsFillCheckCircleFill style={{ fontSize: "55px", color: "#5e855e", marginRight: "20px" }} className='icon' />
                        </span>Email Verified</p>

                        <p className='welcome-font  openSans-300  px-3 mx-auto text-white' style={{ maxWidth: '600px',textAlign:"justify" ,}}>
                        Congratulations and Welcome to Art Actually!
Your email has been successfully verified, and a canvas of possibilities awaits you. <br /><br className='text-white' />You're now part of an exclusive platform where artists and art lovers converge to share, discover, and purchase stunning digital art!

Log In To Your Account and begin your art odyssey with Art Actually.


                        </p>
                        <Button
                            variant="contained"
                            type="button"


                            sx={{
                                color: "#fff",
                                background: "#709AA4",
                                borderRadius: "8px",
                                textTransform: "capitalize",
                                marginTop:"10px",
                                marginBottom:"10px",
                                "&:hover": {
                                    background: "#709AA4",
                                },
                            }}

                            onClick={loginPage}
                        >
                            Login to Your Account
                        </Button>
                        <p className='welcome-font mt-4 openSans-300 px-3 mx-auto text-white' style={{ maxWidth: '600px',textAlign:"justify" }}>

                        Thank you for joining us. 

             Together, let's make the art world a little more connected and a lot more colorful.                        </p>

                        <h4 className='mt-5 openSans-500 text-white' style={{color:' #709BA5'}}>Thank You!   Art Actually</h4>
                        
                    </div>


                </div>

      
        </>
    )
}

export default Welcome
