
import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { searchDataApi } from '../../redux/features/auth/authSlice';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {  ListItemIcon, ListItemText  } from '@mui/material';
import { Person} from '@mui/icons-material';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

import './seachInput.css'

export default function SearchModule({customeWidth,inputPositioin}) {

  const [dropDownValues, setdropDownValues] = useState([]);
  const {searchResult}=useSelector((state)=>state.auth)




  useEffect(() => {
    if (searchResult?.data?.length>0) {
      setdropDownValues((prevValues) => [

        ...searchResult.data.map((data) => ({
          label: data.data.userName?  data.data.userName: data.data.firstName? data.data.firstName: data.data.title,
          tag:data.data.firstName || data.data.userName?'user':'art',
          music:data.data.file? 'music':'',
          id:data.data._id,
          icon:data.data.firstName || data.data.userName? <Person/>: data.data.file? <MusicNoteIcon/>:<ColorLensIcon/>
        })),
      ]);
    }
  }, [searchResult?.data]);
  

const dispatch = useDispatch()

const handleInputChange = (event, value, reason) => {
  if (reason === 'input') {
    dispatch(searchDataApi({ searchTerm: event.target.value }));
  } else if (reason === 'reset') {
    // Handle the backspace key or removal of characters
    // You can update the state or take any necessary actions
    // For example, clear the dropdown values
    setdropDownValues([]);
  }
};

  const navigate = useNavigate()

 
     
  const handleOptionChange = (event, value) => { 
      console.log("🚀 ~ file: SearchModule.jsx:46 ~ handleOptionChange ~ value:", value)
      
      if (value) {
      console.log('Selected Movie:', value.tag,value.id);
      if(value.tag ==='user' ){
      
         navigate("/profile-for-main",{state:{id:value.id}}) 
     
      }else if(value.tag ==='art'&& value.music===''){
      
         navigate("/art-details",{state:{id:value.id}})
      }
      else if(value.tag ==='art' && value.music==='music'){
         navigate("/music-details", { state: { id: value.id, } });
      }
      
      

    }
  };
  return (
   
    <Autocomplete
      className="d-inline-block"
      id="custom-input-demo"
      options={dropDownValues}
      onChange={handleOptionChange}
      onInputChange={handleInputChange}
    
      renderInput={(params) => (
        <div className={`${inputPositioin==='footer'?'input-container':''}`} ref={params.InputProps.ref}>
          <input
            type="text"
            {...params.inputProps}
           
            className={` ${inputPositioin==='footer'?'footerinputmodule':'searchinputmodule'}`}
            placeholder={`${inputPositioin==='footer'?'Search art by Keyword':'Search..'}`}
          />
        </div>
      )}
      renderOption={(props, option) => (
        <li {...props} className="d-flex" style={{cursor:'pointer'}}>
          <ListItemIcon className="mx-0 px-0">{option.icon}</ListItemIcon>
          <ListItemText primary={option.label} className="mx-0 px-0" />
        </li>
      )}
    />
 
  );
}







