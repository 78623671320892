import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import "./Blog.css"
import { useDispatch, useSelector } from "react-redux";
import { ListOfBlogs } from "../../redux/features/auth/PaymentSlice";
import { GetBlogCategories } from "../../redux/features/auth/PaymentSlice";
import uploadBlog from '../../assets/icons/uploadblog.png'
import headerImage from '../../assets/images/headerImage.png'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import search from '../../assets/search.png'

const BlogPostOne = () => {

  const { blogList, isLoading } = useSelector((state) => state.payment)

  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([])
  const [higlighter, sethiglighter] = useState('1')
  const [bolgscount, setbolgscount] = useState(3)
  
  console.log("🚀 ~ BlogPostOne ~ bolgscount:", bolgscount)
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(ListOfBlogs({}))
  }, [dispatch])

  const navigate = useNavigate()

const addblogs=()=>{
  console.log(data.length, 'length');
  if(data.length>bolgscount+4){

    setbolgscount(bolgscount+4)
  }
  else if(data.length<bolgscount+4 && data.length!==bolgscount){
setbolgscount(data.length)
}
else if(data.length===bolgscount){
  setbolgscount(3)
}


} 

  const searchData = () => {
    const filteredData = blogList?.data?.filter((item) => {
      const titleMatch = item.title.toLowerCase().includes(searchQuery.toLowerCase());
      //const descriptionMatch = item.description.toLowerCase().includes(searchQuery.toLowerCase());
      // return titleMatch || descriptionMatch;
      return titleMatch;
    });
    setData(filteredData);
  };

  useEffect(() => {
    if (blogList?.data) {
      setData(blogList?.data)
    }

  }, [blogList?.data])

  const [cat, setCat] = useState([])
  useEffect(() => {
    const getData = async () => {
      const data = await dispatch(GetBlogCategories())
      setCat(data?.payload?.data)
    }
    getData()
  }, [])



  const AllDataFun = async () => {
    sethiglighter('1')
    dispatch(ListOfBlogs({}))
  }
  const printingDataFun = async () => {
    sethiglighter('2')
    dispatch(ListOfBlogs({ cat_id: cat[0]?._id }))
  }

  const MusicDataFun = async () => {
    sethiglighter('3')
    dispatch(ListOfBlogs({ cat_id: cat[2]?._id }))
  }
  const installationDataFun = async () => {
    sethiglighter('4')
    dispatch(ListOfBlogs({ cat_id: cat[1]?._id }))
  }
  const others = async () => {
    sethiglighter('5')
    dispatch(ListOfBlogs({}))
  }

  const showDetailBlog = (id) => {

    navigate('/blog/allpost', { state: { id: id } })
  }

  return (
    <>
      <div
        className="col text-center background-image"
        style={{
          borderTop: '15px solid white',
          borderLeft: '15px solid white',
          borderRight: '15px solid white',
          backgroundImage: `url('${headerImage}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "500px",

        }}
      >
        <div className="container" style={{ borderRadius: "0 20px 20px 0" }}>
          <div className="row d-flex justify-content-center">

            <div className="col-sm-6 d-flex col-md-9 col-sm-10 col-10 ps-0 ">

              {/* <select class="maininputdropdown openSans-700" style={{width:"135px" }} aria-label="Default select example"
              
                >
  <option selected style={{fontWeight:'bold', color:'#73a7b1'}}>All Items</option>
  {userList?.formats.map((item,i)=>{
    console.log(item,'formats');
    return(
      <>
      <option value={`${item._id}`} style={{fontWeight:'bold', color:'#73a7b1', padding:'10px'}}>{item.title}</option>
      </>
    )
    

  })}
  
 

</select> */}
              <div className="w-100">
                <input

                  type="text"
                  className="mainsearchinput text-muted border-0 w-100 p-3"
                  sty
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />


              </div>



              <div
                className="input-group-append border-0 text-center text-light d-flex align-items-center justify-content-center"
                style={{ width: "60px", background: "#719DA8", borderRadius: '0 5px 5px 0', fontWeight: '800' }}
                onClick={searchData}
              >
                <img src={search} alt="" style={{ height: '26px', width: '26px' }} />

              </div>





            </div>
          </div>
        </div>
      </div>



      <div className="container-fluid bg-light">

        <div className="row justify-content-center" >
          <div className="col-xl-9 col-md-8 col-sm-7 col-6 order-md-1 order-sm-2">
            <div className="row justify-content-center">
              {
                isLoading ?
                  <div class="d-flex justify-content-center mt-4">
                    <div class="spinner-border" role="status" style={{ color: '#709AA4' }}>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  :
                  data?.length > 0 ?
                    data?.slice(0,bolgscount).map((item, i) => {

                      const timestamp = Number(item.date); // Your timestamp here
                      const monthNames = [
                        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
                        'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
                      ];

                      const date = new Date(timestamp); // Replace this with your date

                      const month = monthNames[date.getMonth()]; // Get the month name

                      const day = String(date.getDate()).padStart(2, '0');


                      return (
                        <>
                          <div className=" col-md-2 col-sm-3 col-3 mb-1" key={i}>
                            <h3 className="my-3 card-Date pe-3 openSans-800">
                              {day} <br />
                              <span
                                style={{ fontSize: "26px", }}
                              >
                                {month}
                              </span>
                            </h3>
                          </div>
                          <div className=" col-md-10 col-sm-9 col-9 shadow border-0 my-1">
                            <div className="links-card row justify-content-center" style={{
                              backgroundColor: '#fefefe',
                              height: '100%'
                            }}>
                              <div className="col-xl-7 col-lg-7 col-md-6 col-sm-9 col-9">
                                <div className="d-flex">
                                  {item.categories?.map((item) => {
                                    return (
                                      <p className="my-3 mx-2 openSans-500" style={{ color: '#709CA6', textTransform: 'uppercase' }}>{item}</p>
                                    )
                                  })}
                                </div>


                                <div className="link-card-title openSans-700" style={{ textTransform: "capitalize" }}>{item.title}</div>
                                <div className="my-2 openSans-400" style={{ color: '#B9CDD0', textTransform: "capitalize" }}>
                                  {item?.description?.split(' ').slice(0, 8).join(' ')}
                                  {item?.description?.split(' ').length > 8 ? '...' : ''}
                                </div>
                                <div style={{ marginTop: "5rem" }}>

                                  <span className="read mt-5 pe-3 openSans-600" onClick={() => showDetailBlog(item._id)} style={{ float: 'right', color: '#709CA6', cursor: 'pointer', textTransform: "capitalize" }}>
                                    READ MORE....
                                  </span>
                                </div>
                              </div>
                              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 p-0 d-flex justify-content-center">



                                <div className='' style={{


                                  width: '500px',
                                  height: '280px',
                                  overflow: 'hidden', // Ensure that content beyond the card size is hidden
                                }}>
                                  <img
                                    className='img-fluid'
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      objectFit: 'cover', // This will center and crop the image

                                    }}
                                    src={item?.image?.length > 0 ? item?.image[0] : uploadBlog}
                                    alt="Not data"

                                  />
                                </div>


                              </div>
                            </div>
                          </div>

                        </>


                      )
                    })
                    :


                    <h5 className="text-muted p-5">
                      No data
                    </h5>
              }



        
            </div>

          </div>


          <div className="col-xl-3  col-md-4 sidebar-blog order-md-2 order-sm-1">

            <div className=" ps-5 mt-5 ">
              <h6 className={`opa mt-4  fw-bold `}
                style={{ cursor: 'pointer', color: `${higlighter === '1' ? '#709DA7' : "#BACDD2"}`, textTransform: 'uppercase' }}
                onClick={AllDataFun}>
                {/* <NavLink to="/blog/allpost">All POSTS</NavLink> */}
                All Post
              </h6>
              <h6 className={`opa mt-4 fw-bold `} style={{ cursor: 'pointer', color: `${higlighter === '2' ? '#709DA7' : "#BACDD2"}`, textTransform: 'uppercase' }} onClick={printingDataFun}>Printing</h6>
              <h6 className={`opa mt-4 fw-bold`} style={{ cursor: 'pointer', color: `${higlighter === '3' ? '#709DA7' : "#BACDD2"}`, textTransform: 'uppercase' }} onClick={MusicDataFun} >Music</h6>
              <h6 className={`opa mt-4 fw-bold `} style={{ cursor: 'pointer', color: `${higlighter === '4' ? '#709DA7' : "#BACDD2"}`, textTransform: 'uppercase' }} onClick={installationDataFun}>Installation</h6>
              <h6 className={`opa mt-4 fw-bold`} style={{ cursor: 'pointer', color: `${higlighter === '5' ? '#709DA7' : "#BACDD2"}`, textTransform: 'uppercase' }} onClick={others}>Others</h6>
            </div>

          </div>




          

        </div>

<div className="d-flex  justify-content-center mt-5 pb-5">
          <div>
            <p style={{color:"#709da7",fontWeight:'600',margin:'0', cursor:'pointer'}} onClick={addblogs}   > {data.length===bolgscount?'SHOW LESS':'SHOW MORE'}</p>
          
            <div className="d-flex  justify-content-center">
               <MoreVertIcon  className='my-1' style={{color:"#709da7"}}/>
            </div>
             
          </div>

        </div>








      </div>

    </>
  );
};

export default BlogPostOne;
