import React from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import { Routes, Route, useLocation } from "react-router-dom";
import LandingPage from "./Screens/LandingPages/LandingPage";
import BlogPostOne from "./Components/Blog/BlogPostOne";
import { Stepper } from "./Components/stepperForSignup/Stepper";
import Allpost from "./Components/Blog/Allpost";

import Contact from "./Components/contact-us";
import GalleryPage from "./Components/Gallery/GalleryPage";
import GalleryPageDetails from "./Components/Gallery/GalleryPageDetails";
import { Dashboard } from "./Screens/UserDashboard/Dashboard";
import IsProtected from "./auth/IsProtected";
import { Footer } from "./Components/Footer";
import MyFavouriteArtGallery from "./Screens/UserDashboard/FavouriteArt/MyFavouriteArt";
import Plans from "./Components/Subscription_Plan/Plans";
import Payment from "./Screens/UserDashboard/Payment/UserPayment";

import ReviewStripe from "./Components/Review/ReviewStripe";
import NewPassword from "./Components/Reset Password/ResetPassword";
import Welcome from "./Components/welcome/Welcome";
import SubscriptionThankYou from "./Components/SubscriptionThankYou";
import TersmsAndConditions from "./Components/Terms&Use";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import StripePayment from "./Components/Subscription_Plan/StripePayment";
import AllListOfFolderArt from "./Screens/UserDashboard/FavouriteArt/AllListOfFolderArt";
import PublicProfileData from "./Screens/UserDashboard/PublicProfile/PublicProfileData";
import Header from "./Components/TopNavbar/Header";
import MusicPageDetails from "./Components/Gallery/MusicPageDetails";

function App() {

  // const navigate = useNavigate();

  // Define a catch-all route that redirects to the landing page
  // const NotFound = () => {
  //   navigate("/");
  //   return null;
  // };
  const location = useLocation();

  // Check if the current route path is "/thankyou"
  const isThankYouRoute = location.pathname === "/thankyou";


  return (
    <div className="" style={{ minHeight: "100vh" ,overflow:'hidden' }}>
      <ToastContainer />

      <Routes>
        {/* <Route path="/" element={<LandingPage />} /> */}
        <Route path="/dashboard/*" element={<IsProtected Component={Dashboard} />}/>
        {/* <Route path="*" element={<NotFound />} />  */}
      </Routes>

      {/* Move the TopNavbar inside the Routes */}
      <Routes>
        {/* Include all the routes where the TopNavbar should be visible */}
        <Route path="/" element={<Header />} />
        <Route path="/blog/allpost" element={<Header />} />
        <Route path="/blog" element={<Header />} />
        <Route path="/step" element={<Header />} />

        <Route path="/contact-us" element={<Header />} />
        <Route path="/gallery" element={<Header />} />
        <Route path="/music" element={<Header />} />
        <Route path="/music-details" element={<Header />} />
        <Route path="/art-details" element={<Header />} />
        <Route path="/my-favorite-art-gallery" element={<Header />} />
        <Route path="/donate" element={<Header />} />
        <Route path="/checkout" element={<Header />} />
        <Route path="/Terms&Use" element={<Header />} />
        <Route path="/privacy-policy" element={<Header />} />
        <Route path="/subscription-page" element={<Header />} />
        <Route path="/User-Public-Profile/:id/*" element={<Header />} />
        <Route path="/folder-detail" element={<Header />} />
        <Route path="/profile-for-main" element={<Header />} />

      </Routes>

      <Routes>
      <Route path="/" element={<LandingPage />} />

        <Route path="/blog/allpost" element={<Allpost />} />
        <Route path="/blog" element={<BlogPostOne />} />
        <Route path="/step" element={<Stepper />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/music-details" element={<MusicPageDetails />} />
        <Route path="/art-details" element={<GalleryPageDetails />} />
        <Route path="/my-favorite-art-gallery" element={<MyFavouriteArtGallery />} />
        <Route path="/payment-plans/:id/*" element={<Plans />} />
        <Route path="/payment" element={<Payment />} />
       
        <Route path="/forgotpassword/:id/*" element={<NewPassword />} />
        <Route path="/checkout" element={<ReviewStripe />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/thankyou/:id/*" element={<SubscriptionThankYou />} />
        <Route path="/Terms&Use" element={<TersmsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/subscription-page" element={<StripePayment />} />
        <Route path="/folder-detail" element={<AllListOfFolderArt />} />
        <Route path="/profile-for-main" element={<PublicProfileData />} />

        {/* <Route path="/" element={<LandingPage />} /> */}






      


      </Routes>

      {!isThankYouRoute && <Footer />}
    </div>
  );
}

export default App;
