import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cardImg1 from "../../assets/images/cardImage1.png";
import cardImg3 from "../../assets/images/cardImage3.png";
import cardImg4 from "../../assets/images/cardImage4.png";
import cardImg7 from "../../assets/images/cardImage7.png";
import cardImg8 from "../../assets/images/cardImage8.png";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getAllArts, getAllSound } from "../../redux/features/auth/authSlice";
import ArtWorkDetails from "./ArtWorkDetails";
import "./allartwork.css";
import { ListOfBlogs } from "../../redux/features/auth/PaymentSlice";
import { AudioPlayer } from "../../Components/audioPlayer/AudioPlayer";
import CustomPlayer from "../../Components/audioPlayer/CustomrPlayer";
import MainPagePlayer from "../../Components/audioPlayer/MainPagePlayer";
import { Link, useNavigate } from "react-router-dom";

export const AllArtWork = () => {
  const [sound, setSound] = useState([]);
  const [art, setArt] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [musicscount, setmusicscount] = useState(4)

  const dispatch = useDispatch();
  const {allSound} = useSelector((state) => state.auth);


   
 
  const [DetailsOpen, setDetailsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [activeId, setActiveId] = useState(null);
  const [soundDuration, setsoundDuration] = useState()
  const audioRefs = useRef({});

  
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredIndexx, setHoveredIndexx] = useState(null);
  const [hoveredIndexxx, setHoveredIndexxx] = useState(null);
  const [hoveredIndexxxx, setHoveredIndexxxx] = useState(null);
  const [hoveredIndexxxxx, setHoveredIndexxxxx] = useState(null);
  const [hoveredIndexxxxxx, setHoveredIndexxxxxx] = useState(null);
  const [hoveredIndexxxxxxx, setHoveredIndexxxxxxx] = useState(null);


  const addMusic=()=>{
    console.log('conditon 0',allSound?.data?.length);

    if(allSound?.data?.length>musicscount+4){
      console.log('conditoin 1');
      setmusicscount(musicscount+4)
    }
    else if(allSound.data?.length<musicscount+4 && allSound.data?.length!==musicscount){
      console.log('condion 2');
      setmusicscount(allSound.data?.length)
  }
  else if(allSound.data?.length===musicscount){
    console.log('conditon 3');
    setmusicscount(4)
  }else if(allSound.data?.length===musicscount+4){
    console.log('conditon 3');
    setmusicscount(musicscount+4)
  }
}

  const handleMouseEnter1 = (index) => {
    setHoveredIndex(index);
  };

 
  const handleMouseLeave1 = () => {
    setHoveredIndex(null);
  };
  
  const handleMouseEnter2 = (index) => {
    setHoveredIndexx(index)
  };
  const handleMouseLeave2 = () => {
    setHoveredIndexx(null)
  };

  const handleMouseEnter3 = (index) => {
    setHoveredIndexxx(index)
  };
  const handleMouseLeave3 = () => {
    setHoveredIndexxx(null)
  };
  const handleMouseEnter4 = (index) => {
    setHoveredIndexxxx(index)
  };
  const handleMouseLeave4 = () => {
    setHoveredIndexxxxx(null)
  };
  const handleMouseEnter5 = (index) => {
    setHoveredIndexxxx(index)
  };
  const handleMouseLeave5 = () => {
    setHoveredIndexxxxx(null)
  };
  const handleMouseEnter6 = (index) => {
    setHoveredIndexxxxx(index)
  };
  const handleMouseLeave6 = () => {
    setHoveredIndexxxxxx(null)
  };
  const handleMouseEnter7 = (index) => {
    setHoveredIndexxxxxx(index)
  };
  const handleMouseLeave7 = () => {
    setHoveredIndexxxxxxx(null)
  };
  


  useEffect(() => {
    const getData = async () => {
      const data = await dispatch(getAllSound());
      setSound(data?.payload.data);

      const res = await dispatch(getAllArts());
      const artData = res?.payload.data || [];
      setArt([...artData].reverse());

      const val = await dispatch(ListOfBlogs());
      setBlogs(val?.payload?.data);
    };
    getData();
  }, [dispatch]);

  const DetailsScreen = (item) => {
    setDetailsOpen(!DetailsOpen);
    setSelectedItem(item);
  };

 const navigate = useNavigate()
 
  const showDetailBlog = (id) => {
    navigate('/blog/allpost', { state: { id: id } })
      window.scrollTo(0, 0)  
  }

 
 
  return (
    <>
      <div className="col-md-12">
        {DetailsOpen && (
          <div className="modal-show-landingpage">
            <div className="modal-content-landingpage">
              <ArtWorkDetails
                closeWindow={DetailsScreen}
                selectedItem={selectedItem}
              />
            </div>
          </div>
        )}

        {/* row 1 */}

        <div className="row justify-content-center">
        {art?.slice(0, 2)?.map((item, i) => (
        <div
          key={i}
          className="col-sm-6 p-0 art__img__wrapper pe-1"
          onClick={() => DetailsScreen(item)}
          onMouseEnter={() => handleMouseEnter1(i)}
          onMouseLeave={handleMouseLeave1}
        >
          <div className="position-relative" style={{height:'300px'}}>
            <img
              src={item.image[0]}
              alt=""
              className="img-fluid img-bor w-100 h-100 object-cover"
              style={{ cursor: 'pointer' }}
            />
            {hoveredIndex === i && (
              <div className="owner-name-overlay">
                <h1 className="text-center fs-1 fw-bolder text-white mb-0 pb-0" style={{textTransform:'capitalize'}}>{item?.title}</h1>
                <h3 className="text-center fs-3 fw-bolder text-white" style={{textTransform:'capitalize'}}>{item?.ownerName}</h3>
              </div>
            )}
          </div>
        </div>
      ))}

          {/* Fill remaining spaces with cardImg3 */}
          {Array(Math.max(0, 2 - (art?.slice(0, 2)?.length || 0)))
            .fill(null)
            .map((_, i) => (
              <div key={i} className="col-sm-4 p-0 art__img__wrapper pe-1">
                <img
                  src={cardImg1}
                  alt=""
                  className="img-fluid img-bor w-100 h-100 object-cover"
                  style={{ cursor: "pointer" }}
                />
              </div>
            ))}
        </div>

        {/* row 2 */}

        <div className="row justify-content-center mt-1">
        {art?.slice(2, 5)?.map((item, i) => (
        <div
          key={i}
          className="col-sm-4 p-0 art__img__wrapper pe-1"
          onClick={() => DetailsScreen(item)}
          onMouseEnter={() => handleMouseEnter2(i)}
          onMouseLeave={handleMouseLeave2}
        >
          <div className="position-relative" style={{height:'300px'}}>
            <img
              src={item.image[0]}
              alt=""
              className="img-fluid img-bor w-100 h-100 object-cover"
              style={{ cursor: 'pointer' }}
            />
            {hoveredIndexx === i && (
              <div className="owner-name-overlay">
                <h1 className="text-center fs-1 fw-bolder text-white mb-0 pb-0" style={{textTransform:'capitalize'}}>{item?.title}</h1>
                <h3 className="text-center fs-3 fw-bolder text-white" style={{textTransform:'capitalize'}}>{item?.ownerName}</h3>
              </div>
            )}
          </div>
        </div>
      ))}

          {/* Fill remaining spaces with cardImg3 */}
          {Array(Math.max(0, 3 - (art?.slice(2, 5)?.length || 0)))
            .fill(null)
            .map((_, i) => (
              <div key={i} className="col-sm-4 p-0 art__img__wrapper pe-1">
                <img
                  src={cardImg3}
                  alt=""
                  className="img-fluid w-100 h-100 img-bor object-cover"
                  style={{ cursor: "pointer" }}
                />
              </div>
            ))}
        </div>

        <div
          className="row text-center  pe-1" 
         
        >
          {allSound?.data &&
           <MainPagePlayer
          audiodata={allSound?.data?.slice(0, 2)}
          />
          }
         
         
        </div>

        {/* row 3 */}

        <div
          className="row justify-content-center "
          style={{ marginTop: "2px" }}
        >
          {art?.slice(5, 7)?.map((item, i) => (
        <div
          key={i}
          className="col-sm-6 p-0 art__img__wrapper pe-1"
          onClick={() => DetailsScreen(item)}
          onMouseEnter={() => handleMouseEnter3(i)}
          onMouseLeave={handleMouseLeave3}
        >
          <div className="position-relative" style={{height:'300px'}}>
            <img
              src={item.image[0]}
              alt=""
              className="img-fluid img-bor w-100 h-100 object-cover"
              style={{ cursor: 'pointer' }}
            />
            {hoveredIndexxx === i && (
              <div className="owner-name-overlay">
                <h1 className="text-center fs-1 fw-bolder text-white mb-0 pb-0" style={{textTransform:'capitalize'}}>{item?.title}</h1>
                <h3 className="text-center fs-3 fw-bolder text-white" style={{textTransform:'capitalize'}}>{item?.ownerName}</h3>
              </div>
            )}
          </div>
        </div>
      ))}

          {/* Fill remaining spaces with cardImg3 */}
          {Array(Math.max(0, 2 - (art?.slice(5, 7)?.length || 0)))
            .fill(null)
            .map((_, i) => (
              <div key={i} className="col-sm-6 p-0 art__img__wrapper pe-1">
                <img
                  src={cardImg4}
                  alt=""
                  className="img-fluid img-bor w-100 h-100 object-cover"
                  style={{ cursor: "pointer" }}
                />
              </div>
            ))}
        </div>

        {/* row 4 */}

        <div className="row justify-content-center mt-1">
        {art?.slice(7, 11)?.map((item, i) => (
        <div
          key={i}
          className="col-sm-3 p-0 art__img__wrapper pe-1"
          onClick={() => DetailsScreen(item)}
          onMouseEnter={() => handleMouseEnter4(i)}
          onMouseLeave={handleMouseLeave4}
        >
          <div className="position-relative" style={{height:'300px'}}>
            <img
              src={item.image[0]}
              alt=""
              className="img-fluid img-bor w-100 h-100 object-cover"
              style={{ cursor: 'pointer' }}
            />
            {hoveredIndexxxx === i && (
              <div className="owner-name-overlay">
                <h1 className="text-center fs-1 fw-bolder text-white mb-0 pb-0" style={{textTransform:'capitalize'}}>{item?.title}</h1>
                <h3 className="text-center fs-3 fw-bolder text-white" style={{textTransform:'capitalize'}}>{item?.ownerName}</h3>
              </div>
            )}
          </div>
        </div>
      ))}

          {/* Fill remaining spaces with cardImg3 */}
          {Array(Math.max(0, 4 - (art?.slice(7, 11)?.length || 0)))
            .fill(null)
            .map((_, i) => (
              <div key={i} className={`col-sm-3 p-0 art__img__wrapper pe-1`}>
                <img
                  src={cardImg7}
                  alt=""
                  className="img-fluid w-100 h-100 img-bor object-cover"
                  style={{ cursor: "pointer" }}
                />
              </div>
            ))}
        </div>

        {/* row 5 */}

        <div className="row justify-content-center mt-1">
      

{art?.slice(11, 14)?.map((item, i) => (
        <div
          key={i}
          className={`col-sm-${  i === 1 ? "6" : "3" } p-0 art__img__wrapper pe-1`}
          onClick={() => DetailsScreen(item)}
          onMouseEnter={() => handleMouseEnter5(i)}
          onMouseLeave={handleMouseLeave5}
        >
          <div className="position-relative" style={{height:'300px'}}>
            <img
              src={item.image[0]}
              alt=""
              className="img-fluid img-bor w-100 h-100 object-cover"
              style={{ cursor: 'pointer' }}
            />
            {hoveredIndexxxxx === i && (
              <div className="owner-name-overlay">
                <h1 className="text-center fs-1 fw-bolder text-white mb-0 pb-0" style={{textTransform:'capitalize'}}>{item?.title}</h1>
                <h3 className="text-center fs-3 fw-bolder text-white" style={{textTransform:'capitalize'}}>{item?.ownerName}</h3>
              </div>
            )}
          </div>
        </div>
      ))}

          {/* Fill remaining spaces with cardImg3 */}
          {Array(Math.max(0, 3 - (art?.slice(11, 14)?.length || 0)))
            .fill(null)
            .map((_, i) => (
              <div
                key={i}
                className={`col-sm-${
                  i === 1 ? "6" : "3"
                } p-0 art__img__wrapper pe-1`}
              >
                <img
                  src={cardImg8}
                  alt=""
                  className="img-fluid w-100 img-bor h-100 object-cover"
                  style={{ cursor: "pointer" }}
                />
              </div>
            ))}
        </div>

        <div
          className="row text-center pe-1 " 
          
        >
          {allSound?.data &&
           <MainPagePlayer
          audiodata={allSound?.data?.slice(2, musicscount)}
          />
          }
        </div>
        <div className="d-flex  justify-content-center mt-2">
          <div>
            <p style={{color:"#709da7",fontWeight:'600', textDecoration:'none', cursor:'pointer'}} onClick={addMusic} >{allSound?.data?.length===musicscount?'SHOW LESS':'SHOW MORE'}</p>
            <div className="d-flex  justify-content-center">
               <MoreVertIcon  className='my-1' style={{color:"#709da7"}}/>
            </div>
             
          </div>

        </div>

        {/* Cards */}

        <div className="col-12">
          <div className="row">
          {blogs?.slice(0,8)?.map((data, i) => {
            return (<>
             <div className="col-md-3" key={i} style={{border:'1px solid lightgrey', backgroundColor:'white',cursor:'pointer'}} onClick={() => showDetailBlog(data._id)}>
              <div  className="blog-card px-2 py-3 art__img__wrapper"  >
              <h6 className="" style={{color:"#709da7",textTransform:'uppercase', fontWeight:"600"}}>
                      {data?.categories.map((category, j) => {
                        return (
                          <span key={j} className="category px-2 ps-0">
                            {category}
                          </span>
                        );
                      })}
                    </h6>
                    <h5 className="" style={{ color: "black", textTransform:'capitalize', fontWeight:"800" }}>
                      {data.title}
                    </h5>
                    <p
                      className=""
                      style={{ textAlign: "justify",fontSize:"14px", color:'#C2D5D7', fontWeight:'600' }}
                    >
                       {data?.description?.split(' ')?.slice(0, 15).join(' ')}
                    {data?.description?.split(' ')?.length > 15 ? '...' : ''}
                    </p>
              </div>
            </div>
            </>)
            }
            )}
           
        
          </div>
        </div>
        
        {/* <div className="col-12 row">
          <div
          className="col-md-12 col-sm-11 row justify-content-center blog__section p-0 mt-3"
          style={{ marginTop: "2px" }}
        >
          {blogs?.slice(0,8)?.map((data, i) => {
            return (
              <div
                key={i}
                className="col-xl-3 col-md-3 border  col-sm-6 p-0"
                style={{ textTransform: "capitalize" }}
              >
                <div className="px-md-4 px-sm-2 py-3  w-100 ">
                
                    <h6 className="" style={{color:"#709da7",textTransform:'uppercase'}}>
                      {data?.categories.map((category, j) => {
                        return (
                          <span key={j} className="category px-2 ps-0">
                            {category}
                          </span>
                        );
                      })}
                    </h6>
                    <h5 className="fw-bold" style={{ color: "black" }}>
                      {data.title}
                    </h5>
                    <p
                      className="text-muted"
                      style={{ textAlign: "justify",fontSize:"14px" }}
                    >
                       {data?.description?.split(' ')?.slice(0, 15).join(' ')}
                    {data?.description?.split(' ')?.length > 15 ? '...' : ''}
                    </p>
                
                </div>
              </div>
            );
          })}
        </div>
        </div> */}
        
        <div className="d-flex  justify-content-center mt-2">
          <div>
            <Link style={{color:"#709da7",fontWeight:'600', textDecoration:'none'}} to='/blog'> OPEN BLOG</Link>
            <div className="d-flex  justify-content-center">
               <MoreVertIcon  className='my-1' style={{color:"#709da7"}}/>
            </div>
             
          </div>

        </div>

        <div className="row mt-3">
         
           {art?.slice(18, 22)?.map((item, i) => (
        <div
          key={i}
          className="col-sm-3 p-0 art__img__wrapper pe-1"
          onClick={() => DetailsScreen(item)}
          onMouseEnter={() => handleMouseEnter6(i)}
          onMouseLeave={handleMouseLeave6}
        >
          <div className="position-relative" style={{height:'300px'}}>
            <img
              src={item.image[0]}
              alt=""
              className="img-fluid img-bor w-100 h-100 object-cover"
              style={{ cursor: 'pointer' }}
            />
            {hoveredIndexxxxxx === i && (
              <div className="owner-name-overlay">
                <h1 className="text-center fs-1 fw-bolder text-white mb-0 pb-0" style={{textTransform:'capitalize'}}>{item?.title}</h1>
                <h3 className="text-center fs-3 fw-bolder text-white" style={{textTransform:'capitalize'}}>{item?.ownerName}</h3>
              </div>
            )}
          </div>
        </div>
      ))}

          {/* Fill remaining spaces with cardImg3 */}
          {Array(Math.max(0, 4 - (art?.slice(18, 22)?.length || 0)))
            .fill(null)
            .map((_, i) => (
              <div key={i} className="col-sm-3 p-0 art__img__wrapper pe-1">
                <img
                  src={cardImg8}
                  alt=""
                  className="img-fluid w-100 h-100 object-cover"
                  style={{ cursor: "pointer" }}
                />
              </div>
            ))}
        </div>

        <div className="row my-1 mb-3">
         
           {art?.slice(22, 26)?.map((item, i) => (
        <div
          key={i}
          className="col-sm-3 p-0 art__img__wrapper pe-1"
          onClick={() => DetailsScreen(item)}
          onMouseEnter={() => handleMouseEnter7(i)}
          onMouseLeave={handleMouseLeave7}
        >
          <div className="position-relative" style={{height:'300px'}}>
            <img
              src={item.image[0]}
              alt=""
              className="img-fluid img-bor w-100 h-100 object-cover"
              style={{ cursor: 'pointer' }}
            />
            {hoveredIndexxxxxxx === i && (
              <div className="owner-name-overlay">
                <h1 className="text-center fs-1 fw-bolder text-white mb-0 pb-0" style={{textTransform:'capitalize'}}>{item?.title}</h1>
                <h3 className="text-center fs-3 fw-bolder text-white" style={{textTransform:'capitalize'}}>{item?.ownerName}</h3>
              </div>
            )}
          </div>
        </div>
      ))}

          {/* Fill remaining spaces with cardImg3 */}
          {Array(Math.max(0, 4 - (art?.slice(22, 26)?.length || 0)))
            .fill(null)
            .map((_, i) => (
              <div key={i} className="col-sm-3 p-0 art__img__wrapper pe-1">
                <img
                  src={cardImg3}
                  alt=""
                  className="img-fluid w-100 h-100 object-cover"
                  style={{ cursor: "pointer" }}
                />
              </div>
            ))}
        </div>
        <div className="d-flex  justify-content-center mt-2">
          <div>
            <a style={{color:"#709da7",fontWeight:'600', textDecoration:'none'}} href="https://www.instagram.com" target="_bank"  > OPEN INSTAGRAM</a>
          
            <div className="d-flex  justify-content-center">
               <MoreVertIcon  className='my-1' style={{color:"#709da7"}}/>
            </div>
             
          </div>

        </div>
      </div>
    </>
  );
};
