import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { getArtDetails, getUserArts } from '../../redux/features/auth/authSlice'
import { baseURL } from '../../redux/axios/axios'
import ModalHandler from "../../Screens/newloginflow/ModalHandler";
import DownloadIcon from '@mui/icons-material/Download';
import StarIcon from '@mui/icons-material/Star';
import AttachMoneySharpIcon from '@mui/icons-material/AttachMoneySharp';
import MessageIcon from '@mui/icons-material/Message';
import DonateModal from '../Music/DonateModal'
import AddFvrtMusicModal from '../Music/AddFvrtMusicModal'
import axios from 'axios'
import Button from '@mui/material/Button';
import { toast } from 'react-toastify'
import headerImage from '../../assets/images/headerImage.png'
import '../../Styles/artcard.css'

const GalleryPageDetails = () => {


  const [showLoginModal, setShowLoginModal] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const location = useLocation();

  let artId = location.state?.id;

  const [screenshift, setscreenshift] = useState(1)

  const [newLoginModal, setnewLoginModal] = useState(false)
  const [openModal, setopenModal] = useState(false)


  let login = JSON.parse(localStorage.getItem('user'));
  const user_id = login?.user[0]?._id;


  const [openModalFvrt, setopenModalFvrt] = useState(false)
  const [artData, setArtData] = useState([])

  const [data, setData] = useState()
  const [searchQuery, setSearchQuery] = useState('')
  const [ArtDetail, setArtDetail] = useState()



  const dispatch = useDispatch()
  const navigate = useNavigate()

  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  const donatePage = (item) => {
    if (login) {
      const queryParams = new URLSearchParams({
        art_id: item?._id,
      });

      navigate(`/donate?${queryParams}`, {
        state: {
          title: item.title,
          description: item.description,
          image: item.image,
          art_id: item?._id,
          user_id: item?.owner_id,
        },
      });
    }
    if (!login) {
      loginModalScreen()
    }
  };


  const owner_id = ArtDetail?.owner_id;

  const getDetail = async () => {
    const val = await dispatch(getArtDetails(artId));
    const artData = val.payload.data;
    setArtDetail(artData);
  };

  const getUserArtsData = async () => {
    if (owner_id) {
      const val = await dispatch(getUserArts({ user_id: owner_id }));
      const userData = val.payload.data;
      const filteredData=userData.filter((element)=>element.visibility==='everyone')
      console.log("🚀 ~ getUserArtsData ~ filteredData:", userData)

      setData(filteredData);
    }
  };

  const showMessage = () => {
    toast.success("please login")
  }

  useEffect(() => {
    getDetail();
  }, [artId]);

  useEffect(() => {
    getUserArtsData();
  }, [owner_id]);


  const open = (item) => {
    if (login) {
      setArtData(item)
      setopenModal(!openModal)
    }
    else {
      toast.error('Please login for Donation', { autoClose: 2000 });
    }

  }

  const openFvrt = (item) => {
    if (login) {
      setArtData(item);
      setopenModalFvrt(!openModalFvrt);
    } else {
      toast.error('Please login to store art in your collection.', { autoClose: 2000 });
    }
  }


  const detailsPage = async (item) => {
    navigate("/art-details", { state: { id: item._id } });
    await dispatch(getArtDetails(item._id))
    window.scrollTo(0, 0);
  };


  const MoveToMessageScreen = () => {

    if (!login) {
      loginModalScreen()

    } else {
      navigate('/dashboard/user-dashboard', { state: { eventKey: "sixth", user_id: owner_id } })


    }

  }

  function loginModalScreen() {
    setscreenshift(1)
    setnewLoginModal(!newLoginModal)
  }



  function shiftCountplus() {

    setscreenshift(screenshift + 1)

  }
  function shiftCountminus() {

    setscreenshift(screenshift - 1)

  }
  function restscreenplus() {
    setscreenshift(screenshift + 2)
  }
  function restscreenminus() {
    setscreenshift(screenshift - 2)
  }
  function interestscreenplus() {
    setscreenshift(screenshift + 2)
  }
  function interestscreenminus() {
    setscreenshift(screenshift - 2)
  }

  const moveToUserDetail = (id) => {
    navigate('/profile-for-main', { state: { id: id } })
  }


  const download = async (artId, title) => {
    try {
      setDownloadLoading(true);



      const response = await axios.post(`${baseURL}/art/downloads/file`, {
        itemIds: [artId],
        fileType: 'art'
      }, {
        responseType: 'blob',
        headers: {

        }
      })
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${title}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    catch (error) {
      console.error('Failed to download sounds:', error);
    } finally {
      setDownloadLoading(false);
    }
  }




  return (
    <>
      <DonateModal
        closeModal={open}
        ModalIsOpen={openModal}
        musicData={artData}
      />

      <AddFvrtMusicModal
        closeModal={openFvrt}
        ModalIsOpen={openModalFvrt}
        selectedCheckboxes={artData}
        artId={ArtDetail?._id}
      />

      <div
        className="col text-center background-image"
        style={{
          backgroundImage: `url('${headerImage}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '500px',
          borderTop: '15px solid white',
          borderLeft: '15px solid white',
          borderRight: '15px solid white',


        }}
      >

      </div>


      <div className="container-fluid" style={{ backgroundColor: "#f5f5f5", }}>
        <div className="row mb-5"  >
          <div className="col-md-6 col-sm-12 d-flex  justify-content-center align-items-center" style={{ position: "relative", }}>
            <div className="" style={{ marginBottom: '1rem', background: '#F0EEED' }}>
              <div className=" d-flex justify-content-center" >
                <img
                  className=" img-fluid ps-4 pe-4 pt-4 "
                  src={ArtDetail?.image ? ArtDetail?.image[0] : "No User Exist"}
                  alt="No data"
                  style={{ position: "relative", top: 0, left: 0, right: 0, bottom: 0, }}
                />
              </div>
            </div>


          </div>



          <div className="col-md-6 col-sm-12 ps-5 pt-4">
            <div className="d-flex align-items-center">
              <div className='d-flex w-100'>
                <h2 style={{ fontWeight: '800', fontSize: "22px", color: "#000000", textTransform: "capitalize" }}>
                  {ArtDetail?.title}
                  <span className='ps-1 fw-bold' style={{ textTransform: "capitalize", cursor: 'pointer' }}>-</span> <span style={{ textTransform: "capitalize", color: '#719CA9' }}
                    onClick={() => moveToUserDetail(ArtDetail.owner_id)}
                    className='fw-bolder'>
                    {ArtDetail?.artist}
                  </span>
                </h2>
              </div>

              <div className='w-100 text-end px-4'>

                <button
                  disabled={ArtDetail?.owner_id === user_id}
                  className='btn shadow ' onClick={MoveToMessageScreen}>
                  <MessageIcon
                    style={{
                      fontSize: '40px',
                      color: '#4c6a71'
                    }}
                  />
                </button>

                {showLoginModal && showMessage()}
              </div>
            </div>
            <div className="row">
              <h4 style={{ fontWeight: '700', fontSize: "18px", color: "#000000" }}>
                Printing / Pop Art
              </h4>
            </div>
            <div className="row pt-4 mt-2 mb-2 pe-5">
              <p className='pe-5' style={{ fontWeight: '600', fontSize: "16px", color: 'black', textTransform: "capitalize", textAlign: 'justify' }}>
                {ArtDetail?.description ? ArtDetail?.description : "No User Exist"}
              </p>
            </div>




            <div className="border-start px-2">
              <div className="row">
                <h6 style={{ fontWeight: '700' }}>
                  Format
                </h6>
              </div>
              <div className="row">
                <h6 style={{ fontWeight: '700', textTransform: "capitalize" }}>
                  {ArtDetail?.format_title}
                </h6>
              </div>

              <div className="row pt-2">
                <h6 style={{ fontWeight: '700' }}>
                  Style
                </h6>
              </div>
              <div className="row">
                <h6 style={{ fontWeight: '700', textTransform: "capitalize" }}>
                  {ArtDetail?.style_title}
                </h6>
              </div>

              <div className="row pt-2">
                <h6 style={{ fontWeight: '700' }}>
                  Dimensions
                </h6>
              </div>
              <div className="row">
                <h6 style={{ fontWeight: '700' }}>
                  {`${ArtDetail?.dimension1}(w) * ${ArtDetail?.dimension2}(h) ${ArtDetail?.dimension4}`}
                </h6>
              </div>

              <div className="row pt-2">
                <h6 style={{ fontWeight: '700' }}>
                  Material
                </h6>
              </div>
              <div className="row">
                <h6 style={{ fontWeight: '700', textTransform: "capitalize" }}>
                  {ArtDetail?.material}
                </h6>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 mt-md-4 ">
              {downloadLoading ? (
                /* You can replace this with your own spinner component */
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (<>

                <Button className='ps-4 pe-5 text-white btn-down rounded-3 fw-semibold custom-button justify-content-start'
                  onClick={() => { download(ArtDetail?._id, ArtDetail?.title) }}
                  variant="contained" startIcon={<DownloadIcon />} sx={{ textTransform: 'capitalize', background: 'rgb(112, 155, 165)' }}>
                  {downloadLoading ? 'Downloading...' : 'Download'}
                </Button>
                <p className="ps-2  mb-0"></p>
              </>

              )}

            </div>
            <div className="col-md-4 col-sm-4 mt-md-4 my-sm-3 btn-top">
              <Button
                onClick={() => openFvrt(ArtDetail?._id)}
                className='ps-4 pe-5 text-white btn-down rounded-3 fw-semibold custom-button justify-content-start' variant="contained" startIcon={<StarIcon />} sx={{ textTransform: 'capitalize', background: 'rgb(112, 155, 165)' }}>
                Add to collection
              </Button>
            </div>


            <div className="col-md-4 col-sm-4 mt-md-4 my-sm-3 btn-top">
              {ArtDetail?.owner_id === login?.user[0]?._id ? '' :
                (<Button

                  disabled={ArtDetail?.donation === 'none'}
                  onClick={() => open(ArtDetail)}
                  className='ps-4 pe-5 text-white btn-donate btn-down rounded-3 fw-semibold custom-button justify-content-start' variant="contained" startIcon={<AttachMoneySharpIcon />} sx={{ textTransform: 'capitalize', background: '#56DB99' }}>
                  Donate
                </Button>)

              }
            </div>


          </div>
        </div>



        <div className="row ps-4 pt-4 ">

          <h2 style={{ fontWeight: '400', fontSize: "36px" }}>
            More From <span style={{ color: '#719CA9', textTransform: "capitalize" }} >
              {ArtDetail?.artist ? ArtDetail?.artist : "No User Exist"}
            </span>
          </h2>
        </div>
        <div className="test-row  m-0 pb-5" style={{ background: 'rgb(240,238,236)' }}>



          {
            data?.length > 0 ?
              data?.map((item, i) => {
                const descriptionWords = item.description.split(' ');
                const Description = descriptionWords.slice(0, 2).join(' ');


                return (



<div className="test-card">
    <div
                      className="card galleryDetail-card" key={i}
                      style={{
                        boxShadow: "3px 2px 6px #bab8b8",

                        borderRadius: "15px",
                        overflow: 'hidden',

                      }}
                    >
                      <div className='image-card-height' style={{ overflow: 'hidden', }} >
                        <img
                          className='img-fluid'
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            cursor: 'pointer',
                          }}

                          src={item.image ? item?.image[0] : "No User Exist"}
                          alt="Not data"
                          onClick={() => detailsPage(item)}
                        />
                      </div>

                      <div className="p-2 d-flex align-items-center">
                        <div className="">
                          <h6 className='p-0' style={{ fontWeight: '700', color: '#000000', fontSize: "14px", lineHeight: "16.94px", textTransform: 'capitalize' }}>
                            {item.title ? item.title : 'No User Exist'}
                          </h6>

                          <p className=' m-0' style={{ fontFamily: 'inter', color: '#6896a1' }}>{Description}</p>
                        </div>
                        <div className='col text-end'>

                        </div>
                      </div>
                    </div>
</div>
                  
                   
                
                  

                 
                )








              }) :
            ''

          }
        </div>
      </div>

      <ModalHandler
        closeModal={loginModalScreen}
        ModalIsOpen={newLoginModal}
        screenshift={screenshift}
        shiftCountplus={shiftCountplus}
        shiftCountminus={shiftCountminus}
        restscreenplus={restscreenplus}
        restscreenminus={restscreenminus}
        interestscreenplus={interestscreenplus}
        interestscreenminus={interestscreenminus}

      />
    </>
  )
}

export default GalleryPageDetails