import React,{useState,useEffect, useRef} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import { useNavigate,useLocation } from 'react-router-dom'
import {  getAllSound, getMusicDetails, getUserArts, getUserSound } from '../../redux/features/auth/authSlice'
import { baseURL } from '../../redux/axios/axios'
import ModalHandler from "../../Screens/newloginflow/ModalHandler";
import imgMsg from '../../assets/images/cardImage3.png'
import '../../Styles/diskdesign.css'
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import StarIcon from '@mui/icons-material/Star';
import AttachMoneySharpIcon from '@mui/icons-material/AttachMoneySharp';

import MessageIcon from '@mui/icons-material/Message';
import { AudioPlayer } from '../audioPlayer/AudioPlayer'
import axios from 'axios'
import DonateModal from '../Music/DonateModal'
import MyFavouriteDropDown from '../UploadArt/MyFavouriteDropDown'
import AddFvrtMusicModal from '../Music/AddFvrtMusicModal'
import { toast } from 'react-toastify'
import DonateThankModal from '../Music/DonateThankModal'
import CustomPlayer from '../audioPlayer/CustomrPlayer'
import PlayerForAllUserMusic from '../audioPlayer/PlayerForAllUserMusic'
import headerImage from '../../assets/images/headerImage.png'
import SinglePlayer from '../audioPlayer/SinglePlayer'


const MusicPageDetails = () => {


  const [showLoginModal, setShowLoginModal] = useState(false);

  const location = useLocation();

  let musicId = location.state?.id;

  const [screenshift, setscreenshift] = useState(1)
  
  const [newLoginModal, setnewLoginModal] = useState(false)


  let login = JSON.parse(localStorage.getItem('user'));
  
 const [downloadLoading, setDownloadLoading] = useState(false);

    const [data,setData] = useState()
    console.log("🚀 ~ MusicPageDetails ~ data:", data)
    const [searchQuery, setSearchQuery] = useState('')
    const [MuiscDetail, setMusicDetail] = useState()
    const [musicPlayToggle, setmusicPlayToggle] = useState({
      id:'',
      status:''
    })
    const {userList} = useSelector((state)=>state.auth)
   

 

 const dispatch = useDispatch()
 const navigate = useNavigate()


 const closeLoginModal = () => {
  setShowLoginModal(false);
};

const donatePage = (item) => {
  if (login) {
    const queryParams = new URLSearchParams({
      art_id: item?._id,
    });

    navigate(`/donate?${queryParams}`, {
      state: {
        title: item.title,
        description: item.description,
        image: item.image,
        art_id: item?._id,
        user_id: item?.owner_id,
      },
    });
  }
  if (!login) {
   loginModalScreen()
  }
};
const owner_id = MuiscDetail?.owner_id;



const [musicBitRate, setMusicBitRate] = useState(0);





useEffect(() => {
    const getDetail = async () => {
  const val = await dispatch(getMusicDetails(musicId ));
  const MusicData = val.payload.data;
  setMusicDetail(MusicData);
  calculateMusicDuration(val?.payload?.data?.file?.[0])
};
  getDetail();
}, [musicId,dispatch]);



const [sampleRate, setSampleRate] = useState(null);
console.log("🚀 ~ MusicPageDetails ~ sampleRate:", sampleRate)

const calculateMusicDuration = async(audioFile) => {

  if (audioFile) {
    const audio = new Audio(audioFile);
    audio.addEventListener('loadedmetadata', async() => {
      const duration = audio.duration;
       const fileSizeBits = duration * 8; 
      setMusicBitRate(fileSizeBits / 1000 );

      // Calculate sample rate
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContext.createBufferSource();
      source.connect(audioContext.destination);
      const channels = source.channelCount;
   
 const audioFormat = `16 Bit ${channels === 1 ? 'Mono' : 'Stereo'}, ${audioContext?.sampleRate  / 1000} KHz`;
setSampleRate(audioFormat);
    
    });

   
  }
};


useEffect(() => {
    const getUserArtsData = async () => {
  if (owner_id) {
    const val = await dispatch(getUserSound({ user_id: owner_id }));
    const userData = val.payload.data;
    const filteredData=userData.filter((element)=>element.visibility==='public')
    setData(filteredData);
  }
};
  getUserArtsData();
}, [owner_id,dispatch]);

const [musicData,setMusicData] = useState([])
 const [openModalFvrt, setopenModalFvrt] = useState(false)
const audioRefs = useRef({});
const [activeId, setActiveId] = useState(null);
  

   const [openModal, setopenModal] = useState(false)
  
    
   const open = (item) =>{
    if(login){
      setMusicData(item)
    setopenModal(!openModal) 
    } 
    else {
      toast.error('Please login for Donation',{autoClose:2000}); 
    }
   
   } 

   const openFvrt = (item) =>{
    if (login) {
      setMusicData(item);
      setopenModalFvrt(!openModalFvrt);
    } else {
      toast.error('Please login to store music in your collection.',{autoClose:2000}); 
    }
   } 
  
  
      const searchData = () =>{
  
       
        const filteredData = userList?.arts?.data?.filter((item) => {
          const titleMatch = item.title.toLowerCase().includes(searchQuery.toLowerCase());
          const descriptionMatch = item.description.toLowerCase().includes(searchQuery.toLowerCase());
          return titleMatch || descriptionMatch;
        });
         setData(filteredData);
        
      }
  
   
  
    const handleSearch = () =>{
      searchData()
    }

    const details = async (item) => {  
      navigate("/music-details", { state: { id:item._id } });
      await dispatch(getMusicDetails(item._id))
            window.scrollTo(0, 0);
    };

    
const MoveToMessageScreen=()=>{
  
  if(!login){
   loginModalScreen()
    
  }else{
    navigate('/dashboard/user-dashboard',{ state: { eventKey: "sixth", user_id:owner_id } })
   

  }

}

function loginModalScreen() {
  setscreenshift(1)
  setnewLoginModal(!newLoginModal)
}



function shiftCountplus() {
       
  setscreenshift(screenshift+1)
 
}
function shiftCountminus() {
 
  setscreenshift(screenshift-1)
 
}
function restscreenplus(){
  setscreenshift(screenshift+2)
}
function restscreenminus(){
  setscreenshift(screenshift-2)
}
function interestscreenplus(){
  setscreenshift(screenshift+2)
}
function interestscreenminus(){
  setscreenshift(screenshift-2)
}

const  moveToUserDetail=(id)=>{
  navigate('/profile-for-main',{state:{id:id}})
 }



 const download = async (soundId,soundTitle) => {
    try {
      setDownloadLoading(true);

  
      const response =  await axios.post(`${baseURL}/sound/sounds/download`, {
        soundIds: [soundId],
        }, {
          responseType: 'blob',
         
        });
   
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${soundTitle}.zip`; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      
    } catch (error) {
      console.error('Failed to download sounds:', error);
    } finally {
      setDownloadLoading(false);
    }
  };


    const [musicDuration,setMusicDuration] = useState()
   
    const minutes = Math.floor(musicDuration / 60);
    const seconds = Math.floor(musicDuration % 60);
    const timeSpan = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

    

const getMusicDuration = (item) => {
  setMusicDuration(item)
}




 


 

  return (
   <>


    <DonateModal
        closeModal={open}
        ModalIsOpen={openModal}
        musicData={musicData}
      />

<AddFvrtMusicModal
        closeModal={openFvrt}
        ModalIsOpen={openModalFvrt}
        selectedCheckboxes={musicData}
      />


    

    <div
        className="col text-center background-image"
        style={{
          backgroundImage: `url('${headerImage}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '500px',
          borderTop: '15px solid white',
          borderLeft:'15px solid white' ,
          borderRight:'15px solid white' ,
         
        
        }}
      >
        <div className="container" style={{ borderRadius: '0 20px 20px 0'}}>
          <div className="row justify-content-center"  >

          </div>
        </div> 
      </div>


      <div className="container-fluid" style={{backgroundColor: "#f5f5f5",}}>
        <div className="row mb-5"  >
        <div className="col-md-6 col-sm-12 d-flex  justify-content-center imageparent" style={{  }}>
            <div className='w-100'>
           <div className="w-100 d-flex justify-content-center" style={{marginBottom:'1rem',background:'#F0EEED',height:'70%',position: "relative" }}>
  
             <div className="disk-container-music mt-4 img-fluid"  style={{backgroundImage:`url(${MuiscDetail?.image?.length > 0 ? MuiscDetail?.image[0]:imgMsg}`,position:'absolute'}}>
                      <div className="white-center-music"></div>  
               
                        </div> 
                        <div className="col-md-12 col-sm-12 img-music d-flex justify-content-center ">
                                     {/* <audio className='w-75 img-music-player '  src={MuiscDetail?.file?.length > 0 ? MuiscDetail?.file[0] : ""} controls></audio> */}
                                   {  MuiscDetail  && <SinglePlayer 
                                   musicPlayToggle={musicPlayToggle}
                                   setmusicPlayToggle={setmusicPlayToggle}
                                   detailpageStyle={true}
                                   sendToParent={getMusicDuration}
                              audiodata={[MuiscDetail]}
                           />
}
                            </div> 
                            
               

</div> 

 </div>     
            </div>
 
 



            <div className="col-md-6 col-sm-12 ps-5 pt-4">
                <div className="d-flex align-items-center">
                  <div className='d-flex w-100'>
                    <h2 style={{fontWeight:'800', fontSize:"26px", color:"#000000" ,textTransform:"capitalize"}}>
                       {MuiscDetail?.title}
                         <span className='ps-1 fw-bold' style={{textTransform:"capitalize", cursor:"pointer"}}>-</span> <span style={{textTransform:"capitalize",color:'#719CA9',cursor:'pointer'}}
                         onClick={() => moveToUserDetail(MuiscDetail.owner_id)}
                         className='fw-bolder'>
                            {MuiscDetail?.owner_name}
                        </span>
                    </h2>
                  </div>
                    
                  
                </div>
                <div className="row">
                    <h4 style={{fontWeight:'800', fontSize:"18px", color:"#000000"}}>
                       {MuiscDetail?.mood} / {MuiscDetail?.genre} / {MuiscDetail?.instrument}
                    </h4>
                </div>
                <div className="row pt-4 mt-2 mb-2 pe-5">
                    <p className='pe-5' style={{fontWeight:'500', fontSize:"16px", color:'black' ,textTransform:"capitalize",textAlign:'justify'}}>
                      {MuiscDetail?.description ? MuiscDetail?.description : "No User Exist"}
                    </p>
                </div>

               


           <div className="border-start px-2">
            <div className="row">
                    <h6 style={{fontWeight:'700'}}>
                        Main track length
                    </h6>
                </div>
                <div className="row">
                    <h6 style={{fontWeight:'700',textTransform:""}}>
                      {timeSpan} 
                    </h6>
                </div>

                <div className="row pt-2">
                    <h6 style={{fontWeight:'700'}}>
                        Bit rate
                    </h6>
                </div>
                <div className="row">
                    <h6 style={{fontWeight:'700',textTransform:"capitalize"}}>
                    {musicBitRate.toFixed(2)} kbps
                    </h6>
                </div>

                <div className="row pt-2">
                    <h6 style={{fontWeight:'700'}}>
                        Sample rate
                    </h6>
                </div>
                <div className="row">
                    <h6 style={{fontWeight:'700'}}>
                    {sampleRate ? sampleRate : 'Calculating...'}
                    </h6>
                </div>

                <div className="row pt-2">
                    <h6 style={{fontWeight:'700'}}>
                        Vocals
                    </h6>
                </div>
                <div className="row">
                    <h6 style={{fontWeight:'700',textTransform:"capitalize"}}>
                       {MuiscDetail?.vocals}
                    </h6>
                </div>
           </div>
                
          
  <div className="col-md-4 col-sm-4 mt-md-4 ">
    {downloadLoading ? (
                  /* You can replace this with your own spinner component */
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (<>
                
               <Button className='ps-4 pe-5 text-white btn-down rounded-3 fw-semibold custom-button justify-content-start' 
    onClick={() => { download(MuiscDetail?._id, MuiscDetail?.title) }}
    variant="contained" startIcon={<DownloadIcon />} sx={{textTransform:'capitalize',background:'rgb(112, 155, 165)'}}>
      {downloadLoading ? 'Downloading...' : 'Download'}
    </Button>
                   <p className="ps-2  mb-0"></p>
                </>
                 
                )}
   
  </div>
  <div className="col-md-4 col-sm-4 mt-md-4 my-sm-3 btn-top">
    <Button 
    onClick={()=>openFvrt(MuiscDetail?._id)}
    className='ps-4 pe-5 text-white btn-down rounded-3 fw-semibold custom-button justify-content-start' variant="contained" startIcon={<StarIcon />} sx={{textTransform:'capitalize',background:'rgb(112, 155, 165)'}}>
      Add to collection
    </Button>
  </div>
     
     
  <div className="col-md-4 col-sm-4 mt-md-4 my-sm-3 btn-top">
  {MuiscDetail?.owner_id === login?.user[0]?._id ?  '' : 
   ( <Button 
    disabled={MuiscDetail?.donation === 'none'}
    onClick={()=>open(MuiscDetail)}
    className='ps-4 pe-5 text-white btn-donate btn-down rounded-3 fw-semibold custom-button justify-content-start' variant="contained" startIcon={<AttachMoneySharpIcon />} sx={{textTransform:'capitalize',background:'#56DB99'}}>
      Donate
    </Button>  )

                }
  </div>


            </div>
        </div>



<div className="row ps-4 pt-4 ">
                 
                    <h2 style={{fontWeight:'700', fontSize:"36px"}}>
                        More from <span style={{color:'#719CA9',textTransform:"capitalize",fontWeight:'700'}} >
                            {MuiscDetail?.owner_name?.length > 0 ? MuiscDetail?.owner_name : "No User Exist"}
                        </span>
                    </h2>
                </div>
        <div className="row  m-0 pb-5" style={{background:'rgb(240,238,236)'}}>
        


                {
                  
               
       
            
           
  data && 
   data?.length > 0 ? 
  <PlayerForAllUserMusic

  musicPlayToggle={musicPlayToggle}
  setmusicPlayToggle={setmusicPlayToggle}
  detailsPage={(item)=>details(item)}
audiodata={data}
/>

                 


                 : 
                ''
                 
                 
             
           }
           
        </div>
      </div>

      <ModalHandler 
       closeModal={loginModalScreen}
       ModalIsOpen={newLoginModal}
       screenshift={screenshift}
       shiftCountplus={shiftCountplus}
       shiftCountminus={shiftCountminus}
       restscreenplus={restscreenplus}
       restscreenminus={restscreenminus}
       interestscreenplus={interestscreenplus}
       interestscreenminus={interestscreenminus}

      />
   </>
  )
}

export default MusicPageDetails