import React, { useEffect, useState } from 'react';
import './stepper.css';
import { ArtStyleCard } from './ArtStyleCard';
import { MusicStyleCard } from './MusicStyleCard';
import "../../Styles/SignupModal.css"
import font from "../../assets/images/font.PNG"
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, registerUser } from '../../redux/features/auth/authSlice';
export const Stepper = ({closeModal,userData}) => {
    console.log("🚀 ~ file: Stepper.jsx:9 ~ Stepper ~ email:", userData)
    // const { showinterest, handleClose, loginClose } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [isArtStyleCardClicked, setIsArtStyleCardClicked] = useState(false);
  console.log("🚀 ~ Stepper ~ isArtStyleCardClicked:", isArtStyleCardClicked)
const {isLoading}=useSelector((state)=>state.auth)

const [stylesList, setstylesList] = useState([])
const [musicList, setmusicList] = useState([])
console.log("🚀 ~ Stepper ~ musicList:", musicList?.length)
const dispatch=useDispatch()
  const handleStepClick = (index) => {
    setActiveStep(index);
  };


  const handleNext = () => {
    if (stylesList?.length<5) {
      toast.error("Please select atleast 5")
      console.log("err");

    }else if(activeStep < 2 && stylesList?.length>=5 && musicList?.length===0) {
      setActiveStep(activeStep + 1);
      setIsArtStyleCardClicked(false)
      console.log("1");

    }else if(activeStep < 2 && stylesList?.length>=5 && musicList?.length<5){
      toast.error("Please select atleast 5")
      console.log("err");

    }
    else if(activeStep < 2 && stylesList?.length>=5 && musicList?.length>=5 )
    {
      setActiveStep(activeStep + 1);
      console.log("2");

    }

  };
  const getStyles=(items)=>{
setstylesList(items)
  }
 
  const getMusics = (items)=>{
    setmusicList(items)
  }

  const handlePrevious = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleArtStyleCardClick = () => {
    setIsArtStyleCardClicked(true);
  };

  const intrestDone=async()=>{
    dispatch(registerUser({
      ...userData,
      music_interest:musicList,
      art_interest:stylesList
    }))
    .then((response)=>{
      console.log("response",response?.payload);
      if(response.payload.success===true){

         closeModal()
    toast.success('verification e-mail sent to your account',{autoClose:1000})
      }

    })
   
  }

  return (
    <>
    <div className="d-flex justify-content-center">
 <div className="d-flex justify-content-center rounded-3"  
      style={{width:'888px'}}
      >
  <div className=""
    
      >
        <div className="accordion w-100" id="accordionExample">
          <div className="steps p-0 mt-4">
            <progress id="progress" value={(activeStep / 2) * 100} max={100}></progress>
            <div className="step-item m">
              <div
                className={` step-button px-4 me-3   text-center ${activeStep >= 0 ? 'done' : ''}`}
               
                
                // data-bs-toggle="collapse"
                // data-bs-target="#collapseOne"
                aria-expanded={activeStep === 0 ? 'true' : 'false'}
                // aria-controls="collapseOne"
              
              >
                
              </div>
             
            </div>
            <div className="step-item ms-2 me-2">
              <div
                className={`step-button px-4 ms-3  text-center ${activeStep >= 1 ? 'done' : ''}`}
               
                // data-bs-toggle="collapse"
                // data-bs-target="#collapseTwo"
                aria-expanded={activeStep === 1 ? 'true' : 'false'}
                // aria-controls="collapseTwo"
                
              >
                
              </div>
             
            </div>
            <div className="step-item ms-2 me-2">
              <div
                className={`step-button px-4 ms-4 text-center ${activeStep >= 2 ? 'done' : ''}`}
                
                // data-bs-toggle="collapse"
                // data-bs-target="#collapseThree"
                aria-expanded={activeStep === 2 ? 'true' : 'false'}
                // aria-controls="collapseThree"
                
              >
                
              </div>
             
            </div>
          </div>

            <div id="collapseOne" className={`collapse  ${activeStep === 0 ? 'show' : ''}`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
             
                <div className='text-center mt-4'>
                    <h3 style={{color:"#709BA5", fontWeight:"600"}}>What Art Style are you interested in?</h3>
                    <p style={{color:"#709BA5", fontWeight:"400"}}>This will customize your home feed</p>
                    
                </div>
          <div className="my-4">
               <div style={{padding:"0 50px"}}  onClick={handleArtStyleCardClick} type='button'>
               <ArtStyleCard   sendstyleToParent={getStyles} />
              </div>
          </div>
            
             
              
            </div>
        
      
            <div id="collapseTwo" className={`collapse ${activeStep === 1 ? 'show' : ''}`} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className='text-center mt-4'>
                    <h3 style={{color:"#709BA5", fontWeight:"600"}}>What Music Style are you interested in?</h3>
                    <p style={{color:"#709BA5", fontWeight:"400"}}>This will customize your home feed</p>
                    
                </div>
               <div className="my-4">
                   <div style={{padding:"0 50px"}}  onClick={handleArtStyleCardClick} type='button'>
                <MusicStyleCard sendmusicToParent={getMusics}/>
                </div>
               </div>
             
               
              </div>
         
        <div className="d-flex justify-content-center w-100 my-2">
             {/* {activeStep >= 1 &&  activeStep < 2 && ( 
                <>
                <button className='btn px-4' style={{background:'#709BA5' , color:'white'}} onClick={handlePrevious} disabled={activeStep === 0}>Previous</button>
                </> 
                        
               )} */}

              {activeStep < 2 && ( 
                <>
                       <button className='btn   mx-5 w-100 rounded-pill' style={{background:'#709BA5' , color:'white'}} onClick={handleNext} disabled={!isArtStyleCardClicked} >Pick 5 More</button>
                </> 
                        
               )}
                 
        </div>
       

          

       
            <div id="collapseThree" className={`collapse w-100  ${activeStep === 2 ? 'show' : ''}`} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            
                <h3 className='text-center mt-4' style={{color:"#709BA5", fontWeight:"600"}}>Welcome to ArtActually</h3>
                <div className='mt-3 justify-content-center rounded'>
                  <img src={font} alt="" style={{width:'882px'}} className='img-fluid rounded-4 ' />
   
<br/>
                <div className='col-md-12 mt-3 d-flex justify-content-center'>
                  <div className='container'>
   <button className='btn btn-info border-0 text-center col-md-9 rounded-pill w-100 my-2' 

                      onClick={intrestDone}
                      disabled={isLoading}
                      style={{background:'#709BA5' , color:'white',}}
                      >
                        {isLoading? "Please Wait...":'Done'}</button>
                </div>
                  </div>
                   
              
           
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
 
{/* <div className={showinterest ? "modal-show-compare display-block" : "modal-show-compare display-none"} style={{marginTop:'30px'}}>
    
      </div> */}

    
    </>
  );
};
