import React, { useState, useEffect } from "react";
import loginLogo from "../../assets/images/loginLogo.png";
import doorImage from "../../assets/images/doorImage.png";
import './loginimage.css'
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { SendGoogleDetails, getUserDetails, loginUser } from "../../redux/features/auth/authSlice";
// import google from '../../assets/google.PNG'
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode'


const NewLogin = ({ Restpasswordscreenplus, signupscreenPlus, closeModal }) => {


  const [errors, setErrors] = useState({});
  const [rememberMe, setRememberMe] = useState(false);

  const [data, setData] = useState({
    email: "",
    password: "",
  });

const {isLoading}= useSelector((state)=>state.auth)


  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear the error when input changes
  };


  useEffect(() => {
    const storedUserEmail = Cookies.get('userEmail');
    const storedPassword = Cookies.get('userPassword');

    if (storedUserEmail && data.email === "" && storedPassword && data.password === "") {
      setData({
        ...data,
        email: storedUserEmail,
        password: storedPassword,
      })


    }
  }, [data]);

  const validateInputs = () => {
    let isValid = true;
    const updatedErrors = {};

    if (!data.email) {
      updatedErrors.email = "Email is required";
      isValid = false;
    }

    if (!data.password) {
      updatedErrors.password = "Password is required";
      isValid = false;
    }

    setErrors(updatedErrors);
    return isValid;
  };

  const navigate = useNavigate();



  const dispatch = useDispatch();
  const login = async () => {
    if (!validateInputs()) {
      return;
    }

    if (validateInputs) {
      const res = await dispatch(loginUser(data));
      const val = res.payload;
      try {
        if (val?.success === true) {
          if (val?.Role?.title === "user") {
            toast.success(val?.message, {
              position: "top-center",
              autoClose: 1000,
            })

            //   handleClose()
            localStorage.setItem("user", JSON.stringify(val));
            navigate("/dashboard/user-dashboard");
          }
          if (val?.Role?.title === "admin") {
            toast.success(val?.message, {
              position: "top-center",
              autoClose: 1000,
            })

            //   handleClose()
            localStorage.setItem("user", JSON.stringify(val));
            navigate("/dashboard/admin-dashboard");
          }
        }
        if (val?.success === false) {
          console.log('get message', val?.message);
          if (val?.message === 'Your account is vip but you have not pay fee') {

            const getUserDetail = async () => {
              await dispatch(getUserDetails({ email: data.email }))
                .then((res) => {
                  console.log("userDetail", res?.payload?.user[0]);
                  navigate(`/payment-plans/${res?.payload?.user[0]?._id}`)
                })
            }

            getUserDetail()
          } else {
            toast.error(val?.message, {
              autoClose: 1000,
              position: "top-center",
            });
          }

        }
        if (rememberMe) {
          Cookies.set('userEmail', data.email, { expires: 30 })
          Cookies.set('userPassword', data.password, { expires: 30 })
        }
        if (!rememberMe) {
          Cookies.remove("userEmail")
          Cookies.remove("userPassword")
        }

      } catch (error) {
        toast.warn(error, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    }
  };

  const move = () => {
    navigate('/Terms&Use')
    closeModal()
  }
  const privacy = () => {
    navigate('/privacy-policy')
    closeModal()
  }





  const credentialResponse = async (res) => {
    const decodedData = await jwtDecode(res.credential);
    const resp = await dispatch(SendGoogleDetails({
      email: decodedData?.email,
      firstName: decodedData?.given_name,
      lastName: decodedData?.family_name,
      image: decodedData?.picture
    }));

    const updatedData = resp.payload;
    const roleData = updatedData.Role;
    const userData = updatedData.user;
    const token = updatedData.token;
    const finalData = { Role: roleData, user: [userData], token: token }
    localStorage.setItem('user', JSON.stringify(finalData))
    navigate("/dashboard/user-dashboard");

  };



  return (

    <>
      <div class="h-100 login-row  justify-content-center">


        <div className="login-text p-0 row justify-content-center" style={{minWidth:'50%'}}>
          <div className="d-flex justify-content-start p-0 m-0 ">
            <div
              className="d-flex align-items-start mt-2 ms-4"
              style={{
                height: "33px",
                width: "33px",

                padding: "4px",
                paddingBottom: "2px",
                borderRadius: "5px",
                textAlign: "center",
                marginLeft: "3px",
              }}
            >
              <FontAwesomeIcon
                icon={faXmark}
                className="fs-4 ps-1"
                onClick={() => {
                  //   handleClose();
                  closeModal()
                }}
                style={{ color: "#709AA4", cursor: "pointer" }}
              />
            </div>


          </div>
          <div className="p-0 m-0">

          </div>
          <div className="col-md-9">
            <div className="px-2 py-2 rounded-start">
              <div className="d-flex justify-content-center">


                <h4
                  className="text-center fw-bold"
                  style={{ color: "#709DA7" }}
                >
                  Welcome Back to
                </h4>
              </div>
              <div className="d-flex justify-content-center py-3">
                <img
                  src={loginLogo}
                  alt=""
                  style={{ maxWidth: "200px" }}
                />
              </div>

              <div>
                <div className="py-2">
                  <div className="form-group py-2 mt-2">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      value={data.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p className="error text-danger text-start pt-1 ps-2">
                        <small> {errors.email}</small>
                      </p>
                    )}
                  </div>
                  <div className="form-group mt-2">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      name="password"
                      value={data.password}
                      onChange={handleChange}
                      autoComplete="none"
                    />
                    {errors.password && (
                      <p className="error text-danger text-start pt-1 ps-2">
                        <small> {errors.password}</small>
                      </p>
                    )}
                  </div>
                  <div className="d-flex py-2 d-flex align-items-center mt-2 justify-content-between w-100">
                    <div className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        style={{ fontSize: "14px" }}
                        className="form-check-input"
                        id="flexCheckDefault"
                        checked={rememberMe}
                        onChange={() => {
                          setRememberMe(!rememberMe);
                        }}
                      />
                      <span
                        className="text-cadet px-2 "
                        style={{ fontSize: "14px", color: "#709DA7", fontWeight: '700' }}
                        htmlFor="flexCheckDefault"
                      >
                        Keep me logged in
                      </span>

                    </div>
                    <a
                      href="#"
                      className="text-decoration-none  fw-bold"
                      style={{ fontSize: "14px", color: "#709DA7" }}
                      onClick={() => {

                        //   handleshowRestPassword()
                        Restpasswordscreenplus()

                      }}
                    >
                      Forgot Password
                    </a>
                  </div>
                </div>
              </div>

              <div className="d-grid text-center">
                <button
                  type="button"
                  disabled={isLoading}
                  style={{ backgroundColor: "#709DA7", color: "white", fontWeight: '600' }}
                  className="btn  py-1"
                  onClick={login}
                >
                  {isLoading?"Please wait":'Log in'}
                  
                </button>
                <p className="text-cadet mt-2" style={{ color: "#709DA7" }}>
                  OR
                </p>
              </div>

              <div className="mb-2 rounded-circle d-grid text-center justify-content-center" style={{ cursor: 'pointer' }}>
                {/* <img src={google} alt="" className="img-fluid" /> */}


                <GoogleOAuthProvider clientId="591998037911-djuc6q295g0t3k4gtooc8mn99e89g8sa.apps.googleusercontent.com">
                  <GoogleLogin
                    onSuccess={(res) => credentialResponse(res)}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  />

                </GoogleOAuthProvider>

              </div>

              <div className="mt-2 text-center">
                <span
                  className=" text-cadet font-18 px-2"
                  style={{ color: "#709DA7" }}
                >
                  Don't have an account yet?
                </span>
                <a
                  href="#"
                  className="text-decoration-none text-dark fw-bold"
                  onClick={signupscreenPlus}
                >
                  Sign up
                </a>
              </div>

              <p
                className="text-cadet text-center mt-2"
                style={{
                  fontSize: "12px",
                  color: "#709DA7",
                  textAlign: "center",
                }}
              >
                By continuing, you agree to ArtActually's <span style={{ fontWeight: '700', cursor: 'pointer' }} onClick={move}> Terms of Services </span>
                <br /> and acknowledge you've read our <span style={{ fontWeight: '700', cursor: 'pointer' }} onClick={privacy}>Privacy Policy</span> .
                Notice at collection.
              </p>
            </div>

          </div>



        </div>
        <div className="login-image p-0 loginImage">



          <div className=' ' style={{
            // width: "19.5rem",
            width: '100%',
            height: "100%",

            overflow: 'hidden', // Ensure that content beyond the card size is hidden
          }}>
            <img
              className='img-fluid'
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                cursor: 'pointer',
              }}
              src={doorImage}
              alt="Not data"

            />
          </div>
        </div>

      </div>
    </>
  )
}

export default NewLogin