

import {
    Box,
    IconButton,
    Typography,
    Button,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import Modal from "react-modal";
import NewSingUp from "./NewSingUp";
import NewResetPassword from "./NewResetPassword";
import NewLogin from "./NewLogin";
import { Stepper } from "../../Components/stepperForSignup/Stepper";

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      maxWidth: "90%",
      maxHeight:'90%',
      transform: "translate(-50%, -50%)",
      padding:'0px',
    // overflow:'hidden',
    borderRadius:'20px'    
      
    },
  };
  
  const ModalHandler = ({ ModalIsOpen, closeModal,
    screenshift,restscreenminus,
    interestscreenminus,interestscreenplus,
    restscreenplus,shiftCountminus,shiftCountplus }) => {
const [userDAta, setuserDAta] = useState()

  const getuseruserDAta= (data)=>{
setuserDAta(data)
  }



  
    return (
      <Box >
        <Modal
          isOpen={ModalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
     
  {
    screenshift===1?
     (


<NewLogin signupscreenPlus ={shiftCountplus}  Restpasswordscreenplus={restscreenplus} closeModal={closeModal} />
    )
    :
    screenshift===2 ?
    (
<NewSingUp screenPlus ={shiftCountplus}  
screenMinu={shiftCountminus} 
closeModal={closeModal} 
steppershow={interestscreenplus}
setuseremail={getuseruserDAta}
/>
    )
    : screenshift===3 ?
    (
<NewResetPassword screenMinu={restscreenminus}  closeModal={closeModal}/>
    )
    :screenshift===4 ?
    (
<Stepper closeModal={closeModal} screenMinu={shiftCountminus} userData={userDAta}/>
    )
    :
    ('')
  }
          
        
        </Modal>

        {/* <NewSingUp 
       closeModal={signupModalScreen}
       ModalIsOpen={signupModal}
       closeLogin={closeModal
    }
      /> */}
      </Box>
    );
  };
  
  export default ModalHandler;
  