import React from 'react'
import audio1 from '../../assets/AudioImages/audio-1.jpg'
import audio2 from '../../assets/AudioImages/audio-2.jpg'
import audio3 from '../../assets/AudioImages/audio-3.jpg'
import audio4 from '../../assets/AudioImages/audio-4.jpeg'
import audio5 from '../../assets/AudioImages/audio-5.jpg'

const AvailableImages = ({SendAvailabelImagetoParent,backToDetailPage}) => {
const images = [audio1,audio2,audio3,audio4,audio5]

const handleImage = (img) => {
console.log("🚀 ~ file: AvailableImages.jsx:12 ~ handleImage ~ img:", img)
SendAvailabelImagetoParent(img)
backToDetailPage()
}
  return (
   <>
   <div className="row justify-content-center">
    <div className="col-11 m-0 p-0">
        <div className="row p-0 m-0 justify-content-center">
{images.map((img)=>{
    return(
        <div className="col-3 m-3 card" onClick={()=>handleImage(img)}>
            <div className="card-body p-1 row justify-content-around align-items-center">
                <img src={img} alt="" />
            </div>
        </div>
    )
   })

   }
        </div>
    </div>
     
   </div>
  
   </>
  )
}

export default AvailableImages