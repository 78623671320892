import React, { useEffect, useState } from 'react';
import profile from "../../assets/images/cardImage3.png";
import { useDispatch, useSelector } from 'react-redux';
import { addUserArt, getUserArts } from '../../redux/features/auth/authSlice';
import { toast } from 'react-toastify';

import '../../Styles/visibililtyscreen.css'
const UserUloadArtVisibility = ({ addArt, close, backPage }) => {

  const User = JSON.parse(localStorage.getItem('user'))

  const user_id = User?.user[0]?._id

 
  const { isLoading } = useSelector((state) => state.auth)
  const [visibility, setVisibility] = useState('private');
  console.log("🚀 ~ UserUloadArtVisibility ~ visibility:", visibility)
  const [donation, setDonation] = useState('none');
  console.log("🚀 ~ UserUloadArtVisibility ~ donation:", donation)
  const [uploadedArtCount, setUploadedArtCount] = useState(0);

  const dispatch = useDispatch()

      const handleVisibilityChange = (event) => {
        const selectedVisibility = event.target.value;
        setVisibility(selectedVisibility);
  
        if (selectedVisibility === 'private') {
          setDonation('none');
        }
        if (selectedVisibility === 'everyone') {
          setDonation('everyone');
        }
      };

  const handleDonationChange = (event) => {
    setDonation(event.target.value);
  };


  const getData = async () => {
    await dispatch(getUserArts({
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      user_id,
    }))
  }

  useEffect(() => {
    const getDataForCount = async () => {
      const data = await dispatch(getUserArts({ user_id: user_id }));
      setUploadedArtCount(data?.payload?.data?.length)
    };
    getDataForCount();
  }, [user_id, dispatch]);


  const Public = async () => {
    const val = uploadedArtCount + 1;
    setUploadedArtCount(val);
     
 
    const formData = new FormData();

    for (const key in addArt) {
      formData.append(key, addArt[key]);
    }
     
    formData.append("visibility", visibility);
    formData.append("donation", donation);

   
    await dispatch(addUserArt(formData))
      .then((res) => {
        getData()
        if (User?.user[0].vip === "true") {
          toast.success(res?.payload?.message, {
            autoClose: 1000
          })
        }

        close()
      })
}

  





  return (
    <>

      <div className="visbilityview card-body pt-0 my-5">
        <div className="w-100">
          <div className="detail-title mt-3 card-body">
            <p className='fw-semibold h4 mb-0' style={{ color: '#74A8B0' }}>Visibility</p>
            <p className='mb-0 fw-bold' style={{ color: '#74A8B0' }}>Who can see your artwork</p>
          </div>
          <div className="visibility border rounded-2">
            <div className="form-check my-2 mx-2">
              <input
                className="form-check-input"
                type="radio"
                name="visibility"
                value="private"
                checked={visibility === 'private'}
                onChange={handleVisibilityChange}
              />
              <label className="form-check-label" style={{ color: '#74A8B0' }}>
                Private - Only you
              </label>
            </div>

            <div className="form-check my-2 mx-2">
              <input
                className="form-check-input"
                type="radio"
                name="visibility"
                value="everyone"
                checked={visibility === 'everyone'}
                onChange={handleVisibilityChange}
              />
              <label className="form-check-label" style={{ color: '#74A8B0' }}>
                Public - Everyone
              </label>
            </div>
          </div>

          <div className="detail-title mt-5">
            <p className='fw-semibold h4' style={{ color: '#74A8B0' }}>Donation Setting</p>
            <p className='fw-bold' style={{ color: '#74A8B0' }}>Who can donate your artwork</p>
          </div>

          <div className="visibility border rounded-2">
            <div className="form-check my-2 mx-2">
              <input
                className="form-check-input"
                type="radio"
                name="donation"
                value="everyone"  
                disabled={visibility === 'private'}
                checked={donation === 'everyone'}
                onChange={handleDonationChange}
              />
              <label className="form-check-label" style={{ color: '#74A8B0' }}>
                Everyone
              </label>
            </div>

            <div className="form-check my-2 mx-2">
              <input
                className="form-check-input"
                type="radio"
                name="donation"
                value="none"
             
                checked={donation === 'none'}
                onChange={handleDonationChange}
              />
              <label className="form-check-label" style={{ color: '#74A8B0' }}>
                None
              </label>
            </div>
          </div>
        </div>

        <div className="w-100 ">

        <div className="image w-100 d-flex justify-content-center align-items-center w-100">

<div className="d-flex justify-content-center mb-md-5 mb-sm-2 ms-4">



  <div className=""
    style={{


      height: '350px',
      overflow: 'hidden', // Ensure that content beyond the card size is hidden
    }}
  >
    <div className='' style={{
      // width: "19.5rem",

      height: '350px',
      overflow: 'hidden', // Ensure that content beyond the card size is hidden
    }}>
      <img

        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          cursor: 'pointer',
          position: 'relative'
        }}
        src={addArt?.image ? URL.createObjectURL(addArt?.image) : profile}
        className=" img-fluid  "
        alt="Not data"

      />
    </div>


  </div>


</div>


</div>










         

        </div>
      </div>
      <div className="d-flex justify-content-end  text-end mt-5 border-top">
        <div className="mx-2 pt-2">
          <button className='btn btn-secondary' onClick={backPage}>
            BACK
          </button>
        </div>
        <div className=" pt-2">
          <button className='btn text-white' style={{ backgroundColor: '#4e7e85' }} onClick={Public}>
            {isLoading ? "loading..." : visibility === 'everyone' ? "PUBLIC" : "SAVE"}
          </button>
        </div>
      </div>
    </>
  );
}

export default UserUloadArtVisibility;
