import React from 'react'

const ClientMusicImage = ({clientSelectedImage,backToDetailPage}) => {
    const convertImageToText =  (e) => {
        const file =e.target.files[0]
        clientSelectedImage(file)
        backToDetailPage()
        if(file){

        }
    }

  return (
   <>
   <div className="row justify-content-around">
   <div type="button" className="col-5 mt-1 p-4 ">
          <div className="d-flex justify-content-center"></div>


          <div className="d-flex justify-content-center mt-2 d-flex align-items-center">
            <input
              type="file"
              id="scandoc"
              className="hidden"
              style={{ display: "none" }}
              onChange={convertImageToText}
            />
            <label
              style={{ display: "inline-block", cursor: "pointer", backgroundColor:'#74a8b0',color:'white'}}
              htmlFor="scandoc"
              className="file-input-button btn-outline-dark rounded-2 btn btn-lg"
            >
            Upload Image
            </label>
          </div>


        </div>
   </div>
   </>
  )
}

export default ClientMusicImage