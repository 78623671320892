import React, { useEffect } from 'react'
import './folder.css'
import blogbar from "../../../assets/images/topbarimage.png"
import cardImg1 from "../../../assets/images/cardImage1.png"
import cardImg3 from "../../../assets/images/cardImage3.png";
import cardImg4 from "../../../assets/images/cardImage4.png";

import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from './ArtSlider'
import MusicSlider from './MusicSlider'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { collectionHavingArtsApi } from '../../../redux/features/auth/authSlice';
import { useState } from 'react';
function MyFavouriteArtGallery() {

  const user = JSON.parse(localStorage.getItem("user"));
  const user_id = user?.user[0]._id;
  const { collectionHaveArt, isLoading } = useSelector((state) => state.auth)
  console.log("🚀 ~ file: MyFavouriteArt.jsx:21 ~ MyFavouriteArtGallery ~ collectionHaveArt:", collectionHaveArt)
  const navigate = useNavigate()
  const [countItems, setcountItems] = useState()
  let count;
  console.log("count",count)
  const dispatch = useDispatch()
  const moveToDetail = (folderId,folder) => {

    navigate("/folder-detail",{state:{folderId:folderId,folderName:folder}})
  }


    const value  =async()=>{
      const data =await dispatch(collectionHavingArtsApi({ user_id: user_id }))
      console.log("🚀 ~ file: MyFavouriteArt.jsx:51 ~ value ~ dataPayload:", data.payload)
    }

  useEffect(() => {
    if (user_id) {
    //  dispatch(collectionHavingArtsApi({ user_id: user_id }))
    value()

    }
  }, [])

  const moveToUserDetail = (id) => {
    navigate('/profile-for-main', { state: { id: id } })
  }
  return (
    < >
      <div style={{
        backgroundImage: `url(${blogbar})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '110px'
      }}>
        {/* Content */}
      </div>

      <div className="container-fluid " style={{ fontFamily: "initial", color: "cadetblue", backgroundColor: "#EDEBEA" }}>
        <div className="ms-4 py-5">
          <h2 className='ms-5 openSans-700' style={{  color: '#6896a1',  }}>My Favourite Art Actually</h2>
        </div>



        {!collectionHaveArt?.data ?
        (
         <p>
          No data
         </p> 
        )
        :
        (  Object?.keys(collectionHaveArt?.data).map((key, index) => (
          <div key={index} className="row justify-content-center my-3 mx-4 pt-2 pb-5" style={{ alignItems: "center", backgroundColor: '#fefefe', marginLeft: "2px", marginRight: "2px", borderRadius: '15px', boxShadow: " 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)" }}>
            <div style={{ textAlign: "end", paddingTop: "8px" }}>
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </div>
            <div className="col-md-2 col-sm-12 d-flex justify-content-center">
              <div>
                <h4 style={{ fontSize: "20px", fontWeight: "600", color: "#709AA4"  }}>{key}</h4>
                <p style={{ fontSize: "16px", fontWeight: "600", color: "#709AA4" }}> {collectionHaveArt?.data[key]?.items?.length} Items</p>
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="row ">
                {isLoading ?
                  (<div class="spinner-grow text-dark" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>)
                  : (
                    collectionHaveArt?.data[key]?.items?.slice(0,3)?.map((item, i) => {
                      const descriptionWords = item.description.split(" ");
                      const Description = descriptionWords.slice(0, 2).join(" ");
                  return  (
                     


<div
    className="col-md-4 col-sm-6 col-sx-12 col-12  m-0 folderlist-cardarea"
    style={{
      borderRadius: "18px",
    
    }}
    key={i}
  >
   <div
  className="card folderlist-card"
  style={{
    // width: "19.5rem",
    boxShadow: "3px 2px 6px #bab8b8",
   borderRadius:'15px',
    overflow: 'hidden',
    backgroundColor:'#F8F8F8', // Ensure that content beyond the card size is hidden
  }}
>
  <div className='folderlist-imagelength'style={{
    // width: "19.5rem",

   
    overflow: 'hidden', // Ensure that content beyond the card size is hidden
  }}>
    <img
      className='img-fluid'
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover', // This will center and crop the image
        cursor: 'pointer', // Add a pointer cursor to indicate it's clickable
      }}
      src={item.image ? item?.image[0] : "No User Exist"}
      alt="Not data"
      // onClick={() => detailsPage(item)}
    />
  </div>

  <div className="p-2 d-flex align-items-center">
    <div className="">
      <h6 className='p-0 ' style={{ fontWeight:'700', color: '#000000', fontSize: "14px", lineHeight: "16.94px" }}>
        {item.title ? item.title : 'No User Exist'}
      </h6>

      <p
                                    onClick={() => moveToUserDetail(item.owner_id)}
                                    className='p-0 m-0'
                                    style={{ fontWeight: '400', textDecoration: 'none', color: '#709BA5', fontSize: "14px", lineHeight: "16.94px" }}
                                  >
                                    by <span className="fw-bold fs-6" style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item?.ownerName}</span>
                                  </p>
    </div>
    <div className='col text-end'>
      
    </div>
  </div>
</div>

  </div>















                    )
                    
})
                  )
                }
              </div>
            </div>
            <div className="col-md-2 d-flex align-items-center justify-content-end pe-4">
            <div>
              <small className='m-0'> <strong style={{  cursor: 'pointer' }} onClick={()=>moveToDetail(collectionHaveArt?.data[key]?.folder_id,key)} >View more</strong> </small> <br />
              <svg onClick={()=>moveToDetail(collectionHaveArt?.data[key]?.folder_id,key)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path style={{ fill: '#6896a1', cursor: 'pointer' }} d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right" /></svg>
              <div>

              </div>



            </div>

            <div>

            </div>
          </div>

          </div>
        ))) 
      
        }
    
  


      </div>

    </>
  )
}


export default MyFavouriteArtGallery
