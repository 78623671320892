import React, { useState, useEffect, useRef } from "react";
import { Table, Row, Col } from "react-bootstrap";
import Pagination from "../Pagination/Pagination";
import { useDispatch,useSelector } from "react-redux";
import { getAllSound } from "../../redux/features/auth/authSlice";
import uploadimg from '../../assets/icons/uploadblog.png'
import TablePagination from "../Pagination/TablePagination";
import { AudioPlayer } from "../audioPlayer/AudioPlayer";
import MainPagePlayer from "../audioPlayer/MainPagePlayer";

const MusicLandingPage = () => {
 

  

  const Sound  = useSelector((state) => state.auth.userArt);
  const {isLoading}  = useSelector((state) => state.auth);

 
    


  const [data, setData] = useState([]);
  const pageSize = 5;
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setpaginatedData] = useState()
 
  const [activeId, setActiveId] = useState(null);
  const audioRefs = useRef({});
   
  
  const dispatch = useDispatch();



  useEffect(() => {
    const getData = async () => {
     const response = await dispatch( getAllSound() );
      setData(response.payload?.data);
     
    };
    getData();
  }, [dispatch]);

useEffect(()=>{
  setData(Sound?.data)
},[Sound])

 

  const paginatedlist=(val) => {
   
     
    
    setpaginatedData(val)
    }





  return (
    <div className="d-flex justify-conter-center">
    

   
       <div className="row w-100">
        <div className="col-md-12">
         {  
         
         
         isLoading ? ( <div class="d-flex justify-content-center pt-4">
         <div class="spinner-border" role="status" style={{color:'#709AA4'}}>
           <span class="sr-only">Loading...</span>
         </div>
       </div>) 
       :
        paginatedData?.length > 0 ? 
(paginatedData &&
  <MainPagePlayer
 audiodata={paginatedData}
/>
)

 : (
<div className="ps-5 pt-5 fs-5 openSans-300 text-muted">No Data Found</div>
)}
         </div>
      
    

     
      {/* <Row className="justify-content-end mt-3">
        <Col md="auto">
          <Pagination
            totalPage={totalPage}
            currentPageValue={changeCurrentPageValue}
            nextPage={handleNextPage}
            previousPage={handlepreviousPage}
            currentItems={currentItems}
            data={data}
          />
        </Col>
      </Row> */}
      <TablePagination list={data} paginatedList={paginatedlist}/>
       </div>
       
         
    </div>
  );
};

export default MusicLandingPage;
