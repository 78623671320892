import React, { useState } from 'react'
import './contact-us.css'
import blogbar from "../assets/images/blogbar.jpg"
import Logo from '../assets/images/loginLogo.png'
import {toast} from 'react-toastify'
import {useDispatch} from 'react-redux'
import {contactUs} from '../redux/features/auth/authSlice'


const Contact = ()=> {


     const dispatch = useDispatch()

   const [contact,setContact] = useState({
    name:'',
    email:'',
    query:''
   })

   

   const [errors, setErrors] = useState({});


   

   const handleChange = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
    setErrors({ ...errors, [name]: '' }); // Clear the error when input changes

  };

  const validateInputs = () => {
  
    let isValid = true;
    const updatedErrors = {};

    if (!contact.name) {
      updatedErrors.name = "Name is required";
      isValid = false;
    }

    if (!contact.email && !validateEmail(contact.email)) {
      updatedErrors.email = contact.email ? 'Please enter a valid email address'
      : 'Email is required';
      isValid = false;
    }

    if (!contact.query) {
      updatedErrors.query = "Query is required";
      isValid = false;
    }

    setErrors(updatedErrors);
    return isValid;
  };


  const validateEmail = (email) => {
     const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      return false;
    }

  };


  const submit = async(e)=>{
    e.preventDefault()
    if (!validateInputs()) {
      return;
    }
   
  
      await dispatch(contactUs(contact))
    .then(()=>{
      toast('Thank you for contacting us',{
        autoClose:1000
      })
    })
   setContact({
    name:"",
    email:'',
    query:""
   })
   
  }

  return (
    < >
       <div style={{
  backgroundImage: `url(${blogbar})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '110px', 
  borderTop: '15px solid white',
  borderLeft:'15px solid white' ,
  borderRight:'15px solid white' ,
}}>
  {/* Content */}
</div>

        
        <div className="container-flud" style={{ backgroundColor:'#f1efee',color: "cadetblue" }}>
           <div className='ms-4 py-4'>
          <h1 className='contact-text fs-1 openSans-600 fw-bold' style={{color: "cadetblue",letterSpacing:'1.4px'}}>Contact us</h1>
        </div>
          <div className='contact-container pb-5' >
            <div className="card bg-white rounded-3 shadow border-0 ">
              <div className="card-body row justify-content-center">
 <div className="form col-md-4 col-sm-6  ms-md-5 m-0 pe-md-5 p-0">
       <div className="logo mb-4">
        <img src={Logo} style={{width:'250px'}} className='img-fluid' alt=""  />
       </div>
       <div className="para">
       <p className='openSans-700' style={{letterSpacing:'0.5px'}}>Art Actually CO company</p>
       <p className='openSans-600'>Address: <br />asddd,scssdw,wdw,xsx,sq <br />sxsx,sxs,sx,</p>
       <p className='openSans-600'>Phone: <br />+000 1234 123 4563 </p>
       <p className='openSans-600'>Email: <br />ifo@artactually.com</p>
       </div>
       <form onSubmit={submit}>
       <div className="input">
        <input type="text" className='form-control my-3 openSans-400' placeholder='Your name' name='name' value={contact.name} onChange={handleChange} />
        {errors.name && <span className="error text-danger">{errors.name}</span>}


        {/* <label htmlFor="" className='fs-3 mb-2 p-0'>Email</label> */}
        <input type='email' required className='form-control my-3 openSans-400' placeholder='Your email' name='email' value={contact.email} onChange={handleChange} />
        {errors.email && <span className="error text-danger">{errors.email}</span>}

        {/* <label htmlFor="" className='fs-3'>Queries</label> */}

        <textarea className="form-control my-3 th openSans-400" rows={6} id="" placeholder='Any questions' name='query' value={contact.query} onChange={handleChange}></textarea>
        {errors.query && <span className="error text-danger">{errors.query}</span>}

        <div className="pt-2">
               <button className='btn rounded-pill openSans-400 px-4' style={{background:'#709BA5',color:'white'}} type='submit'>Send</button>
   
       </div>
                            

       </div>
       </form>
        </div>
       
        <div className="col-md-7 col-sm-12 mt-md-0 mt-sm-3">
       
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2683553.3705922653!2d-123.43246259999999!3d49.2827291!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548673f143a94fb1%3A0x9bc7bcf9723d0711!2sVancouver%2C%20BC%2C%20Canada!5e0!3m2!1sen!2sca!4v1694093819521!5m2!1sen!2s" 
        style={{height:'100%'}}
        allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='contact-map w-100'/>
        </div>
              </div>
            </div>
       
       
        </div>
   
    </div>

    </>
  )
}

export default Contact
