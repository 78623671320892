import React, { useEffect, useState } from "react";
import cardImg1 from "../../assets/images/cardImage7.png";
import { useDispatch } from "react-redux";
import { getAllArts, getFormats } from "../../redux/features/auth/authSlice";
import ArtWorkDetails from "../../Screens/LandingPages/ArtWorkDetails";

const InstallationArt = () => {
  const [Art, setArt] = useState([]);

  const [DetailsOpen, setDetailsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();



  const dispatch = useDispatch();

  const DetailsScreen = (item) => {
    setDetailsOpen(!DetailsOpen);
    setSelectedItem(item);
  };

  useEffect(() => {
    const getData = async () => {
      await dispatch(getFormats())
         .then((res)=>{
          res?.payload?.data?.forEach(async (item)=>{
            if(item?.title === 'Installation Art'){
        await dispatch(getAllArts({ format: item?._id }) )
         .then((res)=>{
             const artData = res?.payload.data || [];
              setArt([...artData].reverse());
         })
  
          
            }
          })
         })
      
    };
    getData();
  }, [dispatch]);


  const [hoveredIndex, setHoveredIndex] = useState(null);
  const handleMouseEnter1 = (index) => {
    setHoveredIndex(index);
  };

   
  const handleMouseLeave1 = () => {
    setHoveredIndex(null);
  };


  return (
    
        <div className="col-md-12">
            {DetailsOpen && (
          <div className="modal-show-uploadArt">
            <div className="modal-content-uploadArt">
              <ArtWorkDetails
                closeWindow={DetailsScreen}
                selectedItem={selectedItem}
              />
            </div>
          </div>
        )}


              <div className="row justify-content-center">
        {Art?.map((item, i) => (

<div
key={i}
className="col-sm-6 p-0 art__img__wrapper pe-1"
onClick={() => DetailsScreen(item)}
onMouseEnter={() => handleMouseEnter1(i)}
onMouseLeave={handleMouseLeave1}
>
<div className="position-relative" style={{height:'300px'}}>
  <img
    src={item.image[0]}
    alt=""
    className="img-fluid img-bor w-100 h-100 object-cover"
    style={{ cursor: 'pointer' }}
  />
  {hoveredIndex === i && (
    <div className="owner-name-overlay">
      <h1 className="text-center fs-1 fw-bolder text-white mb-0 pb-0" style={{textTransform:'capitalize'}}>{item?.title}</h1>
      <h3 className="text-center fs-3 fw-bolder text-white" style={{textTransform:'capitalize'}}>{item?.ownerName}</h3>
    </div>
  )}
</div>
</div>
        ))}

        {/* Fill remaining spaces with cardImg3 */}
        {Array(Math.max(0,4 - (Art?.length || 0))).fill(null).map((_, i) => (
          <div key={i} className="col-sm-6 p-0 art__img__wrapper pe-1">
            <img
              src={cardImg1}
              alt=""
              className="img-fluid w-100 h-100 object-cover"
              style={{ cursor: 'pointer' }}
            />
          </div>
        ))}
      </div>

        </div>
  );
};

export default InstallationArt;
