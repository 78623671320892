import React, { useState } from 'react';
import Modal from 'react-modal';
import './donatemodal.css'
import {useNavigate} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
    zIndex: 9999, // Ensure the overlay is above other elements
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    minWidth: '37%',
    background: '#FFFFFF',
    border: '2px solid white',
    borderRadius: '10px',
    padding: '8px',
    transform: 'translate(-50%, -50%)',
    zIndex: 10000, // Ensure the modal is above the overlay
  },
};


const DonateThankModal = ({ ModalIsOpen, closeModal,musicData }) => {


  const [inputData,setInputData] = useState('')

  

  return (
    <div className="div">


<div className={`backdrop ${ModalIsOpen ? 'active' : ''}`}  />
                   <Modal
        isOpen={ModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
    
        <div>
            <div className="d-flex justify-content-between align-items-center" >
          <div className="w-100 text-center ">
            <p className="h3 fs-4 mt-md-3" style={{ fontWeight: '700', color: 'rgb(112, 155, 165)' ,opacity:'0.8'}}>
            Donation to " {musicData?.title} - {musicData?.owner_name}"
            </p>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }} className='mb-4 me-2'>
          <FontAwesomeIcon className='text-muted fs-3' style={{cursor:'pointer'}} icon={faXmark} onClick={closeModal} />
          </div>
        </div>

        <div className="border-bottom my-3"  style={{opacity:'0.5'}}  ></div>

        <div>
            <div className="col-md-12 my-4">
              <p className="text-center  mb-0" style={{ fontWeight: '700', color: '#11ad60',opacity:'0.7',letterSpacing:'1px' }}>
            "The donation to"{musicData}" has been successfully made."
          </p>   
            </div>
         
          <div className="row mx-4 ">
          <textarea
    type="text"
    className='rounded-3 border  text-start custom-input-donate'
    style={{
        overflow:'hidden',
      outline: 'none',
      color:'rgb(112, 155, 165)'
    }}
    rows={5}
    placeholder='Say something of encouragement to this artist ...'
    value={inputData}
    onChange={(e)=>setInputData(e.target.value)}
  />
            </div>

    
            <div className="border-bottom mt-2" style={{opacity:'0.5'}} ></div>

<div className="d-flex col-md-12 justify-content-center my-3">

          <div className="text-center mx-3">
            <button className="btn text-white fw-semibold px-4 py-1" 
            style={{ background: 'rgb(112, 155, 165)' }} >
              SEND
            </button>
          </div>
</div>
          
        </div>  
        </div>
    
        
   
        
      </Modal> 
           
 
 

  

     
    </div>
  );
};

export default DonateThankModal;
