

import { Box, IconButton, } from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
// import CloseIcon from "@mui/icons-material/Close";
import "../../Styles/uploadmusicModal.css"
import UploadFile from '../UploadArt/uploadMusicFile/UploadFile';
import UserUploadArtFile from '../../Screens/UserDashboard/UserUploadArt/UserUploadArtFile'
// import uploadMusic from '../../assets/icons/uploadMusic.png';
// import uploadImage from '../../assets/icons/uploadImage.png'
import uploadart from '../../assets/uploadart.PNG'
import uploadmusic from '../../assets/uploadmusic.PNG'
import { useDispatch, useSelector } from "react-redux";
import { getUserArts, getUserDetails, getUserSound } from "../../redux/features/auth/authSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";




const customStyles = {
  content: {
    top: "16%",
    left: "65%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "14%",
    background: '#6C9CA6',
    border: '2px solid white',
    padding: '8px',
    transform: "translate(-50%, -50%)",
    zIndex: 4
  },
};

const UPloadArtAndMusic = ({ ModalIsOpen, closeModal }) => {

  const [fileUploadOpen, setfileUploadOpen] = useState(false)
  const [MusicFileUploadOpen, setMusicFileUploadOpen] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { userArt, userDetail } = useSelector((state) => state.auth)
  console.log("🚀 ~ UPloadArtAndMusic ~ userDetail:", userDetail?.user?.stripe_sub_id)
  const User = JSON.parse(localStorage.getItem('user'))
  const user_id = User?.user[0]._id

  useEffect(() => {
    if (ModalIsOpen) {
      dispatch(getUserArts({ user_id: user_id }))
      dispatch(getUserSound({ user_id: user_id }))
      dispatch(getUserDetails({ id: user_id }))
    }


  }, [ModalIsOpen])


  //this funciton will check counter of arts and open plan screen or upload modal
  const fileuploadScreen = () => {

    
     
      let total = userArt?.userGallery?.data?.length + userArt?.userSound?.data?.length
      closeModal()
       console.log('hello',total);

      if ( total >= 3 && !userDetail?.user?.stripe_sub_id) {
        toast.error('Please Upgrade Membership', {
          autoClose: 3000
        })

        navigate(`/payment-plans/${user_id}`)
        return

      } else {

        setfileUploadOpen(!fileUploadOpen)
      }

    


  }

  //this functioin will check counter of arts and open plan screen or upload music modal
  const MusicfileuploadScreen = () => {
   

      let total = userArt?.userGallery?.data?.length + userArt?.userSound?.data?.length
      closeModal()

      if ( total >= 3 && !userDetail?.user?.stripe_sub_id) {
        toast.error('Please Upgrade Membership', {
          autoClose: 3000
        })

        navigate(`/payment-plans/${user_id}`)
        return

      } else {

        setMusicFileUploadOpen(!MusicFileUploadOpen)
      }

    
  }






  return (
    <Box >
      <Modal
        isOpen={ModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"

      >

        <div className="container">
          <div onClick={fileuploadScreen}>
            <div className="d-flex align-items-center justify-content-center" type="button" style={{ cursor: 'pointer' }} >
              <img src={uploadart} alt="" className="img-fluid mx-1" style={{ height: '25px', width: '25px', background: '#cbb9c4', color: 'white' }} />
              <h6 className="text-white pb-0 mb-0">Upload Images</h6>
            </div>

          </div>
          <div className="div" onClick={MusicfileuploadScreen}>
            <div className="d-flex align-items-center justify-content-center mt-2" style={{ cursor: 'pointer' }}>
              <img src={uploadmusic} alt="" className="img-fluid me-1" style={{ height: '25px', width: '25px', background: '#cbb9c4', color: 'white' }} />
              <h6 className="text-white pb-0 mb-0">Upload Music</h6>
            </div>
          </div>

        </div>
      </Modal>





      {MusicFileUploadOpen && (
        <div className="modalForfileUploadOpen ">
          <div className="modal-contentForMusicUploadOpen">
            <UploadFile closeWindow={MusicfileuploadScreen} closeParentModal={closeModal} />
          </div>
        </div>



      )}



      {fileUploadOpen && (
        <div className="modalForfileUploadOpen ">
          <div className="modal-contentForImageUploadOpen">

            <UserUploadArtFile closeWindow={fileuploadScreen} />

          </div>
        </div>



      )}



    </Box>
  );
};

export default UPloadArtAndMusic;