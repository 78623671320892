import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getUserStyle, addStyle } from "../../../redux/features/auth/authSlice";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const StyleList = ({sendToParentStyleId,initalStyle,callType,selectedStyle}) => {

  
  const user = JSON.parse(localStorage.getItem("user"));
  const user_id = user?.user[0]._id;

  const { userStyle, } = useSelector((state) => state.auth);

  const collections = userStyle?.data || [];

const [styleName, setstyleName] = useState()
const [initalStyleTitle, setinitalStyleTitle] = useState({
  title:'',
  id:null,
})
  const [newCollectionTitle, setNewCollectionTitle] = useState({
    title: "",
    user_id: user_id,
  });


  const dispatch = useDispatch();

  const getData = async () => {
    await dispatch(getUserStyle({ user_id }));
  };
  useEffect(() => {
    getData();
  }, []);

  //this useEffect will work when edit modal open and selection need values
  //this useEffect will work when we upload art and user come back visibility to detail page
  //in this we compare comming id's with list and get required object
  useEffect(()=>{
    if(initalStyle && callType==='edit'){
     
      collections.forEach(element => {
        
        if(initalStyle===element._id){
          setinitalStyleTitle((prev)=>({
            ...prev,
            title:element.title,
            id:element._id
          }))

          sendToParentStyleId(element._id)
        }
      });
    }
    else if(selectedStyle && callType==='upload'){
      collections.forEach(element => {
        
        if(selectedStyle===element._id){
          setinitalStyleTitle((prev)=>({
            ...prev,
            title:element.title,
            id:element._id
          }))

          sendToParentStyleId(element._id)
        }
      });
    }
  },[initalStyle,callType,selectedStyle])

  const handleNewCollectionChange = (e) => {
    const { name, value } = e.target;
    setNewCollectionTitle((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };



  const handleCheckboxChange = (item) => {
    sendToParentStyleId(item._id)
    setstyleName(item.title)
  };

  const handleDropdownClick = (e) => {
    e.stopPropagation(); // Prevent dropdown from closing
  };

  const handleNewCollectionSave = (e) => {
    e.stopPropagation(); // Prevent dropdown from closing
    dispatch(
      addStyle({
        title: newCollectionTitle?.title,
        user_id,
      })
    ).then(async () => {
      setNewCollectionTitle({
        title: "",
      });
      await dispatch(getUserStyle({ user_id }));
    });
  };

  return (
    <>
 

<div class="dropdown">
 
  <button
          className="btn btn-transparent  dropdown-toggle w-100 d-flex text-start "
          type="button"
          style={{border:"1px solid #74a8b0" ,
          justifyContent:"space-between", 
          alignItems:'center',
          color: `${initalStyleTitle.title && !styleName ? '#709AA4': styleName? '#709AA4':'#BED3D6'}`,
            fontWeight: '600' }} 
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {initalStyleTitle.title && !styleName ? initalStyleTitle?.title: styleName? styleName:'Select Style...'}
        </button>

  <ul class="dropdown-menu" style={{ minWidth: "180px" }}>



    {collections?.map((item, i) => {
            return (
              <li className="px-2" key={i}>
                <a href="#style" className="dropdown-item"   style={{ fontWeight: '600', color: 'rgb(116, 168, 176)' }} onClick={() => handleCheckboxChange(item)}>
 
                
                    {item.title}
                 
                
                </a>
               
              </li>
            );
          })}
          <li className="p-1" >
            <div className="row border-top mt-1 pt-2">
              <div className="col-9 p-0  ps-3 ">
                
                  <input
                    type="text"
                    name="title"
                    className="form-control dropdown-toggle project-input"
                    value={newCollectionTitle.title}
                    
                    onChange={handleNewCollectionChange}
                    placeholder="Add New..."
                    onClick={handleDropdownClick}
                  />
               
              </div>
              <div className="col-3  d-flex align-items-center justify-content-center  text-center">
            <div>
               <AddCircleOutlineIcon style={{ color: "#74a8b0" }} onClick={handleNewCollectionSave}/>
            </div>
               
              </div>
            </div>
          </li>
          <div className="pt-2 text-center"></div>
  </ul>
</div>
    </>
  );
};

export default StyleList;
