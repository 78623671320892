import React, { useEffect, useRef, useState } from "react";
import "../Gallery/gallery.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AddFvrtArts, getAllArts, getAllSound, getFvrtArts } from "../../redux/features/auth/authSlice";
import Login from "../../Screens/Login";
import { baseURL } from "../../redux/axios/axios";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { toast } from "react-toastify";
import Tooltip from '@mui/material/Tooltip';
import ModalHandler from "../../Screens/newloginflow/ModalHandler";
import { AudioPlayer } from "../audioPlayer/AudioPlayer";
import CustomPlayer from "../audioPlayer/CustomrPlayer";
import filterIcon from '../../assets/images/filter_icon.png'
import TablePagination from "../Pagination/TablePagination";

const GalleryMusic = ({ allSound,musicFilterdata }) => {


  const { isLoading } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [paginatedData, setpaginatedData] = useState()

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const paginatedlist=(val) => {
   console.log("🚀 ~ paginatedlist ~ val:", val)
   
     
    
    setpaginatedData(val)
    }





  const details = (item) => {
    navigate("/music-details", { state: { id: item._id, image: item.image } });
  }
  return (
    <>
     

        {isLoading ?

          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status" style={{ color: '#709AA4' }}>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          : paginatedData?.length > 0 ?

            (paginatedData && <CustomPlayer
              detailsPage={(item) => details(item)}
              audiodata={paginatedData}
            />
            )

            : 
                <div className="text-muted fs-3">No Data Found</div>
        }
        {
        musicFilterdata?
         
          <TablePagination list={musicFilterdata} paginatedList={paginatedlist}/>
          :
          
          <TablePagination list={allSound?.data} paginatedList={paginatedlist}/>
        }


    </>
  )
}

export default GalleryMusic