import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetailForProfile  } from '../../../redux/features/auth/authSlice'
import { Link, useLocation } from 'react-router-dom'
import defaultImage from '../../../assets/images/profile.jpg'
import blogbar from "../../../assets/images/topbarimage.png"

const PublicProfileData = () => {
    const location = useLocation()
    const id = location?.state?.id
    const dispatch = useDispatch()

    const [completeDetail, setcompleteDetail] = useState()
      const userName = completeDetail?.firstName+' '+completeDetail?.lastName

    useEffect(()=>{
         const getDetail = async()=>{
        await dispatch(getUserDetailForProfile(id))
        .then((res)=>{
            setcompleteDetail(res.payload.user)
        })
    }
        if(id){
            getDetail()
        }

    },[id,dispatch])
    
    const websiteUrl = completeDetail?.website?.startsWith('http') ? completeDetail?.website : `https://${completeDetail?.website}`;
    const FbUrl = completeDetail?.facebook?.startsWith('http') ? completeDetail?.facebook : `https://${completeDetail?.facebook}`;
    const InsUrl = completeDetail?.instagram?.startsWith('http') ? completeDetail?.instagram : `https://${completeDetail?.instagram}`;
  return (<>

<div style={{
  backgroundImage: `url(${blogbar})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '110px' 
}}></div>
  <div className='container' >
    {!completeDetail ?(
<>
<div className="d-flex  justify-content-center" >
    <div>
    <p className='' style={{fontWeight: '600', color: '#8f9bb9', fontSize: '22px'}} >Loading Please Wait</p>
    </div>
</div>
</>
    )
    :(
        <div style={{alignItems:"center",  backgroundColor:'#fefefe',
        marginLeft:"2px",
         marginRight:"2px", 
         borderRadius:'15px', 
         boxShadow:"  0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)", 
         padding:'30px 35px' }}>
       <div>
<h4 className="fs-3 openSans-800 ms-5" style={{ fontWeight: '600', color: '#709AA4', fontSize: '24px' }}>
            Public Profile
          </h4>
</div>
<div className="row mt-5 ">
  
<div
          className="col-md-3  d-flex justify-content-center"
         
        >
            <div style={{width:"200px",height:'200px' }}>
                 <img  className="rounded-circle img-fluid w-100 h-100 object-cover"  src={completeDetail?.image[0] ? completeDetail?.image[0]:defaultImage} alt="" />
    </div>
            </div>
       
    <div className=" col-md-6 content" >
        <div className="row">
            <div className="col-md-6">
                <p className='openSans-400' style={{color:"black"}}>User Name</p>
                <h5 className='openSans-600' style={{color:'#709AA4'}}>
              {completeDetail?.userName?.length > 0 ? completeDetail?.userName :userName}
                </h5>
            </div>
            <div className="col-md-6">
                <p className='openSans-400' style={{color:"black"}}>Website</p>
                {
                    completeDetail?.website?
                    ( <a href={websiteUrl} target='_blank' rel="noopener noreferrer" style={{textDecoration:'none'}}>
                    <h5 className='openSans-600' style={{color:'#709AA4'}}>
                     {completeDetail?.website}
                    </h5>
               
                </a>)
                    :(
                        <h5 className='openSans-600' style={{color:'#709AA4'}}>
                       No Website Added
                       </h5>   
                    )
                }
               

            </div>
        </div>
        <div className="row my-3">
            <div className="col-md-6">
                <p style={{color:"black"}}>Instagram</p>
                {
                    completeDetail?.instagram?
                    (<a href={InsUrl} target='_black' rel="noopener noreferrer" style={{textDecoration:'none'}}> 
                <h5 className='openSans-600' style={{color:'#709AA4'}}>
            { completeDetail?.instagram}
                </h5></a>)
                    :
                    (
                        <h5 className='openSans-600' style={{color:'#709AA4'}}>
                     No Account Added
                    </h5>
                    )
                }
                
               
            </div>
            <div className="col-md-6">
                <p className='openSans-400' style={{color:"black"}}>Facebook</p>

                    {completeDetail?.facebook?
                    ( <a href={FbUrl} target='_blank' rel="noopener noreferrer" style={{textDecoration:'none'}}>
                    <h5 className='openSans-600' style={{color:'#709AA4'}}>
                       {completeDetail?.facebook?.length > 0 ? completeDetail?.facebook:'No Account Added'}  
                    </h5>
               
                </a>
                )
                    :
                    (
                        <h5 className='openSans-600' style={{color:'#709AA4'}}>
                    No Account Added
                    </h5>
                    )
                    
                }
               

            </div>
        </div>
        <div className="row">
            <p className='openSans-400' style={{color:"black"}}>About Profiler</p>
            <textarea readOnly className='border-0 openSans-600' id="" cols="30" rows="4" style={{color:'#709AA4', fontSize:'22px' }}>
           {completeDetail?.bio?.length > 0 ? completeDetail.bio:'No Data Added'}
            </textarea>
        </div>
    </div>
    
</div> 
        </div>

    )}


</div>
  </>

  )
}

export default PublicProfileData